<div class="wrapper">
  <section>
    <div class="toggle-div">
      <form>
        <div class="first-side">
          <div class="control-text-and-checkbox">
            <label for="inqDate" class="form-label"
              >{{ "transactions.inqDate" | translate }}:
            </label>
            <label class="toggle-control">
              <input
                type="checkbox"
                id="inqDate"
                (change)="inqDate = !inqDate; setToLocalStorage()"
                [checked]="inqDate"
              />
              <span class="control"></span>
            </label>
          </div>
          <div class="control-text-and-checkbox">
            <label for="cardHolderName" class="form-label"
              >{{ "transactions.cardNumber" | translate }}:
            </label>
            <label class="toggle-control">
              <input
                type="checkbox"
                id="cardHolderName"
                (change)="
                  addCardHolderName = !addCardHolderName; setToLocalStorage()
                "
                [checked]="addCardHolderName"
              />
              <span class="control"></span>
            </label>
          </div>
          <div class="control-text-and-checkbox">
            <label for="payerApp" class="form-label"
              >{{ "transactions.payerApp" | translate }}:
            </label>
            <label class="toggle-control">
              <input
                type="checkbox"
                id="payerApp"
                (change)="payerApp = !payerApp; setToLocalStorage()"
                [checked]="payerApp"
              />
              <span class="control"></span>
            </label>
          </div>
          <div class="control-text-and-checkbox">
            <label for="comment" class="form-label"
              >{{ "transactions.comment" | translate }}:
            </label>
            <label class="toggle-control">
              <input
                type="checkbox"
                id="comment"
                (change)="comment = !comment; setToLocalStorage()"
                [checked]="comment"
              />
              <span class="control"></span>
            </label>
          </div>
          <div class="control-text-and-checkbox">
            <label for="state" class="form-label"
              >{{ "transactions.department" | translate }}:
            </label>
            <label class="toggle-control">
              <input
                type="checkbox"
                id="state"
                (change)="state = !state; setToLocalStorage()"
                [checked]="state"
              />
              <span class="control"></span>
            </label>
          </div>
          <div class="control-text-and-checkbox">
            <label for="cardType" class="form-label"
              >{{ "transactions.cardType" | translate }}:
            </label>
            <label class="toggle-control">
              <input
                type="checkbox"
                id="cardType"
                (change)="cardType = !cardType; setToLocalStorage()"
                [checked]="cardType"
              />
              <span class="control"></span>
            </label>
          </div>
        </div>
      </form>
      <div class="second-side">
        <button class="btn btn-success" (click)="createExcelModel()">
          {{ "transactions.excelText" | translate }}
        </button>
        <!-- <button class="btn btn-success" (click)="ajaxex()">AJAX button</button> -->
        <form [formGroup]="dateFromTo" class="date-picker">
          <mat-form-field appearance="fill" class="example-form-field">
            <mat-label style="color: var(--uni--color)">{{
              "transactions.dateFilter" | translate
            }}</mat-label>
            <mat-date-range-input
              [rangePicker]="rangePicker"
              formGroupName="dateRange"
            >
              <input
                type="number"
                matStartDate
                placeholder="{{ 'transactions.startDate' | translate }}"
                formControlName="start"
              />
              <input
                type="number"
                matEndDate
                placeholder="{{ 'transactions.endDate' | translate }}"
                formControlName="end"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>
                  {{ "transactions.cancel" | translate }}
                </button>
                <button
                  mat-raised-button
                  color="primary"
                  matDateRangePickerApply
                  (click)="showDataButton()"
                >
                  {{ "transactions.apply" | translate }}
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            class="button-filter"
            (click)="resetDate()"
          >
            {{ "transactions.clear" | translate }}
          </button>
          <!-- <button mat-raised-button color="primary" class="button-filter"
            (click)="showDataButton()">{{'transactions.filter' |translate}}</button> -->
        </form>

        <select
          id="select-type"
          class="form-select form-select-sm fixed"
          aria-label=".form-select-sm example"
          #paidUnpaid_s
          (change)="selectPaidUnpaid(paidUnpaid_s.value)"
        >
          <option value="5" [selected]="selectedOption">
            {{ "transactions.selectPaidUnpaid.all" | translate }}
          </option>
          <option value="2">
            {{ "transactions.selectPaidUnpaid.paid" | translate }}
          </option>
          <option value="0">
            {{ "transactions.selectPaidUnpaid.unPaid" | translate }}
          </option>
          <option value="3">
            {{ "transactions.selectPaidUnpaid.reversed" | translate }}
          </option>
          <option value="4">
            {{ "transactions.selectPaidUnpaid.refund" | translate }}
          </option>
        </select>

        <!-- <a id="my-cool-app" href="http://mycoolapp://"> Open my app </a> -->
      </div>
    </div>
    <div class="media-large">
      <table class="table table-striped table-hover">
        <thead class="sticky">
          <tr>
            <!-- <th scope="col">#</th> -->
            <th scope="col" class="orderNumber">
              {{ "transactions.orderNumber" | translate }}
            </th>
            <th scope="col" *ngIf="inqDate" (click)="sortByDate()">
              {{ "transactions.inqDate" | translate }}
              <!-- <img src="../../../../../assets/image/sort-arrow.png" alt=""> -->
            </th>
            <th scope="col">{{ "transactions.payDate" | translate }}</th>
            <th scope="col" *ngIf="state">
              {{ "transactions.department" | translate }}
            </th>
            <th scope="col">{{ "transactions.amount" | translate }}</th>
            <th scope="col" class="certCode">
              {{ "transactions.certificationCode" | translate }}
            </th>
            <th scope="col" *ngIf="payerApp">
              {{ "transactions.payerApp" | translate }}
            </th>
            <th scope="col" *ngIf="cardType">
              {{ "transactions.cardType" | translate }}
            </th>
            <th scope="col">{{ "transactions.state" | translate }}</th>
            <th scope="col" *ngIf="comment">
              {{ "transactions.comment" | translate }}
            </th>
            <th scope="col" *ngIf="addCardHolderName">
              {{ "transactions.cardNumber" | translate }}
            </th>
            <th scope="col" class="reqType">
              {{ "transactions.requestType" | translate }}
            </th>
            <th scope="col">{{ "transactions.receiver" | translate }}</th>
          </tr>
        </thead>
        <tbody *ngIf="!showLoader">
          <tr
            *ngFor="let transaction of transactionsForDateFilter; index as i"
            [attr.data-index]="i"
            (click)="openReciept(transaction)"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            style="cursor: pointer"
          >
            <!-- <th scope="row">{{transactions.length - i}}</th> -->
            <!-- <th scope="row">{{transaction.id}}</th> -->
            <td scope="row">{{ transaction.transactionId }}</td>
            <td *ngIf="inqDate">
              {{ transaction.createDate | date : "dd/MM/yy HH:mm" }}
            </td>
            <td>
              {{
                getFullYear(transaction.transactionDate) > 2028 ||
                getFullYear(transaction.transactionDate) < 2018
                  ? "-"
                  : (transaction.transactionDate | date : "dd/MM/yy HH:mm")
              }}
            </td>
            <td *ngIf="state">{{ transaction.merchantUsername }}</td>
            <td>{{ transaction.amount.toLocaleString() }}</td>
            <td>{{ transaction.authCode }}</td>
            <td *ngIf="payerApp">{{ transaction.appName }}</td>
            <td *ngIf="cardType" class="img-td">
              <img
                class="visa"
                src="../../../../../assets/image/arca_visa_master/visa.png"
                alt="visa"
                *ngIf="transaction.card.maskedPan[0] == '4'"
              />
              <img
                src="../../../../../assets/image/arca_visa_master/master.png"
                alt="mastercard"
                *ngIf="
                  transaction.card.maskedPan[0] == '5' ||
                  transaction.card.maskedPan[0] == '6'
                "
              />
              <img
                class="visa"
                src="../../../../../assets/image/arca-removebg-preview.png"
                alt="arca"
                *ngIf="transaction.card.maskedPan[0] == '9'"
              />
            </td>
            <!-- <td>{{transaction.card.maskedPan[0] == '5' || transaction.card.maskedPan[0] == '6' ? 'MasterCard' : transaction.card.maskedPan[0] == '4' ? 'Visa' : transaction.card.maskedPan[0] == '9' ? 'Arca' : '-'}}</td> -->
            <!-- <td>{{transaction.transactionStatus == 2 ? ('transactions.selectPaidUnpaid.paid' | translate ) : transaction.transactionStatus == 4 ? ('transactions.selectPaidUnpaid.reverce' | translate ) : ('transactions.selectPaidUnpaid.unPaid' | translate)}}</td> -->
            <td class="stat">
              <img
                src="../../../../../assets/image/checked.png"
                alt="{{ 'transactions.selectPaidUnpaid.paid' | translate }}"
                *ngIf="transaction.transactionStatus == 2"
              />
              <img
                src="../../../../../assets/image/cancel.png"
                alt="{{ 'transactions.selectPaidUnpaid.unPaid' | translate }}"
                *ngIf="transaction.transactionStatus == 3"
              />
              <img
                src="../../../../../assets/image/refund.png"
                alt="{{ 'transactions.selectPaidUnpaid.unPaid' | translate }}"
                *ngIf="transaction.transactionStatus == 4"
              />
              <img
                src="../../../../../assets/image/question.png"
                alt="{{ 'transactions.selectPaidUnpaid.reverse' | translate }}"
                *ngIf="
                  transaction.transactionStatus == 0 ||
                  transaction.transactionStatus == 1
                "
              />
            </td>
            <td *ngIf="comment">
              <div class="span">{{ transaction.comment }}</div>
            </td>
            <td *ngIf="addCardHolderName">
              {{ transaction.card.maskedPan | creditCardDigits }}
            </td>
            <td>
              {{
                transaction.isLink == 0
                  ? "QR"
                  : transaction.isLink == 1
                  ? "Link"
                  : "SMS"
              }}
            </td>
            <td>{{ transaction.receiver }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="showLoader">
          <div class="loader">
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </tbody>
      </table>
      <mat-paginator
        #myPaginator
        [length]="transactionCount"
        [pageSize]="50"
        (page)="handlePageEvent($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>

    <div class="media-small">
      <table class="table">
        <thead class="media-thead">
          <tr>
            <th class="sticky" scope="col">
              {{ "transactions.inqDate" | translate }}
            </th>
            <th class="sticky" scope="col">
              {{ "transactions.amount" | translate }}
            </th>
            <th class="sticky" scope="col">
              {{ "transactions.requestType" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let transaction of transactions">
            <th>{{ transaction.createDate | date : "dd/MM/yy HH:mm" }}</th>
            <td>{{ transaction.amount }}</td>
            <td>
              <svg
                (click)="openReciept(transaction)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                *ngIf="transaction.isLink == 0"
                viewBox="0 0 28 28"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <!-- Uploaded to SVGRepo https://www.svgrepo.com -->
                <title>ic_fluent_qr_code_24_regular</title>
                <desc>Created with Sketch.</desc>
                <g
                  id="🔍-Product-Icons"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="ic_fluent_qr_code_24_regular"
                    fill="#212121"
                    fill-rule="nonzero"
                  >
                    <path
                      d="M10.75,15 C11.9926407,15 13,16.0073593 13,17.25 L13,22.75 C13,23.9926407 11.9926407,25 10.75,25 L5.25,25 C4.00735931,25 3,23.9926407 3,22.75 L3,17.25 C3,16.0073593 4.00735931,15 5.25,15 L10.75,15 Z M18.3346843,15 L18.3346843,18.3333333 L21.6671089,18.3333333 L21.6671089,21.6666667 L18.3346843,21.6660851 L18.3346843,24.9994184 L15.0013509,24.9994184 L15.0013509,21.6660851 L18.3337756,21.6666667 L18.3337756,18.3333333 L15.0013509,18.3333333 L15.0013509,15 L18.3346843,15 Z M25.0004423,21.6660851 L25.0004423,24.9994184 L21.6671089,24.9994184 L21.6671089,21.6660851 L25.0004423,21.6660851 Z M10.75,16.5 L5.25,16.5 C4.83578644,16.5 4.5,16.8357864 4.5,17.25 L4.5,22.75 C4.5,23.1642136 4.83578644,23.5 5.25,23.5 L10.75,23.5 C11.1642136,23.5 11.5,23.1642136 11.5,22.75 L11.5,17.25 C11.5,16.8357864 11.1642136,16.5 10.75,16.5 Z M9.5,18.5 L9.5,21.5 L6.5,21.5 L6.5,18.5 L9.5,18.5 Z M25.0004423,15 L25.0004423,18.3333333 L21.6671089,18.3333333 L21.6671089,15 L25.0004423,15 Z M10.75,3 C11.9926407,3 13,4.00735931 13,5.25 L13,10.75 C13,11.9926407 11.9926407,13 10.75,13 L5.25,13 C4.00735931,13 3,11.9926407 3,10.75 L3,5.25 C3,4.00735931 4.00735931,3 5.25,3 L10.75,3 Z M22.75,3 C23.9926407,3 25,4.00735931 25,5.25 L25,10.75 C25,11.9926407 23.9926407,13 22.75,13 L17.25,13 C16.0073593,13 15,11.9926407 15,10.75 L15,5.25 C15,4.00735931 16.0073593,3 17.25,3 L22.75,3 Z M10.75,4.5 L5.25,4.5 C4.83578644,4.5 4.5,4.83578644 4.5,5.25 L4.5,10.75 C4.5,11.1642136 4.83578644,11.5 5.25,11.5 L10.75,11.5 C11.1642136,11.5 11.5,11.1642136 11.5,10.75 L11.5,5.25 C11.5,4.83578644 11.1642136,4.5 10.75,4.5 Z M22.75,4.5 L17.25,4.5 C16.8357864,4.5 16.5,4.83578644 16.5,5.25 L16.5,10.75 C16.5,11.1642136 16.8357864,11.5 17.25,11.5 L22.75,11.5 C23.1642136,11.5 23.5,11.1642136 23.5,10.75 L23.5,5.25 C23.5,4.83578644 23.1642136,4.5 22.75,4.5 Z M9.5,6.5 L9.5,9.5 L6.5,9.5 L6.5,6.5 L9.5,6.5 Z M21.5,6.5 L21.5,9.5 L18.5,9.5 L18.5,6.5 L21.5,6.5 Z"
                      id="🎨-Color"
                    ></path>
                  </g>
                </g>
              </svg>

              <svg
                (click)="openReciept(transaction)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                *ngIf="transaction.isLink == 1"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 465 465"
                style="enable-background: new 0 0 465 465"
                xml:space="preserve"
              >
                <path
                  d="M370.656,290.363c-30.818,0-57.958,16.049-73.502,40.227l-122.311-64.265c4.39-10.408,6.819-21.838,6.819-33.824
	s-2.429-23.416-6.819-33.824l122.311-64.265c15.545,24.178,42.684,40.227,73.502,40.227c48.148,0,87.319-39.171,87.319-87.318
	C457.975,39.171,418.804,0,370.656,0c-48.147,0-87.318,39.171-87.318,87.319c0,11.987,2.429,23.416,6.819,33.824l-122.311,64.265
	c-15.545-24.178-42.684-40.227-73.502-40.227c-48.147,0-87.318,39.171-87.318,87.319s39.171,87.319,87.318,87.319
	c30.818,0,57.958-16.049,73.502-40.227l122.311,64.265c-4.39,10.408-6.819,21.838-6.819,33.824
	c0,48.148,39.171,87.319,87.318,87.319c48.148,0,87.319-39.171,87.319-87.319C457.975,329.534,418.804,290.363,370.656,290.363z
	 M370.656,15c39.877,0,72.319,32.442,72.319,72.319c0,39.876-32.442,72.318-72.319,72.318s-72.318-32.442-72.318-72.318
	C298.337,47.442,330.779,15,370.656,15z M94.343,304.819c-39.876,0-72.318-32.442-72.318-72.319s32.442-72.319,72.318-72.319
	s72.318,32.442,72.318,72.319S134.219,304.819,94.343,304.819z M370.656,450c-39.877,0-72.318-32.442-72.318-72.319
	c0-39.876,32.441-72.318,72.318-72.318s72.319,32.442,72.319,72.318C442.975,417.558,410.533,450,370.656,450z"
                />
              </svg>

              <svg
                (click)="openReciept(transaction)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                *ngIf="transaction.isLink == 3"
                version="1.1"
                id="Icons"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 32 32"
                style="enable-background: new 0 0 32 32"
                xml:space="preserve"
              >
                <style type="text/css">
                  .st0 {
                    fill: none;
                    stroke: #000000;
                    stroke-width: 2;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 10;
                  }
                </style>
                <line class="st0" x1="10" y1="12" x2="19" y2="12" />
                <line class="st0" x1="10" y1="16" x2="14" y2="16" />
                <path
                  class="st0"
                  d="M11,4c-4.4,0-8,3.6-8,8v12v5l0,0c3.7-3.2,8.4-5,13.3-5H21c4.4,0,8-3.6,8-8v-4c0-4.4-3.6-8-8-8H11z"
                />
              </svg>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="showLoader">
          <div class="loader">
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </tbody>
      </table>
      <mat-paginator
        #myPaginator
        [length]="transactionCount"
        [pageSize]="50"
        (page)="handlePageEvent($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </section>

  <!-- Modal -->
  <div
    class="modal fade"
    id="moreIformation"
    tabindex="-1"
    aria-labelledby="moreIformationlLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            #infoModal
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body myModalBody">
          <div class="data-side" *ngIf="modalIf">
            <div class="data">
              <h6>{{ "transactions.inqDate" | translate }}</h6>
              <h5>{{ showData.createDate | date : "dd/MM/yy HH:mm" }}</h5>
            </div>
            <div class="data">
              <h6 scope="col">{{ "transactions.payDate" | translate }}</h6>
              <h5 *ngIf="inqDate">
                {{
                  getFullYear(showData.transactionDate) > 2028 ||
                  getFullYear(showData.transactionDate) < 2018
                    ? "-"
                    : (showData.transactionDate | date : "dd/MM/yy HH:mm")
                }}
              </h5>
            </div>
            <div class="data">
              <h6 scope="col">{{ "transactions.department" | translate }}</h6>
              <h5>{{ showData.merchantUsername }}</h5>
            </div>
            <div class="data">
              <h6 scope="col">{{ "transactions.amount" | translate }}</h6>
              <h5>{{ showData.amount }}</h5>
            </div>
            <div class="data">
              <h6 scope="col">
                {{ "transactions.certificationCode" | translate }}
              </h6>
              <h5>{{ showData.authCode }}</h5>
            </div>
            <div class="data">
              <h6 scope="col">{{ "transactions.payerApp" | translate }}</h6>
              <h5>{{ showData.appName }}</h5>
            </div>
            <div class="data">
              <h6 scope="col">{{ "transactions.cardType" | translate }}</h6>
              <h5>
                {{
                  showData.card.maskedPan[0] == "5" ||
                  showData.card.maskedPan[0] == "6"
                    ? "MasterCard"
                    : showData.card.maskedPan[0] == "4"
                    ? "Visa"
                    : showData.card.maskedPan[0] == "9"
                    ? "Arca"
                    : "-"
                }}
              </h5>
            </div>
            <div class="data">
              <h6 scope="col">{{ "transactions.state" | translate }}</h6>
              <h5>
                {{
                  showData.transactionStatus == 2
                    ? ("transactions.selectPaidUnpaid.paid" | translate)
                    : showData.transactionStatus == 4
                    ? ("transactions.selectPaidUnpaid.reverse" | translate)
                    : ("transactions.selectPaidUnpaid.unPaid" | translate)
                }}
              </h5>
            </div>
            <div class="data comment">
              <h6 scope="col">{{ "transactions.comment" | translate }}</h6>
              <h5 title="{{ showData.comment }}">{{ showData.comment }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- INFOMODAL -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            #infoModal
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body myModalBody">
          <div class="data-side" *ngIf="showModalInfo">
            <div class="data">
              <h6 scope="col">
                {{ "transactions.departmentName" | translate }}
              </h6>
              <h5>{{ currentTransaction.merchantName }}</h5>
            </div>

            <div class="data">
              <h6 scope="col">{{ "transactions.address" | translate }}</h6>
              <h5>{{ legalAddress }}</h5>
            </div>

            <div class="data">
              <h6 scope="col">{{ "transactions.tin" | translate }}</h6>
              <h5>{{ tin }}</h5>
            </div>

            <div class="data" *ngIf="currentTransaction.isLink == 0">
              <h6 scope="col">{{ "transactions.requestType" | translate }}</h6>
              <h5>
                <svg
                  width="28px"
                  height="28px"
                  viewBox="0 0 28 28"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <!-- Uploaded to SVGRepo https://www.svgrepo.com -->
                  <title>ic_fluent_qr_code_24_regular</title>
                  <desc>Created with Sketch.</desc>
                  <g
                    id="🔍-Product-Icons"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="ic_fluent_qr_code_24_regular"
                      fill="#212121"
                      fill-rule="nonzero"
                    >
                      <path
                        d="M10.75,15 C11.9926407,15 13,16.0073593 13,17.25 L13,22.75 C13,23.9926407 11.9926407,25 10.75,25 L5.25,25 C4.00735931,25 3,23.9926407 3,22.75 L3,17.25 C3,16.0073593 4.00735931,15 5.25,15 L10.75,15 Z M18.3346843,15 L18.3346843,18.3333333 L21.6671089,18.3333333 L21.6671089,21.6666667 L18.3346843,21.6660851 L18.3346843,24.9994184 L15.0013509,24.9994184 L15.0013509,21.6660851 L18.3337756,21.6666667 L18.3337756,18.3333333 L15.0013509,18.3333333 L15.0013509,15 L18.3346843,15 Z M25.0004423,21.6660851 L25.0004423,24.9994184 L21.6671089,24.9994184 L21.6671089,21.6660851 L25.0004423,21.6660851 Z M10.75,16.5 L5.25,16.5 C4.83578644,16.5 4.5,16.8357864 4.5,17.25 L4.5,22.75 C4.5,23.1642136 4.83578644,23.5 5.25,23.5 L10.75,23.5 C11.1642136,23.5 11.5,23.1642136 11.5,22.75 L11.5,17.25 C11.5,16.8357864 11.1642136,16.5 10.75,16.5 Z M9.5,18.5 L9.5,21.5 L6.5,21.5 L6.5,18.5 L9.5,18.5 Z M25.0004423,15 L25.0004423,18.3333333 L21.6671089,18.3333333 L21.6671089,15 L25.0004423,15 Z M10.75,3 C11.9926407,3 13,4.00735931 13,5.25 L13,10.75 C13,11.9926407 11.9926407,13 10.75,13 L5.25,13 C4.00735931,13 3,11.9926407 3,10.75 L3,5.25 C3,4.00735931 4.00735931,3 5.25,3 L10.75,3 Z M22.75,3 C23.9926407,3 25,4.00735931 25,5.25 L25,10.75 C25,11.9926407 23.9926407,13 22.75,13 L17.25,13 C16.0073593,13 15,11.9926407 15,10.75 L15,5.25 C15,4.00735931 16.0073593,3 17.25,3 L22.75,3 Z M10.75,4.5 L5.25,4.5 C4.83578644,4.5 4.5,4.83578644 4.5,5.25 L4.5,10.75 C4.5,11.1642136 4.83578644,11.5 5.25,11.5 L10.75,11.5 C11.1642136,11.5 11.5,11.1642136 11.5,10.75 L11.5,5.25 C11.5,4.83578644 11.1642136,4.5 10.75,4.5 Z M22.75,4.5 L17.25,4.5 C16.8357864,4.5 16.5,4.83578644 16.5,5.25 L16.5,10.75 C16.5,11.1642136 16.8357864,11.5 17.25,11.5 L22.75,11.5 C23.1642136,11.5 23.5,11.1642136 23.5,10.75 L23.5,5.25 C23.5,4.83578644 23.1642136,4.5 22.75,4.5 Z M9.5,6.5 L9.5,9.5 L6.5,9.5 L6.5,6.5 L9.5,6.5 Z M21.5,6.5 L21.5,9.5 L18.5,9.5 L18.5,6.5 L21.5,6.5 Z"
                        id="🎨-Color"
                      ></path>
                    </g>
                  </g>
                </svg>
              </h5>
            </div>

            <div class="data">
              <h6>{{ "transactions.inqDate" | translate }}</h6>
              <h5>
                {{ currentTransaction.createDate | date : "dd/MM/yy HH:mm" }}
              </h5>
            </div>

            <div class="data">
              <h6 scope="col">{{ "transactions.payDate" | translate }}</h6>
              <h5
                *ngIf="
                  currentTransaction.transactionStatus != 0 &&
                  currentTransaction.transactionStatus != 1
                "
              >
                {{
                  currentTransaction.transactionDate | date : "dd/MM/yy HH:mm"
                }}
              </h5>
              <h5
                *ngIf="
                  currentTransaction.transactionStatus == 0 ||
                  currentTransaction.transactionStatus == 1
                "
              >
                -
              </h5>
            </div>

            <div class="data">
              <h6 scope="col">{{ "transactions.amount" | translate }}</h6>
              <h5>{{ currentTransaction.amount }}</h5>
            </div>

            <div class="data">
              <h6 scope="col">{{ "transactions.payerApp" | translate }}</h6>
              <h5>
                {{
                  currentTransaction.appName == "" ||
                  currentTransaction.appName == " "
                    ? "-"
                    : currentTransaction.appName
                }}
              </h5>
            </div>

            <div class="data">
              <h6 scope="col">{{ "transactions.cardNumber" | translate }}</h6>
              <h5>{{ currentTransaction.card.maskedPan }}</h5>
            </div>

            <div class="data">
              <h6 scope="col">{{ "transactions.cardType" | translate }}</h6>
              <h5>
                {{
                  currentTransaction.card.maskedPan[0] == "5" ||
                  currentTransaction.card.maskedPan[0] == "6"
                    ? "MasterCard"
                    : currentTransaction.card.maskedPan[0] == "4"
                    ? "Visa"
                    : currentTransaction.card.maskedPan[0] == "9"
                    ? "Arca"
                    : "-"
                }}
              </h5>
            </div>

            <div class="data">
              <h6>{{ "transactions.order" | translate }}</h6>
              <h5>{{ currentTransaction.transactionId }}</h5>
            </div>

            <div class="data">
              <h6 scope="col">
                {{ "transactions.certificationCode" | translate }}
              </h6>
              <h5>
                {{
                  currentTransaction.authCode == "" ||
                  currentTransaction.authCode == " "
                    ? "-"
                    : currentTransaction.authCode
                }}
              </h5>
            </div>

            <div class="data" *ngIf="currentTransaction?.receiver != '  '">
              <h6 scope="col">{{ "transactions.receiver" | translate }}</h6>
              <h5>{{ currentTransaction.receiver }}</h5>
            </div>

            <div class="data">
              <h6 scope="col">{{ "transactions.state" | translate }}</h6>
              <h5 class="bold">
                {{
                  currentTransaction.transactionStatus == 2
                    ? ("transactions.selectPaidUnpaid.paid" | translate)
                    : currentTransaction.transactionStatus == 4
                    ? ("transactions.selectPaidUnpaid.refund" | translate)
                    : currentTransaction.transactionStatus == 3
                    ? ("transactions.selectPaidUnpaid.reversed" | translate)
                    : currentTransaction.transactionStatus == 0 ||
                      currentTransaction.transactionStatus == 1
                    ? ("transactions.selectPaidUnpaid.unPaid" | translate)
                    : "-"
                }}
              </h5>
            </div>

            <div class="data" *ngIf="currentTransaction.refundedAmount > 0">
              <h6 scope="col">{{ "transactions.totalRefund" | translate }}</h6>
              <h5>{{ currentTransaction.refundedAmount }}</h5>
            </div>

            <div class="data" *ngIf="currentTransaction.isLink == 1">
              <h6 scope="col">{{ "transactions.requestType" | translate }}</h6>
              <h5>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 465 465"
                  style="enable-background: new 0 0 465 465"
                  xml:space="preserve"
                >
                  <path
                    d="M370.656,290.363c-30.818,0-57.958,16.049-73.502,40.227l-122.311-64.265c4.39-10.408,6.819-21.838,6.819-33.824
	s-2.429-23.416-6.819-33.824l122.311-64.265c15.545,24.178,42.684,40.227,73.502,40.227c48.148,0,87.319-39.171,87.319-87.318
	C457.975,39.171,418.804,0,370.656,0c-48.147,0-87.318,39.171-87.318,87.319c0,11.987,2.429,23.416,6.819,33.824l-122.311,64.265
	c-15.545-24.178-42.684-40.227-73.502-40.227c-48.147,0-87.318,39.171-87.318,87.319s39.171,87.319,87.318,87.319
	c30.818,0,57.958-16.049,73.502-40.227l122.311,64.265c-4.39,10.408-6.819,21.838-6.819,33.824
	c0,48.148,39.171,87.319,87.318,87.319c48.148,0,87.319-39.171,87.319-87.319C457.975,329.534,418.804,290.363,370.656,290.363z
	 M370.656,15c39.877,0,72.319,32.442,72.319,72.319c0,39.876-32.442,72.318-72.319,72.318s-72.318-32.442-72.318-72.318
	C298.337,47.442,330.779,15,370.656,15z M94.343,304.819c-39.876,0-72.318-32.442-72.318-72.319s32.442-72.319,72.318-72.319
	s72.318,32.442,72.318,72.319S134.219,304.819,94.343,304.819z M370.656,450c-39.877,0-72.318-32.442-72.318-72.319
	c0-39.876,32.441-72.318,72.318-72.318s72.319,32.442,72.319,72.318C442.975,417.558,410.533,450,370.656,450z"
                  />
                </svg>
              </h5>
            </div>
            <div class="data" *ngIf="currentTransaction.isLink == 3">
              <h6 scope="col">{{ "transactions.requestType" | translate }}</h6>
              <h5>
                <svg
                  version="1.1"
                  id="Icons"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 32 32"
                  style="enable-background: new 0 0 32 32"
                  xml:space="preserve"
                >
                  <style type="text/css">
                    .st0 {
                      fill: none;
                      stroke: #000000;
                      stroke-width: 2;
                      stroke-linecap: round;
                      stroke-linejoin: round;
                      stroke-miterlimit: 10;
                    }
                  </style>
                  <line class="st0" x1="10" y1="12" x2="19" y2="12" />
                  <line class="st0" x1="10" y1="16" x2="14" y2="16" />
                  <path
                    class="st0"
                    d="M11,4c-4.4,0-8,3.6-8,8v12v5l0,0c3.7-3.2,8.4-5,13.3-5H21c4.4,0,8-3.6,8-8v-4c0-4.4-3.6-8-8-8H11z"
                  />
                </svg>
              </h5>
            </div>

            <div class="data comment">
              <h6 scope="col">{{ "transactions.comment" | translate }}</h6>
              <h5
                *ngIf="
                  currentTransaction.comment != '' ||
                  currentTransaction.comment != ' '
                "
                title="{{ currentTransaction.comment }}"
              >
                {{ currentTransaction.comment }}
              </h5>
              <h5
                *ngIf="
                  currentTransaction.comment == '' ||
                  currentTransaction.comment == ' '
                "
              >
                -
              </h5>
            </div>
          </div>
        </div>
        <div class="modal-footer my_modal-footer" *ngIf="showModalInfo">
          <div style="cursor: pointer" class="reverse">
            <form
              class="form_email"
              [formGroup]="emailSend"
              (submit)="sendSMS()"
              *ngIf="
                currentTransaction.transactionStatus == 4 ||
                currentTransaction.transactionStatus == 2
              "
            >
              <input
                type="email"
                placeholder="Email"
                class="emailSend"
                formControlName="email"
              />
              <button
                type="submit"
                class="btn btn-success"
                [disabled]="!emailSend.get('email').valid || disableEmailButton"
              >
                {{ "transactions.send" | translate }}
              </button>
            </form>
            <button
              class="btn btn-secondary"
              #infoModal
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#reverse"
              *ngIf="
                canReverseMerchant && currentTransaction.transactionStatus == 2
              "
              (click)="closeModal()"
            >
              {{ "transactions.returnAmount" | translate }}
            </button>

            <div class="deleteTransaction">
              <button
                class="btn btn-danger"
                #infoModal
                data-bs-dismiss="modal"
                data-bs-toggle="modal"
                data-bs-target="#deleteTrans"
                *ngIf="
                  currentTransaction.transactionStatus == 0 ||
                  currentTransaction.transactionStatus == 1
                "
                (click)="closeModal()"
              >
                {{ "transactions.delete" | translate }}
              </button>
            </div>

            <!-- <div class="refreshTransaction">
              <button
                class="btn btn-secondary"
                *ngIf="
                  currentTransaction.transactionStatus == 0 ||
                  currentTransaction.transactionStatus == 1
                "
                (click)="checkTransactionStatus(currentTransaction)"
              >
                {{ "transactions.refresh" | translate }}
              </button>
            </div> -->
          </div>
          <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
          <div
            class="alert alert-danger mt-3"
            *ngIf="errorSendMailSms"
            role="alert"
          >
            {{ "transactions.emailerror" | translate }}
          </div>
          <div class="alert alert-success mt-3" *ngIf="sendedSms" role="alert">
            {{ "transactions.emailsend" | translate }}
          </div>
        </div>
        <div
          class="modal-footer my_modal-footer2"
          *ngIf="
            canRefundMerchant &&
            showModalInfo &&
            (currentTransaction.transactionStatus == 2 ||
              currentTransaction.transactionStatus == 4)
          "
        >
          <div class="reverse">
            <h5>{{ "transactions.pReturn" | translate }}</h5>
          </div>

          <form
            class="form_email"
            [formGroup]="refundSend"
            (submit)="refundAmount()"
          >
            <input
              appOnlyNumber
              type="number"
              placeholder="{{ 'transactions.amount' | translate }}"
              class="emailSend"
              formControlName="amount"
            />
            <button
              type="submit"
              class="btn btn-danger"
              [disabled]="
                !refundSend.get('amount').valid || disableRefundButton
              "
            >
              {{ "transactions.refundAmount" | translate }}
            </button>
          </form>
          <div class="alert alert-danger mt-3" *ngIf="errorRefund" role="alert">
            {{ "transactions.refundError" | translate }}
          </div>
          <div
            class="alert alert-success mt-3"
            *ngIf="sendedRefund"
            role="alert"
          >
            {{ "transactions.refundSend" | translate }}
          </div>
        </div>

        <div class="table-height" *ngIf="showModalInfo">
          <table class="table">
            <ng-container *ngIf="currentTransaction.refundedAmount > 0">
              <thead>
                <tr>
                  <th class="no" scope="col">
                    {{ "transactions.refundDate" | translate }}
                  </th>
                  <th class="no" scope="col">
                    {{ "transactions.amount" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rt of currentTransaction.refundedHistory">
                  <td>{{ rt.date | date : "dd/MM/yy HH:mm" }}</td>
                  <td>{{ rt.refundedAmount }}</td>
                </tr>
              </tbody>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="reverse"
    tabindex="-1"
    aria-labelledby="reverseLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="reverseLabel">
            {{ "transactions.reverse" | translate }}
          </h5>
          <button
            type="button"
            #reverseModal
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ "transactions.confirm" | translate }}
          <div
            class="alert alert-danger mt-3"
            *ngIf="refverseErrorMsg"
            role="alert"
          >
            {{ "transactions.errorMessageReverse" | translate }}
          </div>
        </div>
        <div class="modal-footer" *ngIf="showModalInfo">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ "transactions.no" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            (click)="reverseTransaction(currentTransaction)"
            #disableReverseBtn
          >
            {{ "transactions.yes" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="deleteTrans"
    tabindex="-1"
    aria-labelledby="deleteTransLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h5 class="modal-title" id="deleteTransLabel">{{'transactions.delete' |translate}}</h5> -->
          <button
            type="button"
            #reverseModal
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ "transactions.deleteQuetion" | translate }}
          <div
            class="alert alert-danger mt-3"
            *ngIf="refverseErrorMsg"
            role="alert"
          >
            {{ "transactions.errorMessageReverse" | translate }}
          </div>
        </div>
        <div class="modal-footer" *ngIf="showModalInfo">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ "transactions.no" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            (click)="blockTransaction()"
            data-bs-dismiss="modal"
          >
            {{ "transactions.yes" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
