import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataExchangeService } from './dataExchange.service';

@Injectable({
  providedIn: 'root'
})
export class GetMerchantForUserService {

  id = +localStorage.getItem('id');
  token = localStorage.getItem('token');
  constructor(private http: HttpClient, private dataEx: DataExchangeService) { }


  getMerchantUser(id){
    return this.http.get(`api/MerchantUser/GetMerchantUser?id=${id}`)
  }

  getTransactions(merchantId: number, status: any, page: any, data, count: any = 50,){
    let body = {
      id: merchantId,
      page: page,
      count: count,
      status: status,
      start: data.start,
      end: data.end,
    }
    return this.http.post(`/api/Payment/GetTransactionsByPageMerchantUser`, body, {observe: 'response'})
  }
  getTransactionsByMerchantUser(merchantId: number){
    return this.http.get(`/api/Payment/GetTransactionsByMerchantUser?id=${merchantId}`)
  }
  getTransactionsOnlyPaid(trId){
    return this.http.get(`/api/Payment/GetTransactionsOnlyPaid?id=${trId}`)
  }
  getTransactionsNotPaid(trId){
    return this.http.get(`/api/Payment/GetTransactionsNotPaid?id=${trId}`)
  }
  getTransactionsReversed(trId){
    return this.http.get(`/api/Payment/GetTransactionsReversed?id=${trId}`)
  }
}
