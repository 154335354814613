import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { MerchantUser } from "../models/merchantUser.model";

@Injectable({
  providedIn: "root",
})
export class MerchantUserService {
  constructor(private http: HttpClient) {}

  addUser(user: MerchantUser) {
    return this.http.post(`/api/MerchantUser/AddMerchantUser`, user, {
      observe: "response",
    });
  }

  getUsers() {
    return this.http.get(`/api/MerchantUser/GetMerchantUsers`, {
      observe: "response",
    });
  }

  getUsersByPage(page, count) {
    return this.http.get(`/api/MerchantUser/GetMerchantUsersByPage?page=${page}&count=${count}`, {
      observe: "response",
    });
  }

  getUsersByMerchant(id: number) {
    const params = new HttpParams().set("merchantId", id.toString());
    return this.http.get(`/api/MerchantUser/GetMerchantUsersByMerchant`, {
      params,
      observe: "response",
    });
  }

  deleteMerchantUser(id: number) {
    const params = new HttpParams().set("id", id.toString());
    return this.http.delete(`/api/MerchantUser/RemoveMerchantUser`, {
      params,
      observe: "response",
    });
  }

  disableMerchantUser(id: number) {
    return this.http.put(`/api/MerchantUser/ChangeMerchantUserStatus?merchantUserId=${id}`,{
      observe: 'response'
    })
  }

  getUsersByName(name: string) {
    const params = new HttpParams().set("q", name.toString());
    return this.http.get(`/api/MerchantUser/FindMerchantUsersByUsername`, {
      params,
      observe: "response",
    });
  }

  updateUser(user: MerchantUser) {
    return this.http.put(`/api/MerchantUser/UpdateMerchantUser`, user, {
      observe: "response",
    });
  }

  changePassword(password, old) {
    let obj = {
      password: old,
      newPassword: password
    }
    return this.http.post(`/api/MerchantUser/ChangePassword`, obj, {
      observe: "response",
    });
  }
}
