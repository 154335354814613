<div class="wrapper">
  <div class="main-info" *ngIf="merchant">
    <header>
      <!-- <div class="left-side"> -->
      <div class="logo">
        <img [src]="merchant.merchantInfo.logo" alt="Company logo" />
      </div>
      <div class="company-name">
        <h3>{{ merchant.merchantInfo.companyNameEn }}</h3>
      </div>
      <div class="status">
        <select
          #stat
          (change)="changeStatus(stat.value)"
          class="form-select form-select-sm"
          aria-label=".form-select-sm example"
        >
          <option [selected]="merchant.status == 0" value="0">
            In Process
          </option>
          <option [selected]="merchant.status == 1" value="1">Active</option>
          <option [selected]="merchant.status == 2" value="2">Closed</option>
        </select>
      </div>
      <div class="buttons">
        <button class="btn btn-warning" (click)="updatePage()">
          Update Merchant
        </button>
        <!-- <button [disabled]="disableMerchantDelete" class="btn btn-danger" data-toggle="modal"
          data-target="#deleteModal">
          Dalete Merchant</button> -->
        <button
          #allusersmodal
          class="btn btn-secondary"
          data-bs-toggle="modal"
          data-bs-target="#usersModal"
        >
          Users
        </button>

        <button
          class="btn btn-success"
          (click)="openRegisterMerchantDialog(registerMerchantApplePayTemplate)"
        >
          Register apple pay
        </button>
      </div>
      <!-- </div> -->
      <!-- <div class="right-side">

      </div> -->
    </header>

    <section>
      <div class="merchant-info">
        <div class="left-side">
          <div class="bl">
            <div class="name">Internal ID:&nbsp;</div>
            <div class="data">
              {{ merchant.merchantId }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Company name HY:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantInfo.companyNameHy == ''
              }"
            >
              {{
                merchant.merchantInfo.companyNameHy == ""
                  ? "Not Set"
                  : merchant.merchantInfo.companyNameHy
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Company name EN:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantInfo.companyNameEn == ''
              }"
            >
              {{
                merchant.merchantInfo.companyNameEn == ""
                  ? "Not Set"
                  : merchant.merchantInfo.companyNameEn
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Company name RU:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantInfo.companyNameRu == ''
              }"
            >
              {{
                merchant.merchantInfo.companyNameRu == ""
                  ? "Not Set"
                  : merchant.merchantInfo.companyNameRu
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Address En:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.merchantInfo.addressEn == '' }"
            >
              <address>
                {{
                  merchant.merchantInfo.addressEn == ""
                    ? "Not Set"
                    : merchant.merchantInfo.addressEn
                }}
              </address>
            </div>
          </div>

          <div class="bl">
            <div class="name">Address Hy:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.merchantInfo.addressHy == '' }"
            >
              {{
                merchant.merchantInfo.addressHy == ""
                  ? "Not Set"
                  : merchant.merchantInfo.addressHy
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Legal company name:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantInfo.legalCompanyName == ''
              }"
            >
              {{
                merchant.merchantInfo.legalCompanyName == ""
                  ? "Not Set"
                  : merchant.merchantInfo.legalCompanyName
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Legal address:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantInfo.legalAddress == ''
              }"
            >
              {{
                merchant.merchantInfo.legalAddress == ""
                  ? "Not Set"
                  : merchant.merchantInfo.legalAddress
              }}
            </div>
          </div>
        </div>

        <div class="right-side">
          <div class="bl">
            <div class="name">Phone:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.merchantInfo.phone == '' }"
            >
              {{
                merchant.merchantInfo.phone == ""
                  ? "Not Set"
                  : merchant.merchantInfo.phone
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Sms phone:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.merchantInfo.smsPhone == '' }"
            >
              {{
                merchant.merchantInfo.smsPhone == ""
                  ? "Not Set"
                  : merchant.merchantInfo.smsPhone
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Email:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.merchantInfo.email == '' }"
            >
              {{
                merchant.merchantInfo.email == ""
                  ? "Not Set"
                  : merchant.merchantInfo.email
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Website:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.merchantInfo.website == '' }"
            >
              {{
                merchant.merchantInfo.website == ""
                  ? "Not Set"
                  : merchant.merchantInfo.website
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Director:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.merchantInfo.director == '' }"
            >
              {{
                merchant.merchantInfo.director == ""
                  ? "Not Set"
                  : merchant.merchantInfo.director
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Bank serial number:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantInfo.bankSerialNumber == ''
              }"
            >
              {{
                merchant.merchantInfo.bankSerialNumber == ""
                  ? "Not Set"
                  : merchant.merchantInfo.bankSerialNumber
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Tin:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.merchantInfo.tin == '' }"
            >
              {{
                merchant.merchantInfo.tin == ""
                  ? "Not Set"
                  : merchant.merchantInfo.tin
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Token:&nbsp;</div>
            <div class="data">
              {{ merchant.payxToken }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Edit comments:&nbsp;</div>
            <div class="data">
              <input
                (change)="changeCommentEdit($event)"
                class="form-check-input"
                #event
                [checked]="merchant?.canChangeComment"
                type="checkbox"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="secondary-info">
        <div class="left-side">
          <div class="bl">
            <div class="name">Api:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantArcaDetails.tidApi == ''
              }"
            >
              {{
                merchant.merchantArcaDetails.tidApi == ""
                  ? "Not Set"
                  : merchant.merchantArcaDetails.tidApi
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Api password:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantArcaDetails.bpcTidApiPassword == ''
              }"
            >
              {{
                merchant.merchantArcaDetails.bpcTidApiPassword == ""
                  ? "Not Set"
                  : merchant.merchantArcaDetails.bpcTidApiPassword
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Binding:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantArcaDetails.tidBinding == ''
              }"
            >
              {{
                merchant.merchantArcaDetails.tidBinding == ""
                  ? "Not Set"
                  : merchant.merchantArcaDetails.tidBinding
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Binding password:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set':
                  merchant.merchantArcaDetails.bpcTidBindingPassword == ''
              }"
            >
              {{
                merchant.merchantArcaDetails.bpcTidBindingPassword == ""
                  ? "Not Set"
                  : merchant.merchantArcaDetails.bpcTidBindingPassword
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">EPG username:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantArcaDetails.epgUsername == ''
              }"
            >
              {{
                merchant.merchantArcaDetails.epgUsername == ""
                  ? "Not Set"
                  : merchant.merchantArcaDetails.epgUsername
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">EPG password:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set': merchant.merchantArcaDetails.epgPassword == ''
              }"
            >
              {{
                merchant.merchantArcaDetails.epgPassword == ""
                  ? "Not Set"
                  : merchant.merchantArcaDetails.epgPassword
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Merchant arca perecent:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set':
                  merchant.merchantPercent.merchantArcaPercent == undefined
              }"
            >
              {{
                merchant.merchantPercent.merchantArcaPercent == ""
                  ? "Not Set"
                  : merchant.merchantPercent.merchantArcaPercent
              }}
              %
            </div>
          </div>

          <div class="bl">
            <div class="name">Merchant local perecent:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set':
                  merchant.merchantPercent.merchantLocalPercent == undefined
              }"
            >
              {{
                merchant.merchantPercent.merchantLocalPercent == ""
                  ? "Not Set"
                  : merchant.merchantPercent.merchantLocalPercent
              }}
              %
            </div>
          </div>

          <div class="bl">
            <div class="name">Merchant out perecent:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set':
                  merchant.merchantPercent.merchantOutPercent == undefined
              }"
            >
              {{
                merchant.merchantPercent.merchantOutPercent == ""
                  ? "Not Set"
                  : merchant.merchantPercent.merchantOutPercent
              }}
              %
            </div>
          </div>

          <div class="bl">
            <div class="name">Sphere of activity:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant?.sphereOfActivity == '' }"
            >
              {{
                merchant?.sphereOfActivity == ""
                  ? "Not Set"
                  : merchant?.sphereOfActivity
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Domain name:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{
                'not-set':
                  merchant.domain.title == '' || merchant.domain.title == null
              }"
            >
              {{
                merchant.domain.title == "" || merchant.domain.title == null
                  ? "Not Set"
                  : merchant.domain.title
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Mcc:&nbsp;</div>
            <div class="data" [ngClass]="{ 'not-set': merchant.mcc == '' }">
              {{ merchant.mcc == "" ? "Not Set" : merchant.mcc }}
            </div>
          </div>
        </div>

        <div class="right-side">
          <div class="bl">
            <div class="name">Fee type:&nbsp;</div>
            <div class="data" [ngClass]="{ 'not-set': merchant.feeType == 0 }">
              {{
                merchant.feeType == 0
                  ? "Not Set"
                  : merchant.feeType == 1
                  ? "Fixed"
                  : "Percent"
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Is offline:&nbsp;</div>
            <div class="data">
              {{ merchant.isOffline ? "Yes" : "No" }}
            </div>
          </div>

          <div class="bl">
            <div class="name">Merchant type:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.merchantType == 0 }"
            >
              {{
                merchant.merchantType == 0
                  ? "Not Set"
                  : merchant.merchantType == 1
                  ? "Merchant"
                  : "MobileApp"
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">TID:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.bankInternalTid == '' }"
            >
              {{
                merchant.bankInternalTid == ""
                  ? "Not Set"
                  : merchant.bankInternalTid
              }}
            </div>
          </div>

          <div class="bl">
            <div class="name">MID:&nbsp;</div>
            <div
              class="data"
              [ngClass]="{ 'not-set': merchant.bankInternalMid == '' }"
            >
              {{
                merchant.bankInternalMid == ""
                  ? "Not Set"
                  : merchant.bankInternalMid
              }}
            </div>
          </div>

          <div class="bl" *ngIf="isMerchantGroup">
            <div class="name">Merchant group:&nbsp;</div>
            <div class="data">
              {{ merchantGroupName }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!--
  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header background-orange">
          <h1 class="modal-title" id="exampleModalLabel">Warrning</h1>
          <button type="button" id='closeAddUserModal' class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Are you want to delete this merchant?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" id='closeDeleteModal'>Cancel</button>
          <button type="button" class="btn btn-warning" data-dismiss="modal" (click)='disableMerchant()'>Yes</button>
        </div>
      </div>
    </div>
  </div> -->

  <div
    class="modal fade"
    id="usersModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="usersModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="usersModalLabel">Users</h5>
          <button
            #mainUserModal
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="add-user">
            <button
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#add_user_modal"
              (click)="closeMainUserModal()"
            >
              Add User
            </button>
          </div>
          <div class="my-modal-body" *ngIf="users.length > 0">
            <div class="users" *ngFor="let user of users">
              <div class="user">
                <div class="bl">
                  <div class="name">Username:&nbsp;</div>
                  <div class="data">
                    {{ user.username }}
                  </div>
                </div>

                <div class="bl">
                  <div class="name">Can Reverse:&nbsp;</div>
                  <div class="data">
                    <input
                      type="checkbox"
                      [(ngModel)]="user.canReverse"
                      (change)="updateUser(user)"
                    />
                  </div>
                </div>

                <div class="bl">
                  <div class="name">Can Refund:&nbsp;</div>
                  <div class="data">
                    <input
                      type="checkbox"
                      [(ngModel)]="user.canRefund"
                      (change)="updateUser(user)"
                    />
                  </div>
                </div>

                <div class="bl">
                  <div class="name">Is general user?:&nbsp;</div>
                  <div class="data">
                    <input
                      type="checkbox"
                      [(ngModel)]="user.isGeneralUser"
                      (change)="updateUser(user)"
                    />
                  </div>
                </div>

                <div class="bl">
                  <div class="name">Can generate multi qr?:&nbsp;</div>
                  <div class="data">
                    <input
                      type="checkbox"
                      [(ngModel)]="user.canGenerateMultiQR"
                      (change)="updateUser(user)"
                    />
                  </div>
                </div>

                <div class="bl">
                  <div class="name">Show QR?:&nbsp;</div>
                  <div class="data">
                    <input
                      type="checkbox"
                      [(ngModel)]="user.canGenerateQR"
                      (change)="updateUser(user)"
                    />
                  </div>
                </div>

                <div class="buttons">
                  <button
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#attach_merchant_modal"
                    (click)="closeMainUserModal(); selecteduser = user"
                  >
                    Update User
                  </button>
                  <button
                    class="btn btn-danger"
                    *ngIf="!user.isBlocked"
                    (click)="deleteUser(user.merchantUserId)"
                  >
                    Block User
                  </button>
                  <button
                    class="btn btn-danger"
                    *ngIf="user.isBlocked"
                    (click)="deleteUser(user.merchantUserId)"
                  >
                    Unblock User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="add_user_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">New User</h5>
          <button
            #closeUserModal
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="new-user">
            <div class="bl">
              <div class="name">Username:&nbsp;</div>
              <div class="data">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="user.username"
                />
              </div>
            </div>

            <div class="bl">
              <div class="name">Password:&nbsp;</div>
              <div class="data">
                <input
                  type="password"
                  class="form-control"
                  [(ngModel)]="user.password"
                />
              </div>
            </div>

            <div class="bl">
              <div class="name">Can reverse?:&nbsp;</div>
              <div class="data">
                <input type="checkbox" [(ngModel)]="user.canReverse" />
              </div>
            </div>

            <div class="bl">
              <div class="name">Is general user?:&nbsp;</div>
              <div class="data">
                <input type="checkbox" [(ngModel)]="user.isGeneralUser" />
              </div>
            </div>

            <div class="bl">
              <div class="name">Can generate multi qr?:&nbsp;</div>
              <div class="data">
                <input type="checkbox" [(ngModel)]="user.canGenerateMultiQR" />
              </div>
            </div>

            <div class="bl">
              <div class="name">Show QR?:&nbsp;</div>
              <div class="data">
                <input type="checkbox" [(ngModel)]="user.canGenerateQR" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" (click)="newUser()">
            Add user
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="attach_merchant_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">User update</h5>
          <button
            #closeUserUpdateModal
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="new-user" *ngIf="selecteduser">
            <div class="bl">
              <div class="name">Username:&nbsp;</div>
              <div class="data">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="selecteduser.username"
                />
              </div>
            </div>

            <div class="bl">
              <div class="name">Password:&nbsp;</div>
              <div class="data">
                <input
                  type="password"
                  class="form-control"
                  [(ngModel)]="selecteduser.password"
                />
              </div>
            </div>

            <!-- <div class="bl" *ngFor="let m of selecteduser.merchants;index as i">
              <div class="name">
                Merchant-{{i+1}}:&nbsp;
              </div>
              <div class="data">
                {{m.title}}
                <div class="delete">
                  <button>X</button>
                </div>
              </div>
            </div> -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="updateUser(selecteduser, '1')"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #registerMerchantApplePayTemplate>
    <form
      [formGroup]="registerMerchantApplePayFormGroup"
      (ngSubmit)="registerMerchantToApplePay()"
    >
      <div class="modal-template">
        <div class="form-group">
          <label for="encryptTo">Encrypt To</label>
          <input
            type="text"
            id="encryptTo"
            class="form-control"
            formControlName="encryptTo"
          />
        </div>

        <div class="form-group">
          <label for="domainNames">Domain names</label>
          <input
            type="text"
            id="domainNames"
            class="form-control"
            placeholder="ex. domain1.name domain2.name domain3.name"
            formControlName="domainNames"
          />
        </div>

        <div class="form-group">
          <label for="partnerMerchantName">Partner merchant name</label>
          <input
            type="text"
            id="partnerMerchantName"
            class="form-control"
            formControlName="partnerMerchantName"
          />
        </div>

        <button
          type="submit"
          class="btn btn-success"
          [disabled]="registerMerchantApplePayFormGroup.ivalid"
        >
          Register
        </button>
      </div>
    </form>
  </ng-template>
</div>
