<div class="wrapper">

  <div class="button">
    <button class="btn my-btn-color" data-toggle="modal" data-target="#addModal">Add Bank</button>
  </div>

  <div class="bl" *ngFor="let bank of banks">
    <div class="name">
      {{bank.title}}:&nbsp;
    </div>
    <div class="data">
      <button class="btn btn-warning" (click)='selectedBank=bank' data-toggle="modal"
        data-target="#infoModalUpdate">Update</button>
      <!-- <button class="btn btn-danger" (click)='selectedBank=bank' data-toggle="modal"
        data-target="#infoModal">Delete</button> -->
    </div>
  </div>
</div>


<div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add new bank</h5>
        <button #closeAddModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Bank name<br>
        <input type='text' [(ngModel)]='bank.title' class='input-title-wrapper'> -->
        <div class="bl">
          <div class="name">
            Bank name:&nbsp;
          </div>
          <div class="data">
            <input type="text" class="form-control" [(ngModel)]='bank.title'>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id='closeAddModal'>Cancel</button>
        <button type="button" class="btn btn-danger" (click)='addBank()'>Add</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header back-orange">
        <h5 class="modal-title" id="exampleModalLabel">Warrning</h5>
        <button #closeInfoModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>Are you want delete {{selectedBank.title}}</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id='closeInfoModal'>No</button>
        <button type="button" class="btn btn-warning" (click)='deleteBank(selectedBank.id)'>Yes</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="infoModalUpdate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header back-orange">
        <h5 class="modal-title" id="exampleModalLabel">Update</h5>
        <button #closeUpdateModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="bankName" class="form-label">Bank Name</label>
          <input type="text" class="form-control" id="bankName" placeholder="{{selectedBank.title}}"
            [(ngModel)]='selectedBank.title'>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id='closeInfoModal'>No</button>
        <button type="button" class="btn btn-warning" data-dismiss="modal" (click)='updateBank()'>Yes</button>
      </div>
    </div>
  </div>
</div>
