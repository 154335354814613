import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, private http: HttpClient) {}

  refresh: boolean = false;

  intercept(
    req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      if(this.router.url.includes('admin'))
      {
        if (localStorage.getItem("token") != null) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          return next.handle(req).pipe(
            tap(
              (suc) => {},
              (err) => {
                if (err.status == 401 || err.status == 403) {
                  localStorage.removeItem("token");
                  this.router.navigateByUrl("adminLogin");
                }
              }
            ),
            // map((event: HttpEvent<any>) => {
            //   if (event instanceof HttpResponse) {
            //     if (event.headers.get("token") != null)
            //       localStorage.setItem("token", event.headers.get("token"));
            //     return event;
            //   }
            // })
          );
        }
        return next.handle(req);
      }

      else if(req.url.includes('/api/Login/Login')){
        return next.handle(req);
       }

      else{
        const request = req.clone(
          {
            setHeaders: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )

        return next.handle(request).pipe(
          catchError((err: HttpErrorResponse) =>{
            if(err.status === 401 && !this.refresh)
            {
              this.refresh = true
              let refreshToken = localStorage.getItem("tokenr")
              let accessToken = localStorage.getItem("token")
              if (refreshToken)
              return this.http.post('/api/Login/RefreshToken', {'accessToken': accessToken, 'refreshToken': refreshToken}).pipe(
                switchMap((res: any)=>{
                  this.refresh = false
                  console.log("interceptor",res)
                  localStorage.setItem('token', res['accessToken'])
                  localStorage.setItem('tokenr', res['refreshToken'])
                  return next.handle(req.clone(
                    {
                      setHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                      }
                    }
                  ))
                }),
                catchError((resError:any)=>{
                  localStorage.removeItem('tokenr')
                  localStorage.removeItem('token')
                  this.router.navigateByUrl('')
                  return throwError((error:any) => {
                  })
                })
              )
            }
            else{
              this.refresh = false
            }
            return throwError((error:any) => {
            })
          })
        )
        }
      }
  }
