import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ResponseScannedQr } from "../models/responseScannedQr";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  getTransactions() {
    return this.http.get(`/api/Payment/GetTransactions?id=41`, {
      observe: "response",
    });
  }

  getTransaction(id) {
    return this.http.get(`/api/Payment/GetTransactionByPxNumberPage?pxNumber=${id}`, {
      observe: "response",
    });
  }

  pay(body: ResponseScannedQr, lang: any) {
    const headers = new HttpHeaders().set('lang', lang)
    return this.http.post(`/api/Payment/Pay`, body, {headers: headers, observe: "response" });
  }

  getPaymentReciept(id) {
    let params = new HttpParams().set("transferId", id.toString());
    return this.http.get(`/api/Payment/GetPaymentReciept`, {
      observe: "response",
    });
  }
}
