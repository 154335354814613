import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "creditCardDigits",
})
export class LastFourDigitsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return "";
    }
    if (value.trim() != "")
      // Display only the last four digits
      return "********" + value.slice(-4);
  }
}
