<div class="wrapper">
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Merchant name</th>
        <th scope="col">Transaction ID</th>
        <th scope="col">Reciever</th>
        <th scope="col">Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let h of history">
        <td>{{h?.dateTime | date: 'dd/MM/yy HH:mm'}}</td>
        <td>{{h?.merchantName}}</td>
        <td>{{h?.transactionId}}</td>
        <td>{{h?.reciever}}</td>
        <td>{{h?.amount}}</td>
      </tr>
    </tbody>
  </table>
</div>
