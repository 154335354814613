export class MerchantArcaDetail {
  id: number;
  tidBinding: string;
  bpcTidBindingPassword: string;
  tidApi: string;
  bpcTidApiPassword: string;
  merchantGroupId: number;

  epgUsername: string;
  epgPassword: string;
}
