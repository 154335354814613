export class MerchantUser {
  constructor() {
    this.merchantUserId = "";
    this.merchants = [];
    this.canReverse = false;
    this.canRefund = false;
    this.isGeneralUser = false;
    this.username = "";
    this.password = "";
  }
  merchantUserId: string;
  merchants: Merchant[];
  canReverse: boolean;
  canRefund: boolean;
  isGeneralUser: boolean;
  username: string;
  password: string;
  isBlocked: boolean;
  canGenerateMultiQR: boolean;
  canGenerateQR: boolean;
}

export class Merchant {
  constructor() {
    this.id = -1;
    this.title = "";
  }
  id: number;
  title: string;
}
