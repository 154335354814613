export class MerchantInfo {
  companyNameEn: string;
  companyNameRu: string;
  companyNameHy: string;
  logo: string;
  addressEn: string;
  addressHy: string;
  legalCompanyName: string;
  legalAddress: string;
  tin: string;
  phone: string;
  bankSerialNumber: string;
  website: string;
  email: string;
  smsPhone: string;
  director: string;
}
