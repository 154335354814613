import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Bank } from '../models/bank.model';

@Injectable({
  providedIn: 'root'
})
export class BankService {

constructor(private http:HttpClient) { }

  getBanks(){
    return this.http.get(`/api/Bank/GetBanks`,{observe:"response"})
  }

  addBank(bank:Bank){
    return this.http.post(`/api/Bank/AddBank`,bank,{observe:"response"})
  }

  updateBank(bank:Bank){
    return this.http.put(`/api/Bank/UpdateBank`,bank,{observe:"response"})
  }

  deleteBank(id:number){
    const params=new HttpParams()
    .set("id",id.toString());
    return this.http.delete(`/api/Bank/DeleteBank`,{params,observe:"response"})
  }
}
