<header>
  <div class="logo">
    <!-- <svg
      width="148"
      height="40"
      viewBox="0 0 148 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_616_1368)">
        <path
          d="M69.0448 33.3242H72.4197C73.0056 33.3242 73.4688 33.1714 73.8092 32.8662C74.1494 32.5607 74.3195 32.1473 74.3195 31.6252C74.3195 31.3155 74.2579 31.0591 74.135 30.8559C74.0122 30.6524 73.8492 30.4907 73.6459 30.3712C73.4427 30.2514 73.2085 30.1677 72.9442 30.1188C72.6792 30.0704 72.4053 30.0458 72.1218 30.0458H69.0448V33.3242ZM69.0448 28.9845H71.7391C72.5045 28.9845 73.0551 28.8584 73.3908 28.606C73.7263 28.3536 73.8943 27.9759 73.8943 27.4711C73.8943 27.1441 73.8374 26.8804 73.7239 26.6818C73.6105 26.4825 73.4545 26.3298 73.2562 26.2238C73.0575 26.1176 72.8282 26.0493 72.5686 26.018C72.3083 25.9873 72.0318 25.9714 71.7391 25.9714H69.0448V28.9845ZM67.6978 24.9101H71.37C71.5972 24.9101 71.8331 24.912 72.0793 24.9167C72.3248 24.9214 72.5686 24.9345 72.8094 24.9562C73.0504 24.9787 73.2795 25.0117 73.4972 25.0559C73.7145 25.1001 73.899 25.1667 74.0502 25.2552C74.3808 25.4409 74.662 25.6996 74.8938 26.0315C75.1252 26.3635 75.2411 26.7637 75.2411 27.2325C75.2411 27.7366 75.1088 28.1728 74.844 28.5394C74.5795 28.9066 74.2107 29.1791 73.738 29.356V29.3824C74.3622 29.5064 74.8393 29.7717 75.1703 30.1789C75.5011 30.5857 75.6665 31.0809 75.6665 31.6652C75.6665 32.01 75.6002 32.3466 75.4678 32.6739C75.3355 33.0011 75.1396 33.2912 74.8797 33.5429C74.6195 33.7953 74.2983 33.9986 73.9154 34.1536C73.5327 34.3087 73.0904 34.3859 72.5895 34.3859H67.6978V24.9101Z"
          fill="#FF6700"
        />
        <path
          d="M79.7781 30.4708H83.2093L81.5223 26.0516H81.4939L79.7781 30.4708ZM80.7849 24.9103H82.2736L86.2294 34.3859H84.7408L83.6347 31.5325H79.3526L78.2186 34.3859H76.8289L80.7849 24.9103Z"
          fill="#FF6700"
        />
        <path
          d="M87.4358 24.9102H88.8681L94.1994 32.5944H94.2278V24.9102H95.5037V34.386H94.0291L88.7403 26.7813H88.7119V34.386H87.4358V24.9102Z"
          fill="#FF6700"
        />
        <path
          d="M96.9207 24.9102H98.2677V29.6349L103.4 24.9102H105.145L100.876 28.7322L105.315 34.386H103.613L99.9551 29.5946L98.2677 31.0681V34.386H96.9207V24.9102Z"
          fill="#FF6700"
        />
        <path
          d="M89.5942 11.6575H92.2975L95.8072 17.4862H95.8356V11.6575H98.3124V21.1155H95.6091L92.0994 15.221H92.071V21.1155H89.5942V11.6575Z"
          fill="#002366"
        />
        <path
          d="M105.709 21.1156H102.681L99.4963 11.6576H102.242L104.223 18.6915H104.251L106.233 11.6576H108.979L105.709 21.1156Z"
          fill="#002366"
        />
        <path
          d="M110.163 11.6575H118.215V13.6313H112.795V15.2869H117.748V17.1943H112.795V18.9957H118.357V21.1155H110.163V11.6575Z"
          fill="#002366"
        />
        <path
          d="M122.423 15.7642H124.631C125.423 15.7642 125.847 15.3798 125.847 14.6512C125.847 13.9492 125.253 13.6313 124.517 13.6313H122.423V15.7642ZM119.79 11.6575H124.942C126.711 11.6575 128.48 12.4126 128.48 14.32C128.48 15.3398 127.971 16.3069 126.895 16.6912V16.7176C127.956 16.9695 128.296 18.0818 128.367 18.9829C128.409 19.3936 128.437 20.7447 128.791 21.1155H126.187C125.961 20.7843 125.933 19.8837 125.89 19.6053C125.819 18.6514 125.664 17.6715 124.418 17.6715H122.423V21.1155H119.79V11.6575Z"
          fill="#002366"
        />
        <path
          d="M132.306 17.9364C132.362 19.0494 133.084 19.4066 134.216 19.4066C135.009 19.4066 135.83 19.1418 135.83 18.4395C135.83 17.5921 134.386 17.4461 132.928 17.0618C131.499 16.6781 129.971 16.0819 129.971 14.3336C129.971 12.2672 132.192 11.4592 134.089 11.4592C136.085 11.4592 138.137 12.386 138.137 14.5053H135.504C135.547 13.6578 134.669 13.3666 133.877 13.3666C133.31 13.3666 132.603 13.5518 132.603 14.1745C132.603 14.916 134.061 15.0356 135.532 15.4192C136.99 15.8035 138.462 16.4391 138.462 18.1483C138.462 20.546 136.282 21.3139 134.061 21.3139C131.754 21.3139 129.673 20.3605 129.673 17.9364H132.306Z"
          fill="#002366"
        />
        <path
          d="M139.748 11.6575H147.8V13.6313H142.38V15.2869H147.333V17.1943H142.38V18.9957H147.942V21.1155H139.748V11.6575Z"
          fill="#002366"
        />
        <path
          d="M83.4596 18.7248C82.1615 18.7248 81.1114 17.6792 81.1114 16.3865C81.1114 15.0939 82.1615 14.0452 83.4596 14.0452C84.7578 14.0452 85.8109 15.0939 85.8109 16.3865C85.8109 17.6792 84.7578 18.7248 83.4596 18.7248ZM83.4596 11.46C80.727 11.46 78.512 13.6656 78.512 16.3865C78.512 19.1075 80.727 21.3131 83.4596 21.3131C86.1922 21.3131 88.4103 19.1075 88.4103 16.3865C88.4103 13.6656 86.1922 11.46 83.4596 11.46Z"
          fill="#002366"
        />
        <path
          d="M72.4469 18.7248C71.1488 18.7248 70.0987 17.6792 70.0987 16.3865C70.0987 15.0939 71.1488 14.0452 72.4469 14.0452C73.4547 14.0452 74.3171 14.678 74.647 15.5669H77.3281C76.9377 13.2347 74.9011 11.46 72.4469 11.46C69.7143 11.46 67.4993 13.6656 67.4993 16.3865C67.4993 19.1075 69.7143 21.3131 72.4469 21.3131C74.9011 21.3131 76.9377 19.5383 77.3281 17.2062H74.647C74.3171 18.0951 73.4547 18.7248 72.4469 18.7248Z"
          fill="#002366"
        />
        <path
          d="M29.4568 22.764C29.4568 24.033 29.3067 25.0795 29.1566 25.8999C27.6552 31.7233 22.4806 35.683 16.9327 35.683C10.0336 35.683 4.48193 30.0823 4.48193 23.2124H8.15844C8.15844 27.9892 12.1319 31.9492 16.9327 31.9492C20.8329 31.9492 24.1323 29.4115 25.3335 25.8999C25.5567 25.229 25.7801 24.1825 25.707 22.837C25.6337 21.8669 25.4065 21.1195 25.1831 20.5214C24.1323 18.432 21.957 16.9371 19.4814 16.9371H11.3811L18.1339 8.05076H8.60526V4.31696H25.5567L18.8076 13.2798H19.4814C24.059 13.2798 27.9554 16.3427 29.0833 20.5214C29.2299 21.196 29.3835 21.9436 29.4568 22.764Z"
          fill="#002366"
        />
        <path
          d="M27.7365 30.7875C28.3369 31.9798 29.0638 33.0726 29.8879 34.0425C26.7385 37.6268 22.1073 40.0151 16.9327 40.0151C7.63124 40.0151 0.0581055 32.4705 0.0581055 23.2125H3.80814C3.80814 30.4542 9.73331 36.2811 16.9327 36.2811C21.3565 36.2811 25.3379 34.0729 27.7365 30.7875Z"
          fill="#002366"
        />
        <path
          d="M8.60547 -0.0150261H34.3312L26.3077 10.5922C27.432 11.1866 28.406 11.9342 29.3837 12.8313C28.5561 13.8011 27.8824 14.9206 27.432 16.1167C25.6339 14.1001 23.0082 12.7546 20.1556 12.6088L26.8313 3.71877H8.60547V-0.0150261Z"
          fill="#002366"
        />
        <path
          d="M46.1466 22.9225C46.1466 24.7101 44.7103 26.1726 42.8826 26.1726C41.0874 26.1726 39.6184 24.7101 39.6184 22.9225C39.6184 21.1349 41.0874 19.6725 42.8826 19.6725C44.7103 19.6725 46.1466 21.1349 46.1466 22.9225ZM42.8826 15.6423C38.8678 15.6423 35.5709 18.9249 35.5709 22.9225C35.5709 26.9527 38.8678 30.2353 42.8826 30.2353C46.2119 30.2353 49.0516 27.9927 49.9331 24.9702H54.1109C53.1644 30.2678 48.4967 34.298 42.8826 34.298C36.6156 34.298 31.4908 29.1953 31.4908 22.9225C31.4908 16.6823 36.6156 11.5796 42.8826 11.5796C48.4967 11.5796 53.1644 15.6098 54.1109 20.9076H49.9331C49.0516 17.8849 46.2119 15.6423 42.8826 15.6423ZM54.9923 10.8645C51.8914 7.77703 47.6156 5.85937 42.8826 5.85937C33.4167 5.85937 25.7461 13.497 25.7461 22.9225C25.7461 27.6353 27.672 31.8931 30.7729 34.9805C33.8739 38.068 38.1497 39.9857 42.8826 39.9857C47.6156 39.9857 51.8914 38.068 54.9923 34.9805C58.0933 31.8931 60.0189 27.6353 60.0189 22.9225C60.0189 18.2098 58.0933 13.952 54.9923 10.8645Z"
          fill="#002366"
        />
        <path
          d="M134.562 30.5731H135.434V34.3226H134.562V30.5731Z"
          fill="#002366"
        />
        <path
          d="M138.7 33.4956C138.877 33.3934 139.021 33.2521 139.123 33.0699C139.222 32.8877 139.276 32.679 139.276 32.4477C139.276 32.2166 139.222 32.0077 139.123 31.8257C139.021 31.6435 138.877 31.5022 138.7 31.4C138.519 31.2996 138.318 31.2484 138.096 31.2484C137.874 31.2484 137.672 31.2996 137.496 31.4C137.315 31.5022 137.175 31.6435 137.072 31.8257C136.969 32.0077 136.92 32.2166 136.92 32.4477C136.92 32.679 136.969 32.8877 137.072 33.0699C137.175 33.2521 137.315 33.3934 137.496 33.4956C137.672 33.596 137.874 33.647 138.096 33.647C138.318 33.647 138.519 33.596 138.7 33.4956ZM137.039 34.1342C136.727 33.9663 136.48 33.7352 136.303 33.4404C136.127 33.1478 136.036 32.8161 136.036 32.4477C136.036 32.0793 136.127 31.7478 136.303 31.4531C136.48 31.1604 136.727 30.9291 137.039 30.7614C137.352 30.5936 137.705 30.5097 138.096 30.5097C138.486 30.5097 138.84 30.5936 139.152 30.7614C139.465 30.9291 139.707 31.1604 139.888 31.4531C140.069 31.7478 140.155 32.0793 140.155 32.4477C140.155 32.8161 140.069 33.1478 139.888 33.4404C139.707 33.7352 139.465 33.9663 139.152 34.1342C138.84 34.3019 138.486 34.3859 138.096 34.3859C137.705 34.3859 137.352 34.3019 137.039 34.1342Z"
          fill="#002366"
        />
        <path
          d="M144.211 30.5731V34.3225H143.496L141.617 32.0465V34.3225H140.758V30.5731H141.477L143.348 32.8489V30.5731H144.211Z"
          fill="#002366"
        />
        <path
          d="M145.484 34.2672C145.204 34.1854 144.982 34.081 144.814 33.9521L145.11 33.2993C145.27 33.4179 145.463 33.5121 145.685 33.5837C145.907 33.6553 146.129 33.6902 146.351 33.6902C146.598 33.6902 146.783 33.6532 146.898 33.5816C147.017 33.5079 147.079 33.4097 147.079 33.289C147.079 33.1989 147.042 33.1252 146.972 33.0658C146.902 33.0064 146.811 32.9594 146.705 32.9245C146.594 32.8878 146.446 32.849 146.261 32.806C145.973 32.7383 145.739 32.671 145.553 32.6034C145.373 32.5358 145.217 32.4272 145.085 32.2759C144.953 32.1264 144.888 31.926 144.888 31.6761C144.888 31.4593 144.945 31.2607 145.064 31.0848C145.184 30.9086 145.36 30.7676 145.599 30.663C145.837 30.5608 146.129 30.5096 146.474 30.5096C146.713 30.5096 146.951 30.5383 147.177 30.5936C147.407 30.6509 147.609 30.7327 147.782 30.8413L147.514 31.5002C147.165 31.3037 146.815 31.2055 146.47 31.2055C146.224 31.2055 146.047 31.2443 145.928 31.3222C145.813 31.402 145.755 31.5042 145.755 31.6333C145.755 31.7622 145.821 31.8583 145.956 31.9199C146.088 31.9833 146.293 32.0445 146.573 32.104C146.857 32.1734 147.091 32.241 147.276 32.3086C147.457 32.3762 147.617 32.4827 147.745 32.63C147.876 32.7774 147.942 32.9737 147.942 33.2235C147.942 33.4385 147.884 33.6347 147.761 33.8109C147.642 33.9868 147.461 34.1281 147.223 34.2324C146.98 34.3348 146.688 34.3858 146.347 34.3858C146.047 34.3858 145.759 34.347 145.484 34.2672Z"
          fill="#002366"
        />
        <path
          d="M108.752 25.6797H107.548V24.9738H110.828V25.6797H109.624V28.723H108.752V25.6797Z"
          fill="#002366"
        />
        <path
          d="M113.534 25.8496C113.394 25.7371 113.197 25.6798 112.934 25.6798H112.227V26.9876H112.934C113.197 26.9876 113.394 26.9303 113.534 26.8157C113.67 26.7011 113.735 26.5413 113.735 26.3347C113.735 26.1238 113.67 25.9621 113.534 25.8496ZM113.752 28.7231L113.029 27.6793H112.983H112.227V28.7231H111.355V24.9736H112.983C113.316 24.9736 113.608 25.029 113.851 25.1394C114.097 25.2498 114.286 25.4075 114.422 25.6101C114.554 25.8147 114.619 26.0564 114.619 26.3347C114.619 26.6129 114.554 26.8524 114.418 27.055C114.282 27.2557 114.093 27.4112 113.842 27.5177L114.689 28.7231H113.752Z"
          fill="#002366"
        />
        <path
          d="M115.683 28.3106C115.383 28.0138 115.235 27.5923 115.235 27.0416V24.942H116.106V27.0089C116.106 27.6802 116.386 28.0159 116.945 28.0159C117.216 28.0159 117.426 27.9361 117.57 27.7724C117.714 27.6108 117.784 27.3549 117.784 27.0089V24.942H118.643V27.0416C118.643 27.5923 118.495 28.0138 118.195 28.3106C117.894 28.6075 117.475 28.7549 116.941 28.7549C116.402 28.7549 115.983 28.6075 115.683 28.3106Z"
          fill="#002366"
        />
        <path
          d="M119.84 28.6678C119.56 28.5859 119.338 28.4816 119.17 28.3527L119.466 27.6999C119.626 27.8185 119.819 27.9125 120.041 27.9843C120.263 28.0559 120.485 28.0907 120.707 28.0907C120.954 28.0907 121.139 28.0538 121.254 27.9822C121.373 27.9085 121.435 27.8103 121.435 27.6896C121.435 27.5995 121.398 27.5258 121.328 27.4664C121.258 27.407 121.168 27.36 121.061 27.3251C120.95 27.2884 120.802 27.2496 120.617 27.2065C120.329 27.1389 120.095 27.0716 119.91 27.004C119.729 26.9363 119.573 26.8278 119.441 26.6765C119.31 26.527 119.244 26.3265 119.244 26.0767C119.244 25.8599 119.301 25.6613 119.421 25.4854C119.54 25.3092 119.717 25.1682 119.955 25.0636C120.193 24.9614 120.485 24.9102 120.831 24.9102C121.069 24.9102 121.307 24.9389 121.533 24.9941C121.764 25.0514 121.965 25.1333 122.138 25.2418L121.871 25.9008C121.521 25.7043 121.172 25.6061 120.827 25.6061C120.58 25.6061 120.403 25.6449 120.284 25.7228C120.169 25.8025 120.111 25.9048 120.111 26.0339C120.111 26.1628 120.177 26.2589 120.313 26.3204C120.444 26.3838 120.65 26.4451 120.929 26.5045C121.213 26.574 121.447 26.6416 121.632 26.7092C121.813 26.7768 121.973 26.8832 122.101 27.0306C122.232 27.1778 122.298 27.3742 122.298 27.6241C122.298 27.839 122.241 28.0353 122.117 28.2114C121.998 28.3873 121.817 28.5286 121.579 28.633C121.336 28.7354 121.044 28.7864 120.703 28.7864C120.403 28.7864 120.115 28.7476 119.84 28.6678Z"
          fill="#002366"
        />
        <path
          d="M123.861 25.6797H122.657V24.9738H125.937V25.6797H124.733V28.723H123.861V25.6797Z"
          fill="#002366"
        />
        <path
          d="M129.379 28.0272V28.7231H126.464V24.9736H129.309V25.6695H127.327V26.484H129.079V27.1594H127.327V28.0272H129.379Z"
          fill="#002366"
        />
        <path
          d="M131.551 28.0109C131.929 28.0109 132.229 27.9065 132.451 27.6976C132.677 27.489 132.788 27.2045 132.788 26.8483C132.788 26.4923 132.677 26.2078 132.451 25.9989C132.229 25.7903 131.929 25.686 131.551 25.686H130.758V28.0109H131.551ZM129.886 24.9737H131.596C132.003 24.9737 132.365 25.0516 132.677 25.2069C132.994 25.3624 133.236 25.5816 133.409 25.8619C133.586 26.1444 133.672 26.4738 133.672 26.8483C133.672 27.223 133.586 27.5524 133.409 27.835C133.236 28.1152 132.994 28.3341 132.677 28.4897C132.365 28.6452 132.003 28.7231 131.596 28.7231H129.886V24.9737Z"
          fill="#002366"
        />
        <path
          d="M138.173 27.9576C138.288 27.8818 138.346 27.7653 138.346 27.6098C138.346 27.2987 138.115 27.1431 137.655 27.1431H136.635V28.0701H137.655C137.881 28.0701 138.058 28.0313 138.173 27.9576ZM136.635 25.6266V26.5106H137.503C137.717 26.5106 137.881 26.4739 137.992 26.3981C138.103 26.3223 138.161 26.2119 138.161 26.0666C138.161 25.9192 138.103 25.8107 137.992 25.737C137.881 25.6633 137.717 25.6266 137.503 25.6266H136.635ZM139.04 27.1143C139.164 27.272 139.225 27.4685 139.225 27.6998C139.225 28.0292 139.098 28.2809 138.839 28.457C138.584 28.635 138.21 28.723 137.717 28.723H135.772V24.9738H137.61C138.07 24.9738 138.424 25.0617 138.666 25.2355C138.913 25.4116 139.036 25.6491 139.036 25.9478C139.036 26.13 138.991 26.2938 138.905 26.435C138.814 26.5782 138.695 26.6907 138.535 26.7726C138.752 26.8423 138.921 26.9548 139.04 27.1143Z"
          fill="#002366"
        />
        <path
          d="M141.889 27.3948V28.723H141.017V27.3845L139.562 24.9738H140.487L141.49 26.6397L142.497 24.9738H143.352L141.889 27.3948Z"
          fill="#002366"
        />
        <path
          d="M110.257 32.3884H111.05V33.9091C110.849 34.0647 110.611 34.1812 110.343 34.2631C110.072 34.3449 109.801 34.3859 109.529 34.3859C109.139 34.3859 108.785 34.304 108.473 34.1384C108.165 33.9725 107.918 33.7412 107.741 33.4464C107.564 33.1517 107.474 32.8181 107.474 32.4479C107.474 32.0773 107.564 31.7438 107.741 31.449C107.918 31.1543 108.165 30.9232 108.481 30.7573C108.798 30.5915 109.151 30.5096 109.546 30.5096C109.875 30.5096 110.175 30.5648 110.442 30.6755C110.713 30.7859 110.939 30.9456 111.12 31.1564L110.561 31.6701C110.294 31.3896 109.969 31.2486 109.587 31.2486C109.349 31.2486 109.135 31.2977 108.95 31.3978C108.761 31.4981 108.617 31.6394 108.514 31.8214C108.407 32.0036 108.358 32.2125 108.358 32.4479C108.358 32.679 108.407 32.8879 108.514 33.0701C108.617 33.2521 108.761 33.3933 108.946 33.4956C109.131 33.5959 109.34 33.6472 109.579 33.6472C109.829 33.6472 110.056 33.5938 110.257 33.4874V32.3884Z"
          fill="#002366"
        />
        <path
          d="M114.571 33.6266V34.3225H111.657V30.5731H114.501V31.269H112.52V32.0835H114.271V32.7588H112.52V33.6266H114.571Z"
          fill="#002366"
        />
        <path
          d="M118.627 30.5731V34.3225H117.912L116.033 32.0465V34.3225H115.174V30.5731H115.893L117.764 32.8489V30.5731H118.627Z"
          fill="#002366"
        />
        <path
          d="M122.144 33.6266V34.3225H119.23V30.5731H122.074V31.269H120.093V32.0835H121.844V32.7588H120.093V33.6266H122.144Z"
          fill="#002366"
        />
        <path
          d="M124.925 31.449C124.786 31.3365 124.588 31.2792 124.325 31.2792H123.618V32.587H124.325C124.588 32.587 124.786 32.5297 124.925 32.4151C125.061 32.3005 125.127 32.1407 125.127 31.9342C125.127 31.7232 125.061 31.5615 124.925 31.449ZM125.143 34.3225L124.42 33.2787H124.375H123.618V34.3225H122.747V30.573H124.375C124.708 30.573 124.999 30.6284 125.242 30.7389C125.489 30.8493 125.678 31.0069 125.813 31.2095C125.945 31.4142 126.011 31.6558 126.011 31.9342C126.011 32.2123 125.945 32.4518 125.809 32.6544C125.674 32.8551 125.485 33.0107 125.234 33.1171L126.081 34.3225H125.143Z"
          fill="#002366"
        />
        <path
          d="M129.482 32.8612L128.886 31.4245L128.289 32.8612H129.482ZM129.757 33.5183H128.01L127.673 34.3225H126.781L128.462 30.573H129.321L131.003 34.3225H130.09L129.757 33.5183Z"
          fill="#002366"
        />
        <path
          d="M131.883 31.2792H130.679V30.5732H133.959V31.2792H132.755V34.3224H131.883V31.2792Z"
          fill="#002366"
        />
      </g>
      <defs>
        <clipPath id="clip0_616_1368">
          <rect width="148" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg> -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="114"
      height="32"
      viewBox="0 0 114 32"
      fill="none"
    >
      <path
        d="M59.212 5.39427H61.7216L64.9769 10.8243H65.0037V5.39427H67.299V14.2046H64.7938L61.5341 8.71657H61.5117V14.2046H59.212V5.39427Z"
        fill="#002366"
      />
      <path
        d="M74.1579 14.2046H71.3491L68.3974 5.39427H70.9427L72.7825 11.9496H72.8093L74.6446 5.39427H77.1944L74.1579 14.2046Z"
        fill="#002366"
      />
      <path
        d="M78.2884 5.39427H85.7592V7.23403H80.7311V8.77462H85.326V10.5519H80.7311V12.2309H85.8931V14.2046H78.2884V5.39427Z"
        fill="#002366"
      />
      <path
        d="M87.2194 5.39427H91.9974C93.6362 5.39427 95.2795 6.09981 95.2795 7.8726C95.2795 8.82374 94.8062 9.7213 93.8104 10.083V10.1053C94.7972 10.3375 95.1098 11.378 95.1768 12.2175C95.217 12.6015 95.2438 13.8608 95.5698 14.2046H93.154C92.9441 13.8965 92.9173 13.057 92.8771 12.798C92.8101 11.9094 92.6672 10.9984 91.5107 10.9984H89.662V14.2046H87.2194V5.39427ZM89.662 9.22117H91.7116C92.4484 9.22117 92.8414 8.86393 92.8414 8.18518C92.8414 7.53322 92.2921 7.23403 91.6089 7.23403H89.6664V9.22117H89.662Z"
        fill="#002366"
      />
      <path
        d="M98.8295 11.244C98.8831 12.28 99.553 12.6149 100.602 12.6149C101.339 12.6149 102.098 12.3693 102.098 11.7129C102.098 10.9225 100.759 10.7886 99.4056 10.4313C98.0794 10.0741 96.6638 9.52036 96.6638 7.89047C96.6638 5.96586 98.7268 5.21119 100.482 5.21119C102.335 5.21119 104.237 6.07303 104.237 8.05123H101.795C101.835 7.26084 101.022 6.98845 100.285 6.98845C99.7584 6.98845 99.1064 7.1626 99.1064 7.74311C99.1064 8.43526 100.459 8.54689 101.821 8.90413C103.174 9.26137 104.541 9.85527 104.541 11.445C104.541 13.6777 102.518 14.3922 100.459 14.3922C98.3205 14.3922 96.3914 13.5036 96.3914 11.244H98.8295Z"
        fill="#002366"
      />
      <path
        d="M105.733 5.39427H113.199V7.23403H108.171V8.77462H112.766V10.5519H108.171V12.2309H113.333V14.2046H105.733V5.39427Z"
        fill="#002366"
      />
      <path
        d="M53.523 5.21119C50.9866 5.21119 48.9325 7.26531 48.9325 9.80169C48.9325 12.3381 50.9866 14.3922 53.523 14.3922C56.0594 14.3922 58.1135 12.3381 58.1135 9.80169C58.1135 7.26531 56.0594 5.21119 53.523 5.21119ZM53.523 11.9808C52.3173 11.9808 51.3439 11.0074 51.3439 9.80169C51.3439 8.59601 52.3173 7.62255 53.523 7.62255C54.7287 7.62255 55.7022 8.60048 55.7022 9.80169C55.7022 11.0074 54.7287 11.9808 53.523 11.9808Z"
        fill="#002366"
      />
      <path
        d="M43.306 11.9808C42.1003 11.9808 41.1269 11.0074 41.1269 9.80169C41.1269 8.59601 42.1003 7.62255 43.306 7.62255C44.2393 7.62255 45.0386 8.21198 45.3467 9.04256H47.834C47.4723 6.86788 45.5834 5.21566 43.306 5.21566C40.7696 5.21566 38.7155 7.26978 38.7155 9.80616C38.7155 12.3425 40.7696 14.3966 43.306 14.3966C45.5834 14.3966 47.4723 12.7444 47.834 10.5697H45.3467C45.0431 11.3914 44.2437 11.9808 43.306 11.9808Z"
        fill="#002366"
      />
      <path
        d="M27.1143 4.66194C24.2385 1.78618 20.2732 0 15.8881 0C7.11348 0 0 7.11347 0 15.8881C0 20.2776 1.78618 24.243 4.66194 27.1143C7.53769 29.99 11.503 31.7762 15.8881 31.7762C20.2777 31.7762 24.243 29.99 27.1143 27.1143C29.99 24.2385 31.7762 20.2732 31.7762 15.8881C31.7762 11.503 29.99 7.53769 27.1143 4.66194ZM15.8881 9.10954C12.1639 9.10954 9.10954 12.1684 9.10954 15.8881C9.10954 19.6391 12.1639 22.6979 15.8881 22.6979C18.9737 22.6979 21.6084 20.6081 22.4255 17.7949H26.2971C25.4219 22.7292 21.0904 26.4802 15.8881 26.4802C10.0785 26.4802 5.32732 21.7289 5.32732 15.8881C5.32732 10.0785 10.0785 5.32729 15.8881 5.32729C21.0948 5.32729 25.4219 9.07828 26.2971 14.0126H22.4255C21.6039 11.1994 18.9737 9.10954 15.8881 9.10954ZM18.9112 15.8881C18.9112 17.5537 17.5805 18.9157 15.8836 18.9157C14.218 18.9157 12.8561 17.5537 12.8561 15.8881C12.8561 14.2225 14.218 12.8605 15.8836 12.8605C17.5805 12.865 18.9112 14.2269 18.9112 15.8881Z"
        fill="#002366"
      />
      <path
        d="M38.9032 17.7422H42.3103C42.5202 17.7422 42.739 17.7422 42.9668 17.7467C43.1945 17.7511 43.4222 17.7645 43.6455 17.7824C43.8688 17.8047 44.0831 17.836 44.2841 17.8761C44.485 17.9163 44.6547 17.9789 44.7976 18.0637C45.1057 18.2378 45.3647 18.479 45.579 18.7871C45.7934 19.0952 45.9006 19.4703 45.9006 19.9079C45.9006 20.3768 45.7755 20.7832 45.5299 21.127C45.2843 21.4708 44.9405 21.7209 44.5029 21.8861V21.9085C45.0834 22.0246 45.5255 22.2702 45.8336 22.6497C46.1417 23.0293 46.2935 23.4892 46.2935 24.034C46.2935 24.3555 46.231 24.6681 46.1104 24.9762C45.9854 25.2799 45.8068 25.5523 45.5657 25.7845C45.3245 26.0211 45.0253 26.2087 44.6726 26.3516C44.3198 26.4945 43.909 26.5704 43.4446 26.5704H38.9076V17.7422H38.9032ZM40.1535 21.5378H42.6542C43.3642 21.5378 43.8732 21.4217 44.1858 21.1851C44.4984 20.9484 44.6547 20.5956 44.6547 20.1267C44.6547 19.8231 44.6011 19.5775 44.4984 19.3899C44.3912 19.2024 44.2483 19.064 44.0653 18.9613C43.8822 18.863 43.6678 18.8005 43.4267 18.7692C43.1855 18.7424 42.931 18.7246 42.6586 18.7246H40.158V21.5378H40.1535ZM40.1535 25.5791H43.2838C43.8286 25.5791 44.2573 25.4362 44.5743 25.1504C44.8914 24.8646 45.0476 24.4806 45.0476 23.9938C45.0476 23.7036 44.9896 23.4669 44.878 23.2749C44.7619 23.0873 44.6145 22.9355 44.4225 22.8239C44.2305 22.7122 44.0161 22.6363 43.7705 22.5872C43.5249 22.5426 43.2704 22.5202 43.007 22.5202H40.1535V25.5791Z"
        fill="#FF6700"
      />
      <path
        d="M51.0403 17.7422H52.4201L56.0907 26.5704H54.7109L53.6839 23.9134H49.7096L48.6602 26.5704H47.3697L51.0403 17.7422ZM50.107 22.9221H53.2909L51.728 18.805H51.7012L50.107 22.9221Z"
        fill="#FF6700"
      />
      <path
        d="M57.2116 17.7422H58.5378L63.4855 24.9003H63.5123V17.7422H64.6957V26.5704H63.3248L58.4217 19.4837H58.3949V26.5704H57.2116V17.7422Z"
        fill="#FF6700"
      />
      <path
        d="M66.0085 17.7422H67.2589V22.1451L72.019 17.7422H73.6355L69.6791 21.3012L73.7963 26.5704H72.2155L68.8218 22.1049L67.2589 23.4758V26.5704H66.0085V17.7422Z"
        fill="#FF6700"
      />
    </svg>
  </div>
  <!-- <div class="lang">
    <select #lang (change)="changeLanguage(lang.value)">
      <option [selected]="selectedLang == 'en'" value="en">English</option>
      <option [selected]="selectedLang == 'am'" value="am">Հայերեն</option>
      <option [selected]="selectedLang == 'ru'" value="ru">Русский</option>

      <option [selected]="selectedLang == 'en'" value="en">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flag"
          viewBox="0 0 32 32"
        >
          <rect
            x="1"
            y="4"
            width="30"
            height="24"
            rx="4"
            ry="4"
            fill="#071b65"
          ></rect>
          <path
            d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
            fill="#fff"
          ></path>
          <path
            d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
            fill="#b92932"
          ></path>
          <path
            d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
            fill="#b92932"
          ></path>
          <path
            d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
            fill="#fff"
          ></path>
          <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
          <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
          <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
          <rect
            x="14"
            y="1"
            width="4"
            height="30"
            transform="translate(32) rotate(90)"
            fill="#b92932"
          ></rect>
          <path
            d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
            fill="#b92932"
          ></path>
          <path
            d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
            fill="#b92932"
          ></path>
          <path
            d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
            opacity=".15"
          ></path>
          <path
            d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
            fill="#fff"
            opacity=".2"
          ></path>
        </svg>
      </option>
      <option [selected]="selectedLang == 'am'" value="am">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flag"
          viewBox="0 0 32 32"
        >
          <path fill="#102f9b" d="M1 11H31V21H1z"></path>
          <path
            d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
            fill="#c82a20"
          ></path>
          <path
            d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
            transform="rotate(180 16 24)"
            fill="#e8ad3b"
          ></path>
          <path
            d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
            opacity=".15"
          ></path>
          <path
            d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
            fill="#fff"
            opacity=".2"
          ></path>
        </svg>
      </option>
      <option [selected]="selectedLang == 'ru'" value="ru">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="flag"
          viewBox="0 0 32 32"
        >
          <path fill="#1435a1" d="M1 11H31V21H1z"></path>
          <path
            d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
            fill="#fff"
          ></path>
          <path
            d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
            transform="rotate(180 16 24)"
            fill="#c53a28"
          ></path>
          <path
            d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
            opacity=".15"
          ></path>
          <path
            d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
            fill="#fff"
            opacity=".2"
          ></path>
        </svg>
      </option>
    </select>
  </div> -->

  <div class="lang" tabindex="1">
    <div class="current">
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M10.0003 1.6665C12.0847 3.94846 13.2693 6.90987 13.3337 9.99984C13.2693 13.0898 12.0847 16.0512 10.0003 18.3332M10.0003 1.6665C7.91593 3.94846 6.73137 6.90987 6.66699 9.99984C6.73137 13.0898 7.91593 16.0512 10.0003 18.3332M10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332M10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332M2.08367 7.49984H17.917M2.08366 12.4998H17.917"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg> -->
      <div class="title">
        <div>
          <ng-container *ngIf="langText == 'en'">
            <svg
              class="flag"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <rect
                x="1"
                y="4"
                width="30"
                height="24"
                rx="4"
                ry="4"
                fill="#071b65"
              ></rect>
              <path
                d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
                fill="#fff"
              ></path>
              <path
                d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
                fill="#b92932"
              ></path>
              <path
                d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
                fill="#b92932"
              ></path>
              <path
                d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
                fill="#fff"
              ></path>
              <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
              <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
              <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
              <rect
                x="14"
                y="1"
                width="4"
                height="30"
                transform="translate(32) rotate(90)"
                fill="#b92932"
              ></rect>
              <path
                d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
                fill="#b92932"
              ></path>
              <path
                d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
                fill="#b92932"
              ></path>
              <path
                d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                opacity=".15"
              ></path>
              <path
                d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                fill="#fff"
                opacity=".2"
              ></path>
            </svg>
          </ng-container>
          <ng-container *ngIf="langText == 'ru'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="flag"
              viewBox="0 0 32 32"
            >
              <path fill="#1435a1" d="M1 11H31V21H1z"></path>
              <path
                d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                fill="#fff"
              ></path>
              <path
                d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                transform="rotate(180 16 24)"
                fill="#c53a28"
              ></path>
              <path
                d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                opacity=".15"
              ></path>
              <path
                d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                fill="#fff"
                opacity=".2"
              ></path>
            </svg>
          </ng-container>
          <ng-container *ngIf="langText == 'am'">
            <svg
              class="flag"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path fill="#102f9b" d="M1 11H31V21H1z"></path>
              <path
                d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                fill="#c82a20"
              ></path>
              <path
                d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                transform="rotate(180 16 24)"
                fill="#e8ad3b"
              ></path>
              <path
                d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                opacity=".15"
              ></path>
              <path
                d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                fill="#fff"
                opacity=".2"
              ></path>
            </svg>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="nested n-lang">
      <ul>
        <li
          (click)="changeLang('en')"
          [ngClass]="{ active: langText == 'en' }"
          class="en"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flag"
            viewBox="0 0 32 32"
          >
            <rect
              x="1"
              y="4"
              width="30"
              height="24"
              rx="4"
              ry="4"
              fill="#071b65"
            ></rect>
            <path
              d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
              fill="#fff"
            ></path>
            <path
              d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
              fill="#b92932"
            ></path>
            <path
              d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
              fill="#b92932"
            ></path>
            <path
              d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
              fill="#fff"
            ></path>
            <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
            <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
            <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
            <rect
              x="14"
              y="1"
              width="4"
              height="30"
              transform="translate(32) rotate(90)"
              fill="#b92932"
            ></rect>
            <path
              d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
              fill="#b92932"
            ></path>
            <path
              d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
              fill="#b92932"
            ></path>
            <path
              d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
              opacity=".15"
            ></path>
            <path
              d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
              fill="#fff"
              opacity=".2"
            ></path>
          </svg>
        </li>
        <li (click)="changeLang('ru')" [ngClass]="{ active: langText == 'ru' }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flag"
            viewBox="0 0 32 32"
          >
            <path fill="#1435a1" d="M1 11H31V21H1z"></path>
            <path
              d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
              fill="#fff"
            ></path>
            <path
              d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
              transform="rotate(180 16 24)"
              fill="#c53a28"
            ></path>
            <path
              d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
              opacity=".15"
            ></path>
            <path
              d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
              fill="#fff"
              opacity=".2"
            ></path>
          </svg>
        </li>
        <li
          (click)="changeLang('am')"
          [ngClass]="{ active: langText == 'am' }"
          class="am"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flag"
            viewBox="0 0 32 32"
          >
            <path fill="#102f9b" d="M1 11H31V21H1z"></path>
            <path
              d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
              fill="#c82a20"
            ></path>
            <path
              d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
              transform="rotate(180 16 24)"
              fill="#e8ad3b"
            ></path>
            <path
              d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
              opacity=".15"
            ></path>
            <path
              d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
              fill="#fff"
              opacity=".2"
            ></path>
          </svg>
        </li>
      </ul>
    </div>
  </div>
</header>
