import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Qr } from '../models/qr';

@Injectable({
  providedIn: 'root'
})
export class QrGeneratorService {

constructor(private http: HttpClient) { }

  getQr(body: Qr, lang:string){
    const headers = new HttpHeaders().set('lang', lang)
    return this.http.post(`/api/Qr/GenerateQR`, body, {headers: headers, observe:"response"})
  }
  scanQr(qr: string)
  {
    let body = {
      qr: qr
    }
    return this.http.post(`/api/Qr/ScanQr`, body, {observe: "response"})
  }


  copiedLink(id: string)
  {
    return this.http.post(`/api/Payment/CopyLink?transactionId=${id}`, {observe: 'response'})
  }



}
