import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Domain } from '../models/domain.model';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(private http:HttpClient) { }

  getDomains(){
    return this.http.get(`/api/Domain/GetDomains`,{observe:"response"})
  }

  addDomain(domain:Domain){
    return this.http.post(`/api/Domain/AddDomain`,domain,{observe:"response"})
  }

  updateDomain(domain:Domain){
    return this.http.put(`/api/Domain/UpdateDomain`,domain,{observe:"response"})
  }

  deleteDomain(id:number){
    const params=new HttpParams()
    .set("id",id.toString());
    return this.http.delete(`/api/Domain/DeleteDomain`,{params,observe:"response"})
  }
}
