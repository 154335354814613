<div class="wrapper">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1440"
    height="223"
    viewBox="0 0 1440 223"
    fill="none"
  >
    <g clip-path="url(#clip0_16783_42489)">
      <g opacity="0.1">
        <path
          d="M2124.28 1345.24C2124.28 1356.29 2115.36 1365.39 2104.13 1365.39C2093.09 1365.39 2083.98 1356.29 2083.98 1345.24C2083.98 1334.2 2093.09 1325.1 2104.13 1325.1C2115.36 1325.1 2124.28 1334.2 2124.28 1345.24Z"
          fill="white"
        />
        <path
          d="M2148.1 1245.47H2174.07C2168.25 1278.41 2139.39 1303.4 2104.52 1303.4C2065.77 1303.4 2034 1271.63 2034 1232.69C2034 1193.94 2065.77 1162.17 2104.52 1162.17C2139.19 1162.17 2168.25 1187.16 2174.07 1220.09H2148.1C2142.68 1201.3 2125.05 1187.35 2104.52 1187.35C2079.72 1187.35 2059.18 1207.7 2059.18 1232.69C2059.18 1257.68 2079.52 1278.21 2104.52 1278.21C2125.05 1278.21 2142.68 1264.27 2148.1 1245.47Z"
          fill="white"
        />
        <path
          d="M2004.55 1232.69C2004.55 1243.73 1995.64 1252.83 1984.4 1252.83C1973.36 1252.83 1964.25 1243.73 1964.25 1232.69C1964.25 1221.64 1973.36 1212.54 1984.4 1212.54C1995.64 1212.54 2004.55 1221.64 2004.55 1232.69Z"
          fill="white"
        />
        <path
          d="M1908.85 1245.47H1934.81C1928.99 1278.41 1900.13 1303.4 1865.26 1303.4C1826.51 1303.4 1794.74 1271.63 1794.74 1232.69C1794.74 1193.94 1826.51 1162.17 1865.26 1162.17C1899.93 1162.17 1928.99 1187.16 1934.81 1220.09H1908.85C1903.42 1201.3 1885.79 1187.35 1865.26 1187.35C1840.46 1187.35 1819.92 1207.7 1819.92 1232.69C1819.92 1257.68 1840.26 1278.21 1865.26 1278.21C1885.79 1278.21 1903.42 1264.27 1908.85 1245.47Z"
          fill="white"
        />
        <path
          d="M2148.1 1245.47H2174.07C2168.25 1278.41 2139.39 1303.4 2104.52 1303.4C2065.77 1303.4 2034 1271.63 2034 1232.69C2034 1193.94 2065.77 1162.17 2104.52 1162.17C2139.19 1162.17 2168.25 1187.16 2174.07 1220.09H2148.1C2142.68 1201.3 2125.05 1187.35 2104.52 1187.35C2079.72 1187.35 2059.18 1207.7 2059.18 1232.69C2059.18 1257.68 2079.52 1278.21 2104.52 1278.21C2125.05 1278.21 2142.68 1264.27 2148.1 1245.47Z"
          fill="white"
        />
        <path
          d="M2004.55 1232.69C2004.55 1243.73 1995.64 1252.83 1984.4 1252.83C1973.36 1252.83 1964.25 1243.73 1964.25 1232.69C1964.25 1221.64 1973.36 1212.54 1984.4 1212.54C1995.64 1212.54 2004.55 1221.64 2004.55 1232.69Z"
          fill="white"
        />
        <path
          d="M1908.85 1245.47H1934.81C1928.99 1278.41 1900.13 1303.4 1865.26 1303.4C1826.51 1303.4 1794.74 1271.63 1794.74 1232.69C1794.74 1193.94 1826.51 1162.17 1865.26 1162.17C1899.93 1162.17 1928.99 1187.16 1934.81 1220.09H1908.85C1903.42 1201.3 1885.79 1187.35 1865.26 1187.35C1840.46 1187.35 1819.92 1207.7 1819.92 1232.69C1819.92 1257.68 1840.26 1278.21 1865.26 1278.21C1885.79 1278.21 1903.42 1264.27 1908.85 1245.47Z"
          fill="white"
        />
        <path
          d="M2124.28 1120.12C2124.28 1131.17 2115.36 1140.27 2104.13 1140.27C2093.09 1140.27 2083.98 1131.17 2083.98 1120.12C2083.98 1109.08 2093.09 1099.98 2104.13 1099.98C2115.36 1099.98 2124.28 1108.89 2124.28 1120.12Z"
          fill="white"
        />
        <path
          d="M2028.57 1132.91H2054.53C2048.72 1165.85 2019.85 1190.84 1984.98 1190.84C1946.24 1190.84 1914.46 1159.07 1914.46 1120.12C1914.46 1081.38 1946.24 1049.61 1984.98 1049.61C2019.66 1049.61 2048.72 1074.6 2054.53 1107.53H2028.57C2023.15 1088.74 2005.52 1074.79 1984.98 1074.79C1960.18 1074.79 1939.65 1095.13 1939.65 1120.12C1939.65 1145.12 1959.99 1165.65 1984.98 1165.65C2005.52 1165.65 2023.15 1151.7 2028.57 1132.91Z"
          fill="white"
        />
        <path
          d="M2148.1 1020.16H2174.07C2168.25 1053.1 2139.39 1078.09 2104.52 1078.09C2065.77 1078.09 2034 1046.31 2034 1007.37C2034 968.628 2065.77 936.856 2104.52 936.856C2139.19 936.856 2168.25 961.847 2174.07 994.782H2148.1C2142.68 975.99 2125.05 962.041 2104.52 962.041C2079.72 962.041 2059.18 982.383 2059.18 1007.37C2059.18 1032.37 2079.52 1052.9 2104.52 1052.9C2125.05 1052.9 2142.68 1038.95 2148.1 1020.16Z"
          fill="white"
        />
        <path
          d="M2004.55 1007.57C2004.55 1018.61 1995.64 1027.71 1984.4 1027.71C1973.36 1027.71 1964.25 1018.61 1964.25 1007.57C1964.25 996.524 1973.36 987.418 1984.4 987.418C1995.64 987.225 2004.55 996.33 2004.55 1007.57Z"
          fill="white"
        />
        <path
          d="M1908.85 1020.16H1934.81C1928.99 1053.1 1900.13 1078.09 1865.26 1078.09C1826.51 1078.09 1794.74 1046.31 1794.74 1007.37C1794.74 968.628 1826.51 936.856 1865.26 936.856C1899.93 936.856 1928.99 961.847 1934.81 994.782H1908.85C1903.42 975.99 1885.79 962.041 1865.26 962.041C1840.46 962.041 1819.92 982.383 1819.92 1007.37C1819.92 1032.37 1840.26 1052.9 1865.26 1052.9C1885.79 1052.9 1903.42 1038.95 1908.85 1020.16Z"
          fill="white"
        />
        <path
          d="M2148.1 1020.16H2174.07C2168.25 1053.1 2139.39 1078.09 2104.52 1078.09C2065.77 1078.09 2034 1046.31 2034 1007.37C2034 968.628 2065.77 936.856 2104.52 936.856C2139.19 936.856 2168.25 961.847 2174.07 994.782H2148.1C2142.68 975.99 2125.05 962.041 2104.52 962.041C2079.72 962.041 2059.18 982.383 2059.18 1007.37C2059.18 1032.37 2079.52 1052.9 2104.52 1052.9C2125.05 1052.9 2142.68 1038.95 2148.1 1020.16Z"
          fill="white"
        />
        <path
          d="M2004.55 1007.57C2004.55 1018.61 1995.64 1027.71 1984.4 1027.71C1973.36 1027.71 1964.25 1018.61 1964.25 1007.57C1964.25 996.524 1973.36 987.418 1984.4 987.418C1995.64 987.225 2004.55 996.33 2004.55 1007.57Z"
          fill="white"
        />
        <path
          d="M1908.85 1020.16H1934.81C1928.99 1053.1 1900.13 1078.09 1865.26 1078.09C1826.51 1078.09 1794.74 1046.31 1794.74 1007.37C1794.74 968.628 1826.51 936.856 1865.26 936.856C1899.93 936.856 1928.99 961.847 1934.81 994.782H1908.85C1903.42 975.99 1885.79 962.041 1865.26 962.041C1840.46 962.041 1819.92 982.383 1819.92 1007.37C1819.92 1032.37 1840.26 1052.9 1865.26 1052.9C1885.79 1052.9 1903.42 1038.95 1908.85 1020.16Z"
          fill="white"
        />
        <path
          d="M2124.28 894.813C2124.28 905.856 2115.36 914.961 2104.13 914.961C2093.09 914.961 2083.98 905.856 2083.98 894.813C2083.98 883.77 2093.09 874.665 2104.13 874.665C2115.36 874.665 2124.28 883.77 2124.28 894.813Z"
          fill="white"
        />
        <path
          d="M2028.57 907.599H2054.53C2048.72 940.534 2019.85 965.525 1984.98 965.525C1946.24 965.525 1914.46 933.753 1914.46 894.813C1914.46 856.066 1946.24 824.294 1984.98 824.294C2019.66 824.294 2048.72 849.286 2054.53 882.22H2028.57C2023.15 863.428 2005.52 849.479 1984.98 849.479C1960.18 849.479 1939.65 869.821 1939.65 894.813C1939.65 919.804 1959.99 940.34 1984.98 940.34C2005.52 940.34 2023.15 926.391 2028.57 907.599Z"
          fill="white"
        />
        <path
          d="M2148.1 795.041H2174.07C2168.25 827.976 2139.39 852.967 2104.52 852.967C2065.77 852.967 2034 821.195 2034 782.255C2034 743.509 2065.77 711.736 2104.52 711.736C2139.19 711.736 2168.25 736.728 2174.07 769.662H2148.1C2142.68 750.87 2125.05 736.922 2104.52 736.922C2079.72 736.922 2059.18 757.264 2059.18 782.255C2059.18 807.247 2079.52 827.782 2104.52 827.782C2125.05 827.782 2142.68 813.833 2148.1 795.041Z"
          fill="white"
        />
        <path
          d="M2004.55 782.255C2004.55 793.298 1995.64 802.403 1984.4 802.403C1973.36 802.403 1964.25 793.298 1964.25 782.255C1964.25 771.212 1973.36 762.107 1984.4 762.107C1995.64 762.107 2004.55 771.019 2004.55 782.255Z"
          fill="white"
        />
        <path
          d="M1908.85 795.041H1934.81C1928.99 827.976 1900.13 852.967 1865.26 852.967C1826.51 852.967 1794.74 821.195 1794.74 782.255C1794.74 743.509 1826.51 711.736 1865.26 711.736C1899.93 711.736 1928.99 736.728 1934.81 769.662H1908.85C1903.42 750.87 1885.79 736.922 1865.26 736.922C1840.46 736.922 1819.92 757.264 1819.92 782.255C1819.92 807.247 1840.26 827.782 1865.26 827.782C1885.79 827.782 1903.42 813.833 1908.85 795.041Z"
          fill="white"
        />
        <path
          d="M2148.1 795.045H2174.07C2168.25 827.98 2139.39 852.971 2104.52 852.971C2065.77 852.971 2034 821.199 2034 782.259C2034 743.512 2065.77 711.74 2104.52 711.74C2139.19 711.74 2168.25 736.732 2174.07 769.666H2148.1C2142.68 750.874 2125.05 736.925 2104.52 736.925C2079.72 736.925 2059.18 757.267 2059.18 782.259C2059.18 807.25 2079.52 827.786 2104.52 827.786C2125.05 827.786 2142.68 813.837 2148.1 795.045Z"
          fill="white"
        />
        <path
          d="M2004.55 782.259C2004.55 793.302 1995.64 802.407 1984.4 802.407C1973.36 802.407 1964.25 793.302 1964.25 782.259C1964.25 771.216 1973.36 762.111 1984.4 762.111C1995.64 762.111 2004.55 771.022 2004.55 782.259Z"
          fill="white"
        />
        <path
          d="M1908.85 795.045H1934.81C1928.99 827.98 1900.13 852.971 1865.26 852.971C1826.51 852.971 1794.74 821.199 1794.74 782.259C1794.74 743.512 1826.51 711.74 1865.26 711.74C1899.93 711.74 1928.99 736.732 1934.81 769.666H1908.85C1903.42 750.874 1885.79 736.925 1865.26 736.925C1840.46 736.925 1819.92 757.267 1819.92 782.259C1819.92 807.25 1840.26 827.786 1865.26 827.786C1885.79 827.786 1903.42 813.837 1908.85 795.045Z"
          fill="white"
        />
        <path
          d="M2124.28 669.7C2124.28 680.743 2115.36 689.848 2104.13 689.848C2093.09 689.848 2083.98 680.743 2083.98 669.7C2083.98 658.657 2093.09 649.552 2104.13 649.552C2115.36 649.358 2124.28 658.464 2124.28 669.7Z"
          fill="white"
        />
        <path
          d="M2028.57 682.291H2054.53C2048.72 715.225 2019.85 740.217 1984.98 740.217C1946.24 740.217 1914.46 708.444 1914.46 669.504C1914.46 630.758 1946.24 598.986 1984.98 598.986C2019.66 598.986 2048.72 623.977 2054.53 656.912H2028.57C2023.15 638.12 2005.52 624.171 1984.98 624.171C1960.18 624.171 1939.65 644.513 1939.65 669.504C1939.65 694.496 1959.99 715.031 1984.98 715.031C2005.52 715.031 2023.15 701.083 2028.57 682.291Z"
          fill="white"
        />
        <path
          d="M2148.1 569.733H2174.07C2168.25 602.667 2139.39 627.659 2104.52 627.659C2065.77 627.659 2034 595.887 2034 556.946C2034 518.2 2065.77 486.428 2104.52 486.428C2139.19 486.428 2168.25 511.419 2174.07 544.354H2148.1C2142.68 525.562 2125.05 511.613 2104.52 511.613C2079.72 511.613 2059.18 531.955 2059.18 556.946C2059.18 581.938 2079.52 602.474 2104.52 602.474C2125.05 602.474 2142.68 588.525 2148.1 569.733Z"
          fill="white"
        />
        <path
          d="M2004.55 556.946C2004.55 567.989 1995.64 577.095 1984.4 577.095C1973.36 577.095 1964.25 567.989 1964.25 556.946C1964.25 545.904 1973.36 536.798 1984.4 536.798C1995.64 536.798 2004.55 545.904 2004.55 556.946Z"
          fill="white"
        />
        <path
          d="M1908.85 569.733H1934.81C1928.99 602.667 1900.13 627.659 1865.26 627.659C1826.51 627.659 1794.74 595.887 1794.74 556.946C1794.74 518.2 1826.51 486.428 1865.26 486.428C1899.93 486.428 1928.99 511.419 1934.81 544.354H1908.85C1903.42 525.562 1885.79 511.613 1865.26 511.613C1840.46 511.613 1819.92 531.955 1819.92 556.946C1819.92 581.938 1840.26 602.474 1865.26 602.474C1885.79 602.474 1903.42 588.525 1908.85 569.733Z"
          fill="white"
        />
        <path
          d="M2148.1 569.733H2174.07C2168.25 602.667 2139.39 627.659 2104.52 627.659C2065.77 627.659 2034 595.887 2034 556.946C2034 518.2 2065.77 486.428 2104.52 486.428C2139.19 486.428 2168.25 511.419 2174.07 544.354H2148.1C2142.68 525.562 2125.05 511.613 2104.52 511.613C2079.72 511.613 2059.18 531.955 2059.18 556.946C2059.18 581.938 2079.52 602.474 2104.52 602.474C2125.05 602.474 2142.68 588.525 2148.1 569.733Z"
          fill="white"
        />
        <path
          d="M2004.55 556.946C2004.55 567.989 1995.64 577.095 1984.4 577.095C1973.36 577.095 1964.25 567.989 1964.25 556.946C1964.25 545.904 1973.36 536.798 1984.4 536.798C1995.64 536.798 2004.55 545.904 2004.55 556.946Z"
          fill="white"
        />
        <path
          d="M1908.85 569.733H1934.81C1928.99 602.667 1900.13 627.659 1865.26 627.659C1826.51 627.659 1794.74 595.887 1794.74 556.946C1794.74 518.2 1826.51 486.428 1865.26 486.428C1899.93 486.428 1928.99 511.419 1934.81 544.354H1908.85C1903.42 525.562 1885.79 511.613 1865.26 511.613C1840.46 511.613 1819.92 531.955 1819.92 556.946C1819.92 581.938 1840.26 602.474 1865.26 602.474C1885.79 602.474 1903.42 588.525 1908.85 569.733Z"
          fill="white"
        />
        <path
          d="M2124.28 444.385C2124.28 455.428 2115.36 464.533 2104.13 464.533C2093.09 464.533 2083.98 455.428 2083.98 444.385C2083.98 433.342 2093.09 424.237 2104.13 424.237C2115.36 424.237 2124.28 433.342 2124.28 444.385Z"
          fill="white"
        />
        <path
          d="M2028.57 457.171H2054.53C2048.72 490.106 2019.85 515.097 1984.98 515.097C1946.24 515.097 1914.46 483.325 1914.46 444.385C1914.46 405.638 1946.24 373.866 1984.98 373.866C2019.66 373.866 2048.72 398.858 2054.53 431.792H2028.57C2023.15 413 2005.52 399.052 1984.98 399.052C1960.18 399.052 1939.65 419.393 1939.65 444.385C1939.65 469.376 1959.99 489.912 1984.98 489.912C2005.52 489.912 2023.15 475.963 2028.57 457.171Z"
          fill="white"
        />
        <path
          d="M2148.1 344.421H2174.07C2168.25 377.355 2139.39 402.347 2104.52 402.347C2065.77 402.347 2034 370.574 2034 331.634C2034 292.888 2065.77 261.116 2104.52 261.116C2139.19 261.116 2168.25 286.107 2174.07 319.042H2148.1C2142.68 300.25 2125.05 286.301 2104.52 286.301C2079.72 286.301 2059.18 306.643 2059.18 331.634C2059.18 356.626 2079.52 377.161 2104.52 377.161C2125.05 377.161 2142.68 363.213 2148.1 344.421Z"
          fill="white"
        />
        <path
          d="M2004.55 331.826C2004.55 342.869 1995.64 351.975 1984.4 351.975C1973.36 351.975 1964.25 342.869 1964.25 331.826C1964.25 320.784 1973.36 311.678 1984.4 311.678C1995.64 311.484 2004.55 320.59 2004.55 331.826Z"
          fill="white"
        />
        <path
          d="M1908.85 344.421H1934.81C1928.99 377.355 1900.13 402.347 1865.26 402.347C1826.51 402.347 1794.74 370.574 1794.74 331.634C1794.74 292.888 1826.51 261.116 1865.26 261.116C1899.93 261.116 1928.99 286.107 1934.81 319.042H1908.85C1903.42 300.25 1885.79 286.301 1865.26 286.301C1840.46 286.301 1819.92 306.643 1819.92 331.634C1819.92 356.626 1840.26 377.161 1865.26 377.161C1885.79 377.161 1903.42 363.213 1908.85 344.421Z"
          fill="white"
        />
        <path
          d="M2148.1 344.421H2174.07C2168.25 377.355 2139.39 402.347 2104.52 402.347C2065.77 402.347 2034 370.574 2034 331.634C2034 292.888 2065.77 261.116 2104.52 261.116C2139.19 261.116 2168.25 286.107 2174.07 319.042H2148.1C2142.68 300.25 2125.05 286.301 2104.52 286.301C2079.72 286.301 2059.18 306.643 2059.18 331.634C2059.18 356.626 2079.52 377.161 2104.52 377.161C2125.05 377.161 2142.68 363.213 2148.1 344.421Z"
          fill="white"
        />
        <path
          d="M2004.55 331.83C2004.55 342.873 1995.64 351.978 1984.4 351.978C1973.36 351.978 1964.25 342.873 1964.25 331.83C1964.25 320.787 1973.36 311.682 1984.4 311.682C1995.64 311.488 2004.55 320.594 2004.55 331.83Z"
          fill="white"
        />
        <path
          d="M1908.85 344.421H1934.81C1928.99 377.355 1900.13 402.347 1865.26 402.347C1826.51 402.347 1794.74 370.574 1794.74 331.634C1794.74 292.888 1826.51 261.116 1865.26 261.116C1899.93 261.116 1928.99 286.107 1934.81 319.042H1908.85C1903.42 300.25 1885.79 286.301 1865.26 286.301C1840.46 286.301 1819.92 306.643 1819.92 331.634C1819.92 356.626 1840.26 377.161 1865.26 377.161C1885.79 377.161 1903.42 363.213 1908.85 344.421Z"
          fill="white"
        />
        <path
          d="M2124.28 219.076C2124.28 230.119 2115.36 239.225 2104.13 239.225C2093.09 239.225 2083.98 230.119 2083.98 219.076C2083.98 208.034 2093.09 198.928 2104.13 198.928C2115.36 198.928 2124.28 208.034 2124.28 219.076Z"
          fill="white"
        />
        <path
          d="M2028.57 231.863H2054.53C2048.72 264.797 2019.85 289.789 1984.98 289.789C1946.24 289.789 1914.46 258.017 1914.46 219.076C1914.46 180.33 1946.24 148.558 1984.98 148.558C2019.66 148.558 2048.72 173.549 2054.53 206.484H2028.57C2023.15 187.692 2005.52 173.743 1984.98 173.743C1960.18 173.743 1939.65 194.085 1939.65 219.076C1939.65 244.068 1959.99 264.603 1984.98 264.603C2005.52 264.603 2023.15 250.655 2028.57 231.863Z"
          fill="white"
        />
        <path
          d="M2148.1 119.305H2174.07C2168.25 152.239 2139.39 177.231 2104.52 177.231C2065.77 177.231 2034 145.459 2034 106.519C2034 67.7721 2065.77 36 2104.52 36C2139.19 36 2168.25 60.9915 2174.07 93.926H2148.1C2142.68 75.1339 2125.05 61.1852 2104.52 61.1852C2079.72 61.1852 2059.18 81.5271 2059.18 106.519C2059.18 131.51 2079.52 152.046 2104.52 152.046C2125.05 152.046 2142.68 138.097 2148.1 119.305Z"
          fill="white"
        />
        <path
          d="M2004.55 106.519C2004.55 117.561 1995.64 126.667 1984.4 126.667C1973.36 126.667 1964.25 117.561 1964.25 106.519C1964.25 95.4759 1973.36 86.3704 1984.4 86.3704C1995.64 86.3704 2004.55 95.4759 2004.55 106.519Z"
          fill="white"
        />
        <path
          d="M1908.85 119.305H1934.81C1928.99 152.239 1900.13 177.231 1865.26 177.231C1826.51 177.231 1794.74 145.459 1794.74 106.519C1794.74 67.7721 1826.51 36 1865.26 36C1899.93 36 1928.99 60.9915 1934.81 93.926H1908.85C1903.42 75.1339 1885.79 61.1852 1865.26 61.1852C1840.46 61.1852 1819.92 81.5271 1819.92 106.519C1819.92 131.51 1840.26 152.046 1865.26 152.046C1885.79 152.046 1903.42 138.097 1908.85 119.305Z"
          fill="white"
        />
        <path
          d="M1884.82 1345.24C1884.82 1356.29 1875.91 1365.39 1864.67 1365.39C1853.63 1365.39 1844.53 1356.29 1844.53 1345.24C1844.53 1334.2 1853.63 1325.1 1864.67 1325.1C1875.91 1325.1 1884.82 1334.2 1884.82 1345.24Z"
          fill="white"
        />
        <path
          d="M1908.85 1245.47H1934.81C1928.99 1278.41 1900.13 1303.4 1865.26 1303.4C1826.51 1303.4 1794.74 1271.63 1794.74 1232.69C1794.74 1193.94 1826.51 1162.17 1865.26 1162.17C1899.93 1162.17 1928.99 1187.16 1934.81 1220.09H1908.85C1903.42 1201.3 1885.79 1187.35 1865.26 1187.35C1840.46 1187.35 1819.92 1207.7 1819.92 1232.69C1819.92 1257.68 1840.26 1278.21 1865.26 1278.21C1885.79 1278.21 1903.42 1264.27 1908.85 1245.47Z"
          fill="white"
        />
        <path
          d="M1765.29 1232.69C1765.29 1243.73 1756.38 1252.83 1745.14 1252.83C1734.1 1252.83 1724.99 1243.73 1724.99 1232.69C1724.99 1221.64 1734.1 1212.54 1745.14 1212.54C1756.38 1212.54 1765.29 1221.64 1765.29 1232.69Z"
          fill="white"
        />
        <path
          d="M1669.59 1245.47H1695.55C1689.73 1278.41 1660.87 1303.4 1626 1303.4C1587.25 1303.4 1555.48 1271.63 1555.48 1232.69C1555.48 1193.94 1587.25 1162.17 1626 1162.17C1660.67 1162.17 1689.73 1187.16 1695.55 1220.09H1669.59C1664.16 1201.3 1646.53 1187.35 1626 1187.35C1601.2 1187.35 1580.66 1207.7 1580.66 1232.69C1580.66 1257.68 1601 1278.21 1626 1278.21C1646.53 1278.21 1664.16 1264.27 1669.59 1245.47Z"
          fill="white"
        />
        <path
          d="M1908.85 1245.47H1934.81C1928.99 1278.41 1900.13 1303.4 1865.26 1303.4C1826.51 1303.4 1794.74 1271.63 1794.74 1232.69C1794.74 1193.94 1826.51 1162.17 1865.26 1162.17C1899.93 1162.17 1928.99 1187.16 1934.81 1220.09H1908.85C1903.42 1201.3 1885.79 1187.35 1865.26 1187.35C1840.46 1187.35 1819.92 1207.7 1819.92 1232.69C1819.92 1257.68 1840.26 1278.21 1865.26 1278.21C1885.79 1278.21 1903.42 1264.27 1908.85 1245.47Z"
          fill="white"
        />
        <path
          d="M1765.29 1232.69C1765.29 1243.73 1756.38 1252.83 1745.14 1252.83C1734.1 1252.83 1724.99 1243.73 1724.99 1232.69C1724.99 1221.64 1734.1 1212.54 1745.14 1212.54C1756.38 1212.54 1765.29 1221.64 1765.29 1232.69Z"
          fill="white"
        />
        <path
          d="M1669.59 1245.47H1695.55C1689.73 1278.41 1660.87 1303.4 1626 1303.4C1587.25 1303.4 1555.48 1271.63 1555.48 1232.69C1555.48 1193.94 1587.25 1162.17 1626 1162.17C1660.67 1162.17 1689.73 1187.16 1695.55 1220.09H1669.59C1664.16 1201.3 1646.53 1187.35 1626 1187.35C1601.2 1187.35 1580.66 1207.7 1580.66 1232.69C1580.66 1257.68 1601 1278.21 1626 1278.21C1646.53 1278.21 1664.16 1264.27 1669.59 1245.47Z"
          fill="white"
        />
        <path
          d="M1884.82 1120.12C1884.82 1131.17 1875.91 1140.27 1864.67 1140.27C1853.63 1140.27 1844.53 1131.17 1844.53 1120.12C1844.53 1109.08 1853.63 1099.98 1864.67 1099.98C1875.91 1099.98 1884.82 1108.89 1884.82 1120.12Z"
          fill="white"
        />
        <path
          d="M1789.12 1132.91H1815.08C1809.27 1165.85 1780.4 1190.84 1745.53 1190.84C1706.78 1190.84 1675.01 1159.07 1675.01 1120.12C1675.01 1081.38 1706.78 1049.61 1745.53 1049.61C1780.21 1049.61 1809.27 1074.6 1815.08 1107.53H1789.12C1783.69 1088.74 1766.07 1074.79 1745.53 1074.79C1720.73 1074.79 1700.2 1095.13 1700.2 1120.12C1700.2 1145.12 1720.54 1165.65 1745.53 1165.65C1766.26 1165.65 1783.69 1151.7 1789.12 1132.91Z"
          fill="white"
        />
        <path
          d="M1908.85 1020.16H1934.81C1928.99 1053.1 1900.13 1078.09 1865.26 1078.09C1826.51 1078.09 1794.74 1046.31 1794.74 1007.37C1794.74 968.628 1826.51 936.856 1865.26 936.856C1899.93 936.856 1928.99 961.847 1934.81 994.782H1908.85C1903.42 975.99 1885.79 962.041 1865.26 962.041C1840.46 962.041 1819.92 982.383 1819.92 1007.37C1819.92 1032.37 1840.26 1052.9 1865.26 1052.9C1885.79 1052.9 1903.42 1038.95 1908.85 1020.16Z"
          fill="white"
        />
        <path
          d="M1765.29 1007.57C1765.29 1018.61 1756.38 1027.71 1745.14 1027.71C1734.1 1027.71 1724.99 1018.61 1724.99 1007.57C1724.99 996.524 1734.1 987.418 1745.14 987.418C1756.38 987.225 1765.29 996.33 1765.29 1007.57Z"
          fill="white"
        />
        <path
          d="M1669.59 1020.16H1695.55C1689.73 1053.1 1660.87 1078.09 1626 1078.09C1587.25 1078.09 1555.48 1046.31 1555.48 1007.37C1555.48 968.628 1587.25 936.856 1626 936.856C1660.67 936.856 1689.73 961.847 1695.55 994.782H1669.59C1664.16 975.99 1646.53 962.041 1626 962.041C1601.2 962.041 1580.66 982.383 1580.66 1007.37C1580.66 1032.37 1601 1052.9 1626 1052.9C1646.53 1052.9 1664.16 1038.95 1669.59 1020.16Z"
          fill="white"
        />
        <path
          d="M1908.85 1020.16H1934.81C1928.99 1053.1 1900.13 1078.09 1865.26 1078.09C1826.51 1078.09 1794.74 1046.31 1794.74 1007.37C1794.74 968.628 1826.51 936.856 1865.26 936.856C1899.93 936.856 1928.99 961.847 1934.81 994.782H1908.85C1903.42 975.99 1885.79 962.041 1865.26 962.041C1840.46 962.041 1819.92 982.383 1819.92 1007.37C1819.92 1032.37 1840.26 1052.9 1865.26 1052.9C1885.79 1052.9 1903.42 1038.95 1908.85 1020.16Z"
          fill="white"
        />
        <path
          d="M1765.29 1007.57C1765.29 1018.61 1756.38 1027.71 1745.14 1027.71C1734.1 1027.71 1724.99 1018.61 1724.99 1007.57C1724.99 996.524 1734.1 987.418 1745.14 987.418C1756.38 987.225 1765.29 996.33 1765.29 1007.57Z"
          fill="white"
        />
        <path
          d="M1669.59 1020.16H1695.55C1689.73 1053.1 1660.87 1078.09 1626 1078.09C1587.25 1078.09 1555.48 1046.31 1555.48 1007.37C1555.48 968.628 1587.25 936.856 1626 936.856C1660.67 936.856 1689.73 961.847 1695.55 994.782H1669.59C1664.16 975.99 1646.53 962.041 1626 962.041C1601.2 962.041 1580.66 982.383 1580.66 1007.37C1580.66 1032.37 1601 1052.9 1626 1052.9C1646.53 1052.9 1664.16 1038.95 1669.59 1020.16Z"
          fill="white"
        />
        <path
          d="M1884.82 894.813C1884.82 905.856 1875.91 914.961 1864.67 914.961C1853.63 914.961 1844.53 905.856 1844.53 894.813C1844.53 883.77 1853.63 874.665 1864.67 874.665C1875.91 874.665 1884.82 883.77 1884.82 894.813Z"
          fill="white"
        />
        <path
          d="M1789.12 907.599H1815.08C1809.27 940.534 1780.4 965.525 1745.53 965.525C1706.78 965.525 1675.01 933.753 1675.01 894.813C1675.01 856.066 1706.78 824.294 1745.53 824.294C1780.21 824.294 1809.27 849.286 1815.08 882.22H1789.12C1783.69 863.428 1766.07 849.479 1745.53 849.479C1720.73 849.479 1700.2 869.821 1700.2 894.813C1700.2 919.804 1720.54 940.34 1745.53 940.34C1766.26 940.34 1783.69 926.391 1789.12 907.599Z"
          fill="white"
        />
        <path
          d="M1908.85 795.041H1934.81C1928.99 827.976 1900.13 852.967 1865.26 852.967C1826.51 852.967 1794.74 821.195 1794.74 782.255C1794.74 743.509 1826.51 711.736 1865.26 711.736C1899.93 711.736 1928.99 736.728 1934.81 769.662H1908.85C1903.42 750.87 1885.79 736.922 1865.26 736.922C1840.46 736.922 1819.92 757.264 1819.92 782.255C1819.92 807.247 1840.26 827.782 1865.26 827.782C1885.79 827.782 1903.42 813.833 1908.85 795.041Z"
          fill="white"
        />
        <path
          d="M1765.29 782.255C1765.29 793.298 1756.38 802.403 1745.14 802.403C1734.1 802.403 1724.99 793.298 1724.99 782.255C1724.99 771.212 1734.1 762.107 1745.14 762.107C1756.38 762.107 1765.29 771.019 1765.29 782.255Z"
          fill="white"
        />
        <path
          d="M1669.59 795.041H1695.55C1689.73 827.976 1660.87 852.967 1626 852.967C1587.25 852.967 1555.48 821.195 1555.48 782.255C1555.48 743.509 1587.25 711.736 1626 711.736C1660.67 711.736 1689.73 736.728 1695.55 769.662H1669.59C1664.16 750.87 1646.53 736.922 1626 736.922C1601.2 736.922 1580.66 757.264 1580.66 782.255C1580.66 807.247 1601 827.782 1626 827.782C1646.53 827.782 1664.16 813.833 1669.59 795.041Z"
          fill="white"
        />
        <path
          d="M1908.85 795.045H1934.81C1928.99 827.98 1900.13 852.971 1865.26 852.971C1826.51 852.971 1794.74 821.199 1794.74 782.259C1794.74 743.512 1826.51 711.74 1865.26 711.74C1899.93 711.74 1928.99 736.732 1934.81 769.666H1908.85C1903.42 750.874 1885.79 736.925 1865.26 736.925C1840.46 736.925 1819.92 757.267 1819.92 782.259C1819.92 807.25 1840.26 827.786 1865.26 827.786C1885.79 827.786 1903.42 813.837 1908.85 795.045Z"
          fill="white"
        />
        <path
          d="M1765.29 782.259C1765.29 793.302 1756.38 802.407 1745.14 802.407C1734.1 802.407 1724.99 793.302 1724.99 782.259C1724.99 771.216 1734.1 762.111 1745.14 762.111C1756.38 762.111 1765.29 771.022 1765.29 782.259Z"
          fill="white"
        />
        <path
          d="M1669.59 795.045H1695.55C1689.73 827.98 1660.87 852.971 1626 852.971C1587.25 852.971 1555.48 821.199 1555.48 782.259C1555.48 743.512 1587.25 711.74 1626 711.74C1660.67 711.74 1689.73 736.732 1695.55 769.666H1669.59C1664.16 750.874 1646.53 736.925 1626 736.925C1601.2 736.925 1580.66 757.267 1580.66 782.259C1580.66 807.25 1601 827.786 1626 827.786C1646.53 827.786 1664.16 813.837 1669.59 795.045Z"
          fill="white"
        />
        <path
          d="M1884.82 669.7C1884.82 680.743 1875.91 689.848 1864.67 689.848C1853.63 689.848 1844.53 680.743 1844.53 669.7C1844.53 658.657 1853.63 649.552 1864.67 649.552C1875.91 649.358 1884.82 658.464 1884.82 669.7Z"
          fill="white"
        />
        <path
          d="M1789.12 682.291H1815.08C1809.27 715.225 1780.4 740.217 1745.53 740.217C1706.78 740.217 1675.01 708.444 1675.01 669.504C1675.01 630.758 1706.78 598.986 1745.53 598.986C1780.21 598.986 1809.27 623.977 1815.08 656.912H1789.12C1783.69 638.12 1766.07 624.171 1745.53 624.171C1720.73 624.171 1700.2 644.513 1700.2 669.504C1700.2 694.496 1720.54 715.031 1745.53 715.031C1766.26 715.031 1783.69 701.083 1789.12 682.291Z"
          fill="white"
        />
        <path
          d="M1908.85 569.733H1934.81C1928.99 602.667 1900.13 627.659 1865.26 627.659C1826.51 627.659 1794.74 595.887 1794.74 556.946C1794.74 518.2 1826.51 486.428 1865.26 486.428C1899.93 486.428 1928.99 511.419 1934.81 544.354H1908.85C1903.42 525.562 1885.79 511.613 1865.26 511.613C1840.46 511.613 1819.92 531.955 1819.92 556.946C1819.92 581.938 1840.26 602.474 1865.26 602.474C1885.79 602.474 1903.42 588.525 1908.85 569.733Z"
          fill="white"
        />
        <path
          d="M1765.29 556.946C1765.29 567.989 1756.38 577.095 1745.14 577.095C1734.1 577.095 1724.99 567.989 1724.99 556.946C1724.99 545.904 1734.1 536.798 1745.14 536.798C1756.38 536.798 1765.29 545.904 1765.29 556.946Z"
          fill="white"
        />
        <path
          d="M1669.59 569.733H1695.55C1689.73 602.667 1660.87 627.659 1626 627.659C1587.25 627.659 1555.48 595.887 1555.48 556.946C1555.48 518.2 1587.25 486.428 1626 486.428C1660.67 486.428 1689.73 511.419 1695.55 544.354H1669.59C1664.16 525.562 1646.53 511.613 1626 511.613C1601.2 511.613 1580.66 531.955 1580.66 556.946C1580.66 581.938 1601 602.474 1626 602.474C1646.53 602.474 1664.16 588.525 1669.59 569.733Z"
          fill="white"
        />
        <path
          d="M1908.85 569.733H1934.81C1928.99 602.667 1900.13 627.659 1865.26 627.659C1826.51 627.659 1794.74 595.887 1794.74 556.946C1794.74 518.2 1826.51 486.428 1865.26 486.428C1899.93 486.428 1928.99 511.419 1934.81 544.354H1908.85C1903.42 525.562 1885.79 511.613 1865.26 511.613C1840.46 511.613 1819.92 531.955 1819.92 556.946C1819.92 581.938 1840.26 602.474 1865.26 602.474C1885.79 602.474 1903.42 588.525 1908.85 569.733Z"
          fill="white"
        />
        <path
          d="M1765.29 556.946C1765.29 567.989 1756.38 577.095 1745.14 577.095C1734.1 577.095 1724.99 567.989 1724.99 556.946C1724.99 545.904 1734.1 536.798 1745.14 536.798C1756.38 536.798 1765.29 545.904 1765.29 556.946Z"
          fill="white"
        />
        <path
          d="M1669.59 569.733H1695.55C1689.73 602.667 1660.87 627.659 1626 627.659C1587.25 627.659 1555.48 595.887 1555.48 556.946C1555.48 518.2 1587.25 486.428 1626 486.428C1660.67 486.428 1689.73 511.419 1695.55 544.354H1669.59C1664.16 525.562 1646.53 511.613 1626 511.613C1601.2 511.613 1580.66 531.955 1580.66 556.946C1580.66 581.938 1601 602.474 1626 602.474C1646.53 602.474 1664.16 588.525 1669.59 569.733Z"
          fill="white"
        />
        <path
          d="M1884.82 444.385C1884.82 455.428 1875.91 464.533 1864.67 464.533C1853.63 464.533 1844.53 455.428 1844.53 444.385C1844.53 433.342 1853.63 424.237 1864.67 424.237C1875.91 424.237 1884.82 433.342 1884.82 444.385Z"
          fill="white"
        />
        <path
          d="M1789.12 457.171H1815.08C1809.27 490.106 1780.4 515.097 1745.53 515.097C1706.78 515.097 1675.01 483.325 1675.01 444.385C1675.01 405.638 1706.78 373.866 1745.53 373.866C1780.21 373.866 1809.27 398.858 1815.08 431.792H1789.12C1783.69 413 1766.07 399.052 1745.53 399.052C1720.73 399.052 1700.2 419.393 1700.2 444.385C1700.2 469.376 1720.54 489.912 1745.53 489.912C1766.26 489.912 1783.69 475.963 1789.12 457.171Z"
          fill="white"
        />
        <path
          d="M1908.85 344.421H1934.81C1928.99 377.355 1900.13 402.347 1865.26 402.347C1826.51 402.347 1794.74 370.574 1794.74 331.634C1794.74 292.888 1826.51 261.116 1865.26 261.116C1899.93 261.116 1928.99 286.107 1934.81 319.042H1908.85C1903.42 300.25 1885.79 286.301 1865.26 286.301C1840.46 286.301 1819.92 306.643 1819.92 331.634C1819.92 356.626 1840.26 377.161 1865.26 377.161C1885.79 377.161 1903.42 363.213 1908.85 344.421Z"
          fill="white"
        />
        <path
          d="M1765.29 331.826C1765.29 342.869 1756.38 351.975 1745.14 351.975C1734.1 351.975 1724.99 342.869 1724.99 331.826C1724.99 320.784 1734.1 311.678 1745.14 311.678C1756.38 311.484 1765.29 320.59 1765.29 331.826Z"
          fill="white"
        />
        <path
          d="M1669.59 344.421H1695.55C1689.73 377.355 1660.87 402.347 1626 402.347C1587.25 402.347 1555.48 370.574 1555.48 331.634C1555.48 292.888 1587.25 261.116 1626 261.116C1660.67 261.116 1689.73 286.107 1695.55 319.042H1669.59C1664.16 300.25 1646.53 286.301 1626 286.301C1601.2 286.301 1580.66 306.643 1580.66 331.634C1580.66 356.626 1601 377.161 1626 377.161C1646.53 377.161 1664.16 363.213 1669.59 344.421Z"
          fill="white"
        />
        <path
          d="M1908.85 344.421H1934.81C1928.99 377.355 1900.13 402.347 1865.26 402.347C1826.51 402.347 1794.74 370.574 1794.74 331.634C1794.74 292.888 1826.51 261.116 1865.26 261.116C1899.93 261.116 1928.99 286.107 1934.81 319.042H1908.85C1903.42 300.25 1885.79 286.301 1865.26 286.301C1840.46 286.301 1819.92 306.643 1819.92 331.634C1819.92 356.626 1840.26 377.161 1865.26 377.161C1885.79 377.161 1903.42 363.213 1908.85 344.421Z"
          fill="white"
        />
        <path
          d="M1765.29 331.83C1765.29 342.873 1756.38 351.978 1745.14 351.978C1734.1 351.978 1724.99 342.873 1724.99 331.83C1724.99 320.787 1734.1 311.682 1745.14 311.682C1756.38 311.488 1765.29 320.594 1765.29 331.83Z"
          fill="white"
        />
        <path
          d="M1669.59 344.421H1695.55C1689.73 377.355 1660.87 402.347 1626 402.347C1587.25 402.347 1555.48 370.574 1555.48 331.634C1555.48 292.888 1587.25 261.116 1626 261.116C1660.67 261.116 1689.73 286.107 1695.55 319.042H1669.59C1664.16 300.25 1646.53 286.301 1626 286.301C1601.2 286.301 1580.66 306.643 1580.66 331.634C1580.66 356.626 1601 377.161 1626 377.161C1646.53 377.161 1664.16 363.213 1669.59 344.421Z"
          fill="white"
        />
        <path
          d="M1884.82 219.076C1884.82 230.119 1875.91 239.225 1864.67 239.225C1853.63 239.225 1844.53 230.119 1844.53 219.076C1844.53 208.034 1853.63 198.928 1864.67 198.928C1875.91 198.928 1884.82 208.034 1884.82 219.076Z"
          fill="white"
        />
        <path
          d="M1789.12 231.863H1815.08C1809.27 264.797 1780.4 289.789 1745.53 289.789C1706.78 289.789 1675.01 258.017 1675.01 219.076C1675.01 180.33 1706.78 148.558 1745.53 148.558C1780.21 148.558 1809.27 173.549 1815.08 206.484H1789.12C1783.69 187.692 1766.07 173.743 1745.53 173.743C1720.73 173.743 1700.2 194.085 1700.2 219.076C1700.2 244.068 1720.54 264.603 1745.53 264.603C1766.26 264.603 1783.69 250.655 1789.12 231.863Z"
          fill="white"
        />
        <path
          d="M1908.85 119.305H1934.81C1928.99 152.239 1900.13 177.231 1865.26 177.231C1826.51 177.231 1794.74 145.459 1794.74 106.519C1794.74 67.7721 1826.51 36 1865.26 36C1899.93 36 1928.99 60.9915 1934.81 93.926H1908.85C1903.42 75.1339 1885.79 61.1852 1865.26 61.1852C1840.46 61.1852 1819.92 81.5271 1819.92 106.519C1819.92 131.51 1840.26 152.046 1865.26 152.046C1885.79 152.046 1903.42 138.097 1908.85 119.305Z"
          fill="white"
        />
        <path
          d="M1765.29 106.519C1765.29 117.561 1756.38 126.667 1745.14 126.667C1734.1 126.667 1724.99 117.561 1724.99 106.519C1724.99 95.4759 1734.1 86.3704 1745.14 86.3704C1756.38 86.3704 1765.29 95.4759 1765.29 106.519Z"
          fill="white"
        />
        <path
          d="M1669.59 119.305H1695.55C1689.73 152.239 1660.87 177.231 1626 177.231C1587.25 177.231 1555.48 145.459 1555.48 106.519C1555.48 67.7721 1587.25 36 1626 36C1660.67 36 1689.73 60.9915 1695.55 93.926H1669.59C1664.16 75.1339 1646.53 61.1852 1626 61.1852C1601.2 61.1852 1580.66 81.5271 1580.66 106.519C1580.66 131.51 1601 152.046 1626 152.046C1646.53 152.046 1664.16 138.097 1669.59 119.305Z"
          fill="white"
        />
        <path
          d="M1645.56 1345.24C1645.56 1356.29 1636.65 1365.39 1625.42 1365.39C1614.37 1365.39 1605.27 1356.29 1605.27 1345.24C1605.27 1334.2 1614.37 1325.1 1625.42 1325.1C1636.65 1325.1 1645.56 1334.2 1645.56 1345.24Z"
          fill="white"
        />
        <path
          d="M1669.59 1245.47H1695.55C1689.73 1278.41 1660.87 1303.4 1626 1303.4C1587.25 1303.4 1555.48 1271.63 1555.48 1232.69C1555.48 1193.94 1587.25 1162.17 1626 1162.17C1660.67 1162.17 1689.73 1187.16 1695.55 1220.09H1669.59C1664.16 1201.3 1646.53 1187.35 1626 1187.35C1601.2 1187.35 1580.66 1207.7 1580.66 1232.69C1580.66 1257.68 1601 1278.21 1626 1278.21C1646.53 1278.21 1664.16 1264.27 1669.59 1245.47Z"
          fill="white"
        />
        <path
          d="M1525.84 1232.69C1525.84 1243.73 1516.93 1252.83 1505.69 1252.83C1494.65 1252.83 1485.54 1243.73 1485.54 1232.69C1485.54 1221.64 1494.65 1212.54 1505.69 1212.54C1517.12 1212.54 1525.84 1221.64 1525.84 1232.69Z"
          fill="white"
        />
        <path
          d="M1430.33 1245.47H1456.29C1450.47 1278.41 1421.61 1303.4 1386.74 1303.4C1347.99 1303.4 1316.22 1271.63 1316.22 1232.69C1316.22 1193.94 1347.99 1162.17 1386.74 1162.17C1421.42 1162.17 1450.47 1187.16 1456.29 1220.09H1430.33C1424.9 1201.3 1407.27 1187.35 1386.74 1187.35C1361.94 1187.35 1341.4 1207.7 1341.4 1232.69C1341.4 1257.68 1361.75 1278.21 1386.74 1278.21C1407.27 1278.21 1424.71 1264.27 1430.33 1245.47Z"
          fill="white"
        />
        <path
          d="M1669.59 1245.47H1695.55C1689.73 1278.41 1660.87 1303.4 1626 1303.4C1587.25 1303.4 1555.48 1271.63 1555.48 1232.69C1555.48 1193.94 1587.25 1162.17 1626 1162.17C1660.67 1162.17 1689.73 1187.16 1695.55 1220.09H1669.59C1664.16 1201.3 1646.53 1187.35 1626 1187.35C1601.2 1187.35 1580.66 1207.7 1580.66 1232.69C1580.66 1257.68 1601 1278.21 1626 1278.21C1646.53 1278.21 1664.16 1264.27 1669.59 1245.47Z"
          fill="white"
        />
        <path
          d="M1525.84 1232.69C1525.84 1243.73 1516.93 1252.83 1505.69 1252.83C1494.65 1252.83 1485.54 1243.73 1485.54 1232.69C1485.54 1221.64 1494.65 1212.54 1505.69 1212.54C1517.12 1212.54 1525.84 1221.64 1525.84 1232.69Z"
          fill="white"
        />
        <path
          d="M1430.33 1245.47H1456.29C1450.47 1278.41 1421.61 1303.4 1386.74 1303.4C1347.99 1303.4 1316.22 1271.63 1316.22 1232.69C1316.22 1193.94 1347.99 1162.17 1386.74 1162.17C1421.42 1162.17 1450.47 1187.16 1456.29 1220.09H1430.33C1424.9 1201.3 1407.27 1187.35 1386.74 1187.35C1361.94 1187.35 1341.4 1207.7 1341.4 1232.69C1341.4 1257.68 1361.75 1278.21 1386.74 1278.21C1407.27 1278.21 1424.71 1264.27 1430.33 1245.47Z"
          fill="white"
        />
        <path
          d="M1645.56 1120.12C1645.56 1131.17 1636.65 1140.27 1625.42 1140.27C1614.37 1140.27 1605.27 1131.17 1605.27 1120.12C1605.27 1109.08 1614.37 1099.98 1625.42 1099.98C1636.65 1099.98 1645.56 1108.89 1645.56 1120.12Z"
          fill="white"
        />
        <path
          d="M1549.86 1132.91H1575.82C1570.01 1165.85 1541.14 1190.84 1506.27 1190.84C1467.52 1190.84 1435.75 1159.07 1435.75 1120.12C1435.75 1081.38 1467.52 1049.61 1506.27 1049.61C1540.95 1049.61 1570.01 1074.6 1575.82 1107.53H1549.86C1544.44 1088.74 1526.81 1074.79 1506.27 1074.79C1481.47 1074.79 1460.94 1095.13 1460.94 1120.12C1460.94 1145.12 1481.28 1165.65 1506.27 1165.65C1526.81 1165.65 1544.44 1151.7 1549.86 1132.91Z"
          fill="white"
        />
        <path
          d="M1669.59 1020.16H1695.55C1689.73 1053.1 1660.87 1078.09 1626 1078.09C1587.25 1078.09 1555.48 1046.31 1555.48 1007.37C1555.48 968.628 1587.25 936.856 1626 936.856C1660.67 936.856 1689.73 961.847 1695.55 994.782H1669.59C1664.16 975.99 1646.53 962.041 1626 962.041C1601.2 962.041 1580.66 982.383 1580.66 1007.37C1580.66 1032.37 1601 1052.9 1626 1052.9C1646.53 1052.9 1664.16 1038.95 1669.59 1020.16Z"
          fill="white"
        />
        <path
          d="M1525.84 1007.57C1525.84 1018.61 1516.93 1027.71 1505.69 1027.71C1494.65 1027.71 1485.54 1018.61 1485.54 1007.57C1485.54 996.524 1494.65 987.418 1505.69 987.418C1517.12 987.225 1525.84 996.33 1525.84 1007.57Z"
          fill="white"
        />
        <path
          d="M1430.33 1020.16H1456.29C1450.47 1053.1 1421.61 1078.09 1386.74 1078.09C1347.99 1078.09 1316.22 1046.31 1316.22 1007.37C1316.22 968.628 1347.99 936.856 1386.74 936.856C1421.42 936.856 1450.47 961.847 1456.29 994.782H1430.33C1424.9 975.99 1407.27 962.041 1386.74 962.041C1361.94 962.041 1341.4 982.383 1341.4 1007.37C1341.4 1032.37 1361.75 1052.9 1386.74 1052.9C1407.27 1052.9 1424.71 1038.95 1430.33 1020.16Z"
          fill="white"
        />
        <path
          d="M1669.59 1020.16H1695.55C1689.73 1053.1 1660.87 1078.09 1626 1078.09C1587.25 1078.09 1555.48 1046.31 1555.48 1007.37C1555.48 968.628 1587.25 936.856 1626 936.856C1660.67 936.856 1689.73 961.847 1695.55 994.782H1669.59C1664.16 975.99 1646.53 962.041 1626 962.041C1601.2 962.041 1580.66 982.383 1580.66 1007.37C1580.66 1032.37 1601 1052.9 1626 1052.9C1646.53 1052.9 1664.16 1038.95 1669.59 1020.16Z"
          fill="white"
        />
        <path
          d="M1525.84 1007.57C1525.84 1018.61 1516.93 1027.71 1505.69 1027.71C1494.65 1027.71 1485.54 1018.61 1485.54 1007.57C1485.54 996.524 1494.65 987.418 1505.69 987.418C1517.12 987.225 1525.84 996.33 1525.84 1007.57Z"
          fill="white"
        />
        <path
          d="M1430.33 1020.16H1456.29C1450.47 1053.1 1421.61 1078.09 1386.74 1078.09C1347.99 1078.09 1316.22 1046.31 1316.22 1007.37C1316.22 968.628 1347.99 936.856 1386.74 936.856C1421.42 936.856 1450.47 961.847 1456.29 994.782H1430.33C1424.9 975.99 1407.27 962.041 1386.74 962.041C1361.94 962.041 1341.4 982.383 1341.4 1007.37C1341.4 1032.37 1361.75 1052.9 1386.74 1052.9C1407.27 1052.9 1424.71 1038.95 1430.33 1020.16Z"
          fill="white"
        />
        <path
          d="M1645.56 894.813C1645.56 905.856 1636.65 914.961 1625.42 914.961C1614.37 914.961 1605.27 905.856 1605.27 894.813C1605.27 883.77 1614.37 874.665 1625.42 874.665C1636.65 874.665 1645.56 883.77 1645.56 894.813Z"
          fill="white"
        />
        <path
          d="M1549.86 907.599H1575.82C1570.01 940.534 1541.14 965.525 1506.27 965.525C1467.52 965.525 1435.75 933.753 1435.75 894.813C1435.75 856.066 1467.52 824.294 1506.27 824.294C1540.95 824.294 1570.01 849.286 1575.82 882.22H1549.86C1544.44 863.428 1526.81 849.479 1506.27 849.479C1481.47 849.479 1460.94 869.821 1460.94 894.813C1460.94 919.804 1481.28 940.34 1506.27 940.34C1526.81 940.34 1544.44 926.391 1549.86 907.599Z"
          fill="white"
        />
        <path
          d="M1669.59 795.041H1695.55C1689.73 827.976 1660.87 852.967 1626 852.967C1587.25 852.967 1555.48 821.195 1555.48 782.255C1555.48 743.509 1587.25 711.736 1626 711.736C1660.67 711.736 1689.73 736.728 1695.55 769.662H1669.59C1664.16 750.87 1646.53 736.922 1626 736.922C1601.2 736.922 1580.66 757.264 1580.66 782.255C1580.66 807.247 1601 827.782 1626 827.782C1646.53 827.782 1664.16 813.833 1669.59 795.041Z"
          fill="white"
        />
        <path
          d="M1525.84 782.255C1525.84 793.298 1516.93 802.403 1505.69 802.403C1494.65 802.403 1485.54 793.298 1485.54 782.255C1485.54 771.212 1494.65 762.107 1505.69 762.107C1517.12 762.107 1525.84 771.019 1525.84 782.255Z"
          fill="white"
        />
        <path
          d="M1430.33 795.041H1456.29C1450.47 827.976 1421.61 852.967 1386.74 852.967C1347.99 852.967 1316.22 821.195 1316.22 782.255C1316.22 743.509 1347.99 711.736 1386.74 711.736C1421.42 711.736 1450.47 736.728 1456.29 769.662H1430.33C1424.9 750.87 1407.27 736.922 1386.74 736.922C1361.94 736.922 1341.4 757.264 1341.4 782.255C1341.4 807.247 1361.75 827.782 1386.74 827.782C1407.27 827.782 1424.71 813.833 1430.33 795.041Z"
          fill="white"
        />
        <path
          d="M1669.59 795.045H1695.55C1689.73 827.98 1660.87 852.971 1626 852.971C1587.25 852.971 1555.48 821.199 1555.48 782.259C1555.48 743.512 1587.25 711.74 1626 711.74C1660.67 711.74 1689.73 736.732 1695.55 769.666H1669.59C1664.16 750.874 1646.53 736.925 1626 736.925C1601.2 736.925 1580.66 757.267 1580.66 782.259C1580.66 807.25 1601 827.786 1626 827.786C1646.53 827.786 1664.16 813.837 1669.59 795.045Z"
          fill="white"
        />
        <path
          d="M1525.84 782.259C1525.84 793.302 1516.93 802.407 1505.69 802.407C1494.65 802.407 1485.54 793.302 1485.54 782.259C1485.54 771.216 1494.65 762.111 1505.69 762.111C1517.12 762.111 1525.84 771.022 1525.84 782.259Z"
          fill="white"
        />
        <path
          d="M1430.33 795.045H1456.29C1450.47 827.98 1421.61 852.971 1386.74 852.971C1347.99 852.971 1316.22 821.199 1316.22 782.259C1316.22 743.512 1347.99 711.74 1386.74 711.74C1421.42 711.74 1450.47 736.732 1456.29 769.666H1430.33C1424.9 750.874 1407.27 736.925 1386.74 736.925C1361.94 736.925 1341.4 757.267 1341.4 782.259C1341.4 807.25 1361.75 827.786 1386.74 827.786C1407.27 827.786 1424.71 813.837 1430.33 795.045Z"
          fill="white"
        />
        <path
          d="M1645.56 669.7C1645.56 680.743 1636.65 689.848 1625.42 689.848C1614.37 689.848 1605.27 680.743 1605.27 669.7C1605.27 658.657 1614.37 649.552 1625.42 649.552C1636.65 649.358 1645.56 658.464 1645.56 669.7Z"
          fill="white"
        />
        <path
          d="M1549.86 682.291H1575.82C1570.01 715.225 1541.14 740.217 1506.27 740.217C1467.52 740.217 1435.75 708.444 1435.75 669.504C1435.75 630.758 1467.52 598.986 1506.27 598.986C1540.95 598.986 1570.01 623.977 1575.82 656.912H1549.86C1544.44 638.12 1526.81 624.171 1506.27 624.171C1481.47 624.171 1460.94 644.513 1460.94 669.504C1460.94 694.496 1481.28 715.031 1506.27 715.031C1526.81 715.031 1544.44 701.083 1549.86 682.291Z"
          fill="white"
        />
        <path
          d="M1669.59 569.733H1695.55C1689.73 602.667 1660.87 627.659 1626 627.659C1587.25 627.659 1555.48 595.887 1555.48 556.946C1555.48 518.2 1587.25 486.428 1626 486.428C1660.67 486.428 1689.73 511.419 1695.55 544.354H1669.59C1664.16 525.562 1646.53 511.613 1626 511.613C1601.2 511.613 1580.66 531.955 1580.66 556.946C1580.66 581.938 1601 602.474 1626 602.474C1646.53 602.474 1664.16 588.525 1669.59 569.733Z"
          fill="white"
        />
        <path
          d="M1525.84 556.946C1525.84 567.989 1516.93 577.095 1505.69 577.095C1494.65 577.095 1485.54 567.989 1485.54 556.946C1485.54 545.904 1494.65 536.798 1505.69 536.798C1517.12 536.798 1525.84 545.904 1525.84 556.946Z"
          fill="white"
        />
        <path
          d="M1430.33 569.733H1456.29C1450.47 602.667 1421.61 627.659 1386.74 627.659C1347.99 627.659 1316.22 595.887 1316.22 556.946C1316.22 518.2 1347.99 486.428 1386.74 486.428C1421.42 486.428 1450.47 511.419 1456.29 544.354H1430.33C1424.9 525.562 1407.27 511.613 1386.74 511.613C1361.94 511.613 1341.4 531.955 1341.4 556.946C1341.4 581.938 1361.75 602.474 1386.74 602.474C1407.27 602.474 1424.71 588.525 1430.33 569.733Z"
          fill="white"
        />
        <path
          d="M1669.59 569.733H1695.55C1689.73 602.667 1660.87 627.659 1626 627.659C1587.25 627.659 1555.48 595.887 1555.48 556.946C1555.48 518.2 1587.25 486.428 1626 486.428C1660.67 486.428 1689.73 511.419 1695.55 544.354H1669.59C1664.16 525.562 1646.53 511.613 1626 511.613C1601.2 511.613 1580.66 531.955 1580.66 556.946C1580.66 581.938 1601 602.474 1626 602.474C1646.53 602.474 1664.16 588.525 1669.59 569.733Z"
          fill="white"
        />
        <path
          d="M1525.84 556.946C1525.84 567.989 1516.93 577.095 1505.69 577.095C1494.65 577.095 1485.54 567.989 1485.54 556.946C1485.54 545.904 1494.65 536.798 1505.69 536.798C1517.12 536.798 1525.84 545.904 1525.84 556.946Z"
          fill="white"
        />
        <path
          d="M1430.33 569.733H1456.29C1450.47 602.667 1421.61 627.659 1386.74 627.659C1347.99 627.659 1316.22 595.887 1316.22 556.946C1316.22 518.2 1347.99 486.428 1386.74 486.428C1421.42 486.428 1450.47 511.419 1456.29 544.354H1430.33C1424.9 525.562 1407.27 511.613 1386.74 511.613C1361.94 511.613 1341.4 531.955 1341.4 556.946C1341.4 581.938 1361.75 602.474 1386.74 602.474C1407.27 602.474 1424.71 588.525 1430.33 569.733Z"
          fill="white"
        />
        <path
          d="M1645.56 444.385C1645.56 455.428 1636.65 464.533 1625.42 464.533C1614.37 464.533 1605.27 455.428 1605.27 444.385C1605.27 433.342 1614.37 424.237 1625.42 424.237C1636.65 424.237 1645.56 433.342 1645.56 444.385Z"
          fill="white"
        />
        <path
          d="M1549.86 457.171H1575.82C1570.01 490.106 1541.14 515.097 1506.27 515.097C1467.52 515.097 1435.75 483.325 1435.75 444.385C1435.75 405.638 1467.52 373.866 1506.27 373.866C1540.95 373.866 1570.01 398.858 1575.82 431.792H1549.86C1544.44 413 1526.81 399.052 1506.27 399.052C1481.47 399.052 1460.94 419.393 1460.94 444.385C1460.94 469.376 1481.28 489.912 1506.27 489.912C1526.81 489.912 1544.44 475.963 1549.86 457.171Z"
          fill="white"
        />
        <path
          d="M1669.59 344.421H1695.55C1689.73 377.355 1660.87 402.347 1626 402.347C1587.25 402.347 1555.48 370.574 1555.48 331.634C1555.48 292.888 1587.25 261.116 1626 261.116C1660.67 261.116 1689.73 286.107 1695.55 319.042H1669.59C1664.16 300.25 1646.53 286.301 1626 286.301C1601.2 286.301 1580.66 306.643 1580.66 331.634C1580.66 356.626 1601 377.161 1626 377.161C1646.53 377.161 1664.16 363.213 1669.59 344.421Z"
          fill="white"
        />
        <path
          d="M1525.84 331.826C1525.84 342.869 1516.93 351.975 1505.69 351.975C1494.65 351.975 1485.54 342.869 1485.54 331.826C1485.54 320.784 1494.65 311.678 1505.69 311.678C1517.12 311.484 1525.84 320.59 1525.84 331.826Z"
          fill="white"
        />
        <path
          d="M1430.33 344.421H1456.29C1450.47 377.355 1421.61 402.347 1386.74 402.347C1347.99 402.347 1316.22 370.574 1316.22 331.634C1316.22 292.888 1347.99 261.116 1386.74 261.116C1421.42 261.116 1450.47 286.107 1456.29 319.042H1430.33C1424.9 300.25 1407.27 286.301 1386.74 286.301C1361.94 286.301 1341.4 306.643 1341.4 331.634C1341.4 356.626 1361.75 377.161 1386.74 377.161C1407.27 377.161 1424.71 363.213 1430.33 344.421Z"
          fill="white"
        />
        <path
          d="M1669.59 344.421H1695.55C1689.73 377.355 1660.87 402.347 1626 402.347C1587.25 402.347 1555.48 370.574 1555.48 331.634C1555.48 292.888 1587.25 261.116 1626 261.116C1660.67 261.116 1689.73 286.107 1695.55 319.042H1669.59C1664.16 300.25 1646.53 286.301 1626 286.301C1601.2 286.301 1580.66 306.643 1580.66 331.634C1580.66 356.626 1601 377.161 1626 377.161C1646.53 377.161 1664.16 363.213 1669.59 344.421Z"
          fill="white"
        />
        <path
          d="M1525.84 331.83C1525.84 342.873 1516.93 351.978 1505.69 351.978C1494.65 351.978 1485.54 342.873 1485.54 331.83C1485.54 320.787 1494.65 311.682 1505.69 311.682C1517.12 311.488 1525.84 320.594 1525.84 331.83Z"
          fill="white"
        />
        <path
          d="M1430.33 344.421H1456.29C1450.47 377.355 1421.61 402.347 1386.74 402.347C1347.99 402.347 1316.22 370.574 1316.22 331.634C1316.22 292.888 1347.99 261.116 1386.74 261.116C1421.42 261.116 1450.47 286.107 1456.29 319.042H1430.33C1424.9 300.25 1407.27 286.301 1386.74 286.301C1361.94 286.301 1341.4 306.643 1341.4 331.634C1341.4 356.626 1361.75 377.161 1386.74 377.161C1407.27 377.161 1424.71 363.213 1430.33 344.421Z"
          fill="white"
        />
        <path
          d="M1645.56 219.076C1645.56 230.119 1636.65 239.225 1625.42 239.225C1614.37 239.225 1605.27 230.119 1605.27 219.076C1605.27 208.034 1614.37 198.928 1625.42 198.928C1636.65 198.928 1645.56 208.034 1645.56 219.076Z"
          fill="white"
        />
        <path
          d="M1549.86 231.863H1575.82C1570.01 264.797 1541.14 289.789 1506.27 289.789C1467.52 289.789 1435.75 258.017 1435.75 219.076C1435.75 180.33 1467.52 148.558 1506.27 148.558C1540.95 148.558 1570.01 173.549 1575.82 206.484H1549.86C1544.44 187.692 1526.81 173.743 1506.27 173.743C1481.47 173.743 1460.94 194.085 1460.94 219.076C1460.94 244.068 1481.28 264.603 1506.27 264.603C1526.81 264.603 1544.44 250.655 1549.86 231.863Z"
          fill="white"
        />
        <path
          d="M1669.59 119.305H1695.55C1689.73 152.239 1660.87 177.231 1626 177.231C1587.25 177.231 1555.48 145.459 1555.48 106.519C1555.48 67.7721 1587.25 36 1626 36C1660.67 36 1689.73 60.9915 1695.55 93.926H1669.59C1664.16 75.1339 1646.53 61.1852 1626 61.1852C1601.2 61.1852 1580.66 81.5271 1580.66 106.519C1580.66 131.51 1601 152.046 1626 152.046C1646.53 152.046 1664.16 138.097 1669.59 119.305Z"
          fill="white"
        />
        <path
          d="M1525.84 106.519C1525.84 117.561 1516.93 126.667 1505.69 126.667C1494.65 126.667 1485.54 117.561 1485.54 106.519C1485.54 95.4759 1494.65 86.3704 1505.69 86.3704C1517.12 86.3704 1525.84 95.4759 1525.84 106.519Z"
          fill="white"
        />
        <path
          d="M1430.33 119.305H1456.29C1450.47 152.239 1421.61 177.231 1386.74 177.231C1347.99 177.231 1316.22 145.459 1316.22 106.519C1316.22 67.7721 1347.99 36 1386.74 36C1421.42 36 1450.47 60.9915 1456.29 93.926H1430.33C1424.9 75.1339 1407.27 61.1852 1386.74 61.1852C1361.94 61.1852 1341.4 81.5271 1341.4 106.519C1341.4 131.51 1361.75 152.046 1386.74 152.046C1407.27 152.046 1424.71 138.097 1430.33 119.305Z"
          fill="white"
        />
        <path
          d="M1406.3 1345.24C1406.3 1356.29 1397.39 1365.39 1386.16 1365.39C1375.11 1365.39 1366.01 1356.29 1366.01 1345.24C1366.01 1334.2 1375.11 1325.1 1386.16 1325.1C1397.39 1325.1 1406.3 1334.2 1406.3 1345.24Z"
          fill="white"
        />
        <path
          d="M1430.33 1245.47H1456.29C1450.47 1278.41 1421.61 1303.4 1386.74 1303.4C1347.99 1303.4 1316.22 1271.63 1316.22 1232.69C1316.22 1193.94 1347.99 1162.17 1386.74 1162.17C1421.42 1162.17 1450.47 1187.16 1456.29 1220.09H1430.33C1424.9 1201.3 1407.27 1187.35 1386.74 1187.35C1361.94 1187.35 1341.4 1207.7 1341.4 1232.69C1341.4 1257.68 1361.75 1278.21 1386.74 1278.21C1407.27 1278.21 1424.71 1264.27 1430.33 1245.47Z"
          fill="white"
        />
        <path
          d="M1286.58 1232.69C1286.58 1243.73 1277.67 1252.83 1266.43 1252.83C1255.39 1252.83 1246.28 1243.73 1246.28 1232.69C1246.28 1221.64 1255.39 1212.54 1266.43 1212.54C1277.67 1212.54 1286.58 1221.64 1286.58 1232.69Z"
          fill="white"
        />
        <path
          d="M1190.87 1245.47H1216.83C1211.02 1278.41 1182.16 1303.4 1147.28 1303.4C1108.54 1303.4 1076.77 1271.63 1076.77 1232.69C1076.77 1193.94 1108.54 1162.17 1147.28 1162.17C1181.96 1162.17 1211.02 1187.16 1216.83 1220.09H1190.87C1185.45 1201.3 1167.82 1187.35 1147.28 1187.35C1122.49 1187.35 1101.95 1207.7 1101.95 1232.69C1101.95 1257.68 1122.29 1278.21 1147.28 1278.21C1167.82 1278.21 1185.45 1264.27 1190.87 1245.47Z"
          fill="white"
        />
        <path
          d="M1430.33 1245.47H1456.29C1450.47 1278.41 1421.61 1303.4 1386.74 1303.4C1347.99 1303.4 1316.22 1271.63 1316.22 1232.69C1316.22 1193.94 1347.99 1162.17 1386.74 1162.17C1421.42 1162.17 1450.47 1187.16 1456.29 1220.09H1430.33C1424.9 1201.3 1407.27 1187.35 1386.74 1187.35C1361.94 1187.35 1341.4 1207.7 1341.4 1232.69C1341.4 1257.68 1361.75 1278.21 1386.74 1278.21C1407.27 1278.21 1424.71 1264.27 1430.33 1245.47Z"
          fill="white"
        />
        <path
          d="M1286.58 1232.69C1286.58 1243.73 1277.67 1252.83 1266.43 1252.83C1255.39 1252.83 1246.28 1243.73 1246.28 1232.69C1246.28 1221.64 1255.39 1212.54 1266.43 1212.54C1277.67 1212.54 1286.58 1221.64 1286.58 1232.69Z"
          fill="white"
        />
        <path
          d="M1190.87 1245.47H1216.83C1211.02 1278.41 1182.16 1303.4 1147.28 1303.4C1108.54 1303.4 1076.77 1271.63 1076.77 1232.69C1076.77 1193.94 1108.54 1162.17 1147.28 1162.17C1181.96 1162.17 1211.02 1187.16 1216.83 1220.09H1190.87C1185.45 1201.3 1167.82 1187.35 1147.28 1187.35C1122.49 1187.35 1101.95 1207.7 1101.95 1232.69C1101.95 1257.68 1122.29 1278.21 1147.28 1278.21C1167.82 1278.21 1185.45 1264.27 1190.87 1245.47Z"
          fill="white"
        />
        <path
          d="M1406.3 1120.12C1406.3 1131.17 1397.39 1140.27 1386.16 1140.27C1375.11 1140.27 1366.01 1131.17 1366.01 1120.12C1366.01 1109.08 1375.11 1099.98 1386.16 1099.98C1397.39 1099.98 1406.3 1108.89 1406.3 1120.12Z"
          fill="white"
        />
        <path
          d="M1310.6 1132.91H1336.56C1330.75 1165.85 1301.88 1190.84 1267.01 1190.84C1228.26 1190.84 1196.49 1159.07 1196.49 1120.12C1196.49 1081.38 1228.26 1049.61 1267.01 1049.61C1301.69 1049.61 1330.75 1074.6 1336.56 1107.53H1310.6C1305.18 1088.74 1287.55 1074.79 1267.01 1074.79C1242.21 1074.79 1221.68 1095.13 1221.68 1120.12C1221.68 1145.12 1242.02 1165.65 1267.01 1165.65C1287.55 1165.65 1305.18 1151.7 1310.6 1132.91Z"
          fill="white"
        />
        <path
          d="M1430.33 1020.16H1456.29C1450.47 1053.1 1421.61 1078.09 1386.74 1078.09C1347.99 1078.09 1316.22 1046.31 1316.22 1007.37C1316.22 968.628 1347.99 936.856 1386.74 936.856C1421.42 936.856 1450.47 961.847 1456.29 994.782H1430.33C1424.9 975.99 1407.27 962.041 1386.74 962.041C1361.94 962.041 1341.4 982.383 1341.4 1007.37C1341.4 1032.37 1361.75 1052.9 1386.74 1052.9C1407.27 1052.9 1424.71 1038.95 1430.33 1020.16Z"
          fill="white"
        />
        <path
          d="M1286.58 1007.57C1286.58 1018.61 1277.67 1027.71 1266.43 1027.71C1255.39 1027.71 1246.28 1018.61 1246.28 1007.57C1246.28 996.524 1255.39 987.418 1266.43 987.418C1277.67 987.225 1286.58 996.33 1286.58 1007.57Z"
          fill="white"
        />
        <path
          d="M1190.87 1020.16H1216.83C1211.02 1053.1 1182.16 1078.09 1147.28 1078.09C1108.54 1078.09 1076.77 1046.31 1076.77 1007.37C1076.77 968.628 1108.54 936.856 1147.28 936.856C1181.96 936.856 1211.02 961.847 1216.83 994.782H1190.87C1185.45 975.99 1167.82 962.041 1147.28 962.041C1122.49 962.041 1101.95 982.383 1101.95 1007.37C1101.95 1032.37 1122.29 1052.9 1147.28 1052.9C1167.82 1052.9 1185.45 1038.95 1190.87 1020.16Z"
          fill="white"
        />
        <path
          d="M1430.33 1020.16H1456.29C1450.47 1053.1 1421.61 1078.09 1386.74 1078.09C1347.99 1078.09 1316.22 1046.31 1316.22 1007.37C1316.22 968.628 1347.99 936.856 1386.74 936.856C1421.42 936.856 1450.47 961.847 1456.29 994.782H1430.33C1424.9 975.99 1407.27 962.041 1386.74 962.041C1361.94 962.041 1341.4 982.383 1341.4 1007.37C1341.4 1032.37 1361.75 1052.9 1386.74 1052.9C1407.27 1052.9 1424.71 1038.95 1430.33 1020.16Z"
          fill="white"
        />
        <path
          d="M1286.58 1007.57C1286.58 1018.61 1277.67 1027.71 1266.43 1027.71C1255.39 1027.71 1246.28 1018.61 1246.28 1007.57C1246.28 996.524 1255.39 987.418 1266.43 987.418C1277.67 987.225 1286.58 996.33 1286.58 1007.57Z"
          fill="white"
        />
        <path
          d="M1190.87 1020.16H1216.83C1211.02 1053.1 1182.16 1078.09 1147.28 1078.09C1108.54 1078.09 1076.77 1046.31 1076.77 1007.37C1076.77 968.628 1108.54 936.856 1147.28 936.856C1181.96 936.856 1211.02 961.847 1216.83 994.782H1190.87C1185.45 975.99 1167.82 962.041 1147.28 962.041C1122.49 962.041 1101.95 982.383 1101.95 1007.37C1101.95 1032.37 1122.29 1052.9 1147.28 1052.9C1167.82 1052.9 1185.45 1038.95 1190.87 1020.16Z"
          fill="white"
        />
        <path
          d="M1406.3 894.813C1406.3 905.856 1397.39 914.961 1386.16 914.961C1375.11 914.961 1366.01 905.856 1366.01 894.813C1366.01 883.77 1375.11 874.665 1386.16 874.665C1397.39 874.665 1406.3 883.77 1406.3 894.813Z"
          fill="white"
        />
        <path
          d="M1310.6 907.599H1336.56C1330.75 940.534 1301.88 965.525 1267.01 965.525C1228.26 965.525 1196.49 933.753 1196.49 894.813C1196.49 856.066 1228.26 824.294 1267.01 824.294C1301.69 824.294 1330.75 849.286 1336.56 882.22H1310.6C1305.18 863.428 1287.55 849.479 1267.01 849.479C1242.21 849.479 1221.68 869.821 1221.68 894.813C1221.68 919.804 1242.02 940.34 1267.01 940.34C1287.55 940.34 1305.18 926.391 1310.6 907.599Z"
          fill="white"
        />
        <path
          d="M1430.33 795.041H1456.29C1450.47 827.976 1421.61 852.967 1386.74 852.967C1347.99 852.967 1316.22 821.195 1316.22 782.255C1316.22 743.509 1347.99 711.736 1386.74 711.736C1421.42 711.736 1450.47 736.728 1456.29 769.662H1430.33C1424.9 750.87 1407.27 736.922 1386.74 736.922C1361.94 736.922 1341.4 757.264 1341.4 782.255C1341.4 807.247 1361.75 827.782 1386.74 827.782C1407.27 827.782 1424.71 813.833 1430.33 795.041Z"
          fill="white"
        />
        <path
          d="M1286.58 782.255C1286.58 793.298 1277.67 802.403 1266.43 802.403C1255.39 802.403 1246.28 793.298 1246.28 782.255C1246.28 771.212 1255.39 762.107 1266.43 762.107C1277.67 762.107 1286.58 771.019 1286.58 782.255Z"
          fill="white"
        />
        <path
          d="M1190.87 795.041H1216.83C1211.02 827.976 1182.16 852.967 1147.28 852.967C1108.54 852.967 1076.77 821.195 1076.77 782.255C1076.77 743.509 1108.54 711.736 1147.28 711.736C1181.96 711.736 1211.02 736.728 1216.83 769.662H1190.87C1185.45 750.87 1167.82 736.922 1147.28 736.922C1122.49 736.922 1101.95 757.264 1101.95 782.255C1101.95 807.247 1122.29 827.782 1147.28 827.782C1167.82 827.782 1185.45 813.833 1190.87 795.041Z"
          fill="white"
        />
        <path
          d="M1430.33 795.045H1456.29C1450.47 827.98 1421.61 852.971 1386.74 852.971C1347.99 852.971 1316.22 821.199 1316.22 782.259C1316.22 743.512 1347.99 711.74 1386.74 711.74C1421.42 711.74 1450.47 736.732 1456.29 769.666H1430.33C1424.9 750.874 1407.27 736.925 1386.74 736.925C1361.94 736.925 1341.4 757.267 1341.4 782.259C1341.4 807.25 1361.75 827.786 1386.74 827.786C1407.27 827.786 1424.71 813.837 1430.33 795.045Z"
          fill="white"
        />
        <path
          d="M1286.58 782.259C1286.58 793.302 1277.67 802.407 1266.43 802.407C1255.39 802.407 1246.28 793.302 1246.28 782.259C1246.28 771.216 1255.39 762.111 1266.43 762.111C1277.67 762.111 1286.58 771.022 1286.58 782.259Z"
          fill="white"
        />
        <path
          d="M1190.87 795.045H1216.83C1211.02 827.98 1182.16 852.971 1147.28 852.971C1108.54 852.971 1076.77 821.199 1076.77 782.259C1076.77 743.512 1108.54 711.74 1147.28 711.74C1181.96 711.74 1211.02 736.732 1216.83 769.666H1190.87C1185.45 750.874 1167.82 736.925 1147.28 736.925C1122.49 736.925 1101.95 757.267 1101.95 782.259C1101.95 807.25 1122.29 827.786 1147.28 827.786C1167.82 827.786 1185.45 813.837 1190.87 795.045Z"
          fill="white"
        />
        <path
          d="M1406.3 669.7C1406.3 680.743 1397.39 689.848 1386.16 689.848C1375.11 689.848 1366.01 680.743 1366.01 669.7C1366.01 658.657 1375.11 649.552 1386.16 649.552C1397.39 649.358 1406.3 658.464 1406.3 669.7Z"
          fill="white"
        />
        <path
          d="M1310.6 682.291H1336.56C1330.75 715.225 1301.88 740.217 1267.01 740.217C1228.26 740.217 1196.49 708.444 1196.49 669.504C1196.49 630.758 1228.26 598.986 1267.01 598.986C1301.69 598.986 1330.75 623.977 1336.56 656.912H1310.6C1305.18 638.12 1287.55 624.171 1267.01 624.171C1242.21 624.171 1221.68 644.513 1221.68 669.504C1221.68 694.496 1242.02 715.031 1267.01 715.031C1287.55 715.031 1305.18 701.083 1310.6 682.291Z"
          fill="white"
        />
        <path
          d="M1430.33 569.733H1456.29C1450.47 602.667 1421.61 627.659 1386.74 627.659C1347.99 627.659 1316.22 595.887 1316.22 556.946C1316.22 518.2 1347.99 486.428 1386.74 486.428C1421.42 486.428 1450.47 511.419 1456.29 544.354H1430.33C1424.9 525.562 1407.27 511.613 1386.74 511.613C1361.94 511.613 1341.4 531.955 1341.4 556.946C1341.4 581.938 1361.75 602.474 1386.74 602.474C1407.27 602.474 1424.71 588.525 1430.33 569.733Z"
          fill="white"
        />
        <path
          d="M1286.58 556.946C1286.58 567.989 1277.67 577.095 1266.43 577.095C1255.39 577.095 1246.28 567.989 1246.28 556.946C1246.28 545.904 1255.39 536.798 1266.43 536.798C1277.67 536.798 1286.58 545.904 1286.58 556.946Z"
          fill="white"
        />
        <path
          d="M1190.87 569.733H1216.83C1211.02 602.667 1182.16 627.659 1147.28 627.659C1108.54 627.659 1076.77 595.887 1076.77 556.946C1076.77 518.2 1108.54 486.428 1147.28 486.428C1181.96 486.428 1211.02 511.419 1216.83 544.354H1190.87C1185.45 525.562 1167.82 511.613 1147.28 511.613C1122.49 511.613 1101.95 531.955 1101.95 556.946C1101.95 581.938 1122.29 602.474 1147.28 602.474C1167.82 602.474 1185.45 588.525 1190.87 569.733Z"
          fill="white"
        />
        <path
          d="M1430.33 569.733H1456.29C1450.47 602.667 1421.61 627.659 1386.74 627.659C1347.99 627.659 1316.22 595.887 1316.22 556.946C1316.22 518.2 1347.99 486.428 1386.74 486.428C1421.42 486.428 1450.47 511.419 1456.29 544.354H1430.33C1424.9 525.562 1407.27 511.613 1386.74 511.613C1361.94 511.613 1341.4 531.955 1341.4 556.946C1341.4 581.938 1361.75 602.474 1386.74 602.474C1407.27 602.474 1424.71 588.525 1430.33 569.733Z"
          fill="white"
        />
        <path
          d="M1286.58 556.946C1286.58 567.989 1277.67 577.095 1266.43 577.095C1255.39 577.095 1246.28 567.989 1246.28 556.946C1246.28 545.904 1255.39 536.798 1266.43 536.798C1277.67 536.798 1286.58 545.904 1286.58 556.946Z"
          fill="white"
        />
        <path
          d="M1190.87 569.733H1216.83C1211.02 602.667 1182.16 627.659 1147.28 627.659C1108.54 627.659 1076.77 595.887 1076.77 556.946C1076.77 518.2 1108.54 486.428 1147.28 486.428C1181.96 486.428 1211.02 511.419 1216.83 544.354H1190.87C1185.45 525.562 1167.82 511.613 1147.28 511.613C1122.49 511.613 1101.95 531.955 1101.95 556.946C1101.95 581.938 1122.29 602.474 1147.28 602.474C1167.82 602.474 1185.45 588.525 1190.87 569.733Z"
          fill="white"
        />
        <path
          d="M1406.3 444.385C1406.3 455.428 1397.39 464.533 1386.16 464.533C1375.11 464.533 1366.01 455.428 1366.01 444.385C1366.01 433.342 1375.11 424.237 1386.16 424.237C1397.39 424.237 1406.3 433.342 1406.3 444.385Z"
          fill="white"
        />
        <path
          d="M1310.6 457.171H1336.56C1330.75 490.106 1301.88 515.097 1267.01 515.097C1228.26 515.097 1196.49 483.325 1196.49 444.385C1196.49 405.638 1228.26 373.866 1267.01 373.866C1301.69 373.866 1330.75 398.858 1336.56 431.792H1310.6C1305.18 413 1287.55 399.052 1267.01 399.052C1242.21 399.052 1221.68 419.393 1221.68 444.385C1221.68 469.376 1242.02 489.912 1267.01 489.912C1287.55 489.912 1305.18 475.963 1310.6 457.171Z"
          fill="white"
        />
        <path
          d="M1430.33 344.421H1456.29C1450.47 377.355 1421.61 402.347 1386.74 402.347C1347.99 402.347 1316.22 370.574 1316.22 331.634C1316.22 292.888 1347.99 261.116 1386.74 261.116C1421.42 261.116 1450.47 286.107 1456.29 319.042H1430.33C1424.9 300.25 1407.27 286.301 1386.74 286.301C1361.94 286.301 1341.4 306.643 1341.4 331.634C1341.4 356.626 1361.75 377.161 1386.74 377.161C1407.27 377.161 1424.71 363.213 1430.33 344.421Z"
          fill="white"
        />
        <path
          d="M1286.58 331.826C1286.58 342.869 1277.67 351.975 1266.43 351.975C1255.39 351.975 1246.28 342.869 1246.28 331.826C1246.28 320.784 1255.39 311.678 1266.43 311.678C1277.67 311.484 1286.58 320.59 1286.58 331.826Z"
          fill="white"
        />
        <path
          d="M1190.87 344.421H1216.83C1211.02 377.355 1182.16 402.347 1147.28 402.347C1108.54 402.347 1076.77 370.574 1076.77 331.634C1076.77 292.888 1108.54 261.116 1147.28 261.116C1181.96 261.116 1211.02 286.107 1216.83 319.042H1190.87C1185.45 300.25 1167.82 286.301 1147.28 286.301C1122.49 286.301 1101.95 306.643 1101.95 331.634C1101.95 356.626 1122.29 377.161 1147.28 377.161C1167.82 377.161 1185.45 363.213 1190.87 344.421Z"
          fill="white"
        />
        <path
          d="M1430.33 344.421H1456.29C1450.47 377.355 1421.61 402.347 1386.74 402.347C1347.99 402.347 1316.22 370.574 1316.22 331.634C1316.22 292.888 1347.99 261.116 1386.74 261.116C1421.42 261.116 1450.47 286.107 1456.29 319.042H1430.33C1424.9 300.25 1407.27 286.301 1386.74 286.301C1361.94 286.301 1341.4 306.643 1341.4 331.634C1341.4 356.626 1361.75 377.161 1386.74 377.161C1407.27 377.161 1424.71 363.213 1430.33 344.421Z"
          fill="white"
        />
        <path
          d="M1286.58 331.83C1286.58 342.873 1277.67 351.978 1266.43 351.978C1255.39 351.978 1246.28 342.873 1246.28 331.83C1246.28 320.787 1255.39 311.682 1266.43 311.682C1277.67 311.488 1286.58 320.594 1286.58 331.83Z"
          fill="white"
        />
        <path
          d="M1190.87 344.421H1216.83C1211.02 377.355 1182.16 402.347 1147.28 402.347C1108.54 402.347 1076.77 370.574 1076.77 331.634C1076.77 292.888 1108.54 261.116 1147.28 261.116C1181.96 261.116 1211.02 286.107 1216.83 319.042H1190.87C1185.45 300.25 1167.82 286.301 1147.28 286.301C1122.49 286.301 1101.95 306.643 1101.95 331.634C1101.95 356.626 1122.29 377.161 1147.28 377.161C1167.82 377.161 1185.45 363.213 1190.87 344.421Z"
          fill="white"
        />
        <path
          d="M1406.3 219.076C1406.3 230.119 1397.39 239.225 1386.16 239.225C1375.11 239.225 1366.01 230.119 1366.01 219.076C1366.01 208.034 1375.11 198.928 1386.16 198.928C1397.39 198.928 1406.3 208.034 1406.3 219.076Z"
          fill="white"
        />
        <path
          d="M1310.6 231.863H1336.56C1330.75 264.797 1301.88 289.789 1267.01 289.789C1228.26 289.789 1196.49 258.017 1196.49 219.076C1196.49 180.33 1228.26 148.558 1267.01 148.558C1301.69 148.558 1330.75 173.549 1336.56 206.484H1310.6C1305.18 187.692 1287.55 173.743 1267.01 173.743C1242.21 173.743 1221.68 194.085 1221.68 219.076C1221.68 244.068 1242.02 264.603 1267.01 264.603C1287.55 264.603 1305.18 250.655 1310.6 231.863Z"
          fill="white"
        />
        <path
          d="M1430.33 119.305H1456.29C1450.47 152.239 1421.61 177.231 1386.74 177.231C1347.99 177.231 1316.22 145.459 1316.22 106.519C1316.22 67.7721 1347.99 36 1386.74 36C1421.42 36 1450.47 60.9915 1456.29 93.926H1430.33C1424.9 75.1339 1407.27 61.1852 1386.74 61.1852C1361.94 61.1852 1341.4 81.5271 1341.4 106.519C1341.4 131.51 1361.75 152.046 1386.74 152.046C1407.27 152.046 1424.71 138.097 1430.33 119.305Z"
          fill="white"
        />
        <path
          d="M1286.58 106.519C1286.58 117.561 1277.67 126.667 1266.43 126.667C1255.39 126.667 1246.28 117.561 1246.28 106.519C1246.28 95.4759 1255.39 86.3704 1266.43 86.3704C1277.67 86.3704 1286.58 95.4759 1286.58 106.519Z"
          fill="white"
        />
        <path
          d="M1190.87 119.305H1216.83C1211.02 152.239 1182.16 177.231 1147.28 177.231C1108.54 177.231 1076.77 145.459 1076.77 106.519C1076.77 67.7721 1108.54 36 1147.28 36C1181.96 36 1211.02 60.9915 1216.83 93.926H1190.87C1185.45 75.1339 1167.82 61.1852 1147.28 61.1852C1122.49 61.1852 1101.95 81.5271 1101.95 106.519C1101.95 131.51 1122.29 152.046 1147.28 152.046C1167.82 152.046 1185.45 138.097 1190.87 119.305Z"
          fill="white"
        />
        <path
          d="M1167.04 1345.24C1167.04 1356.29 1158.13 1365.39 1146.9 1365.39C1135.85 1365.39 1126.75 1356.29 1126.75 1345.24C1126.75 1334.2 1135.85 1325.1 1146.9 1325.1C1158.13 1325.1 1167.04 1334.2 1167.04 1345.24Z"
          fill="white"
        />
        <path
          d="M1190.87 1245.47H1216.83C1211.02 1278.41 1182.16 1303.4 1147.28 1303.4C1108.54 1303.4 1076.77 1271.63 1076.77 1232.69C1076.77 1193.94 1108.54 1162.17 1147.28 1162.17C1181.96 1162.17 1211.02 1187.16 1216.83 1220.09H1190.87C1185.45 1201.3 1167.82 1187.35 1147.28 1187.35C1122.49 1187.35 1101.95 1207.7 1101.95 1232.69C1101.95 1257.68 1122.29 1278.21 1147.28 1278.21C1167.82 1278.21 1185.45 1264.27 1190.87 1245.47Z"
          fill="white"
        />
        <path
          d="M1047.32 1232.69C1047.32 1243.73 1038.41 1252.83 1027.17 1252.83C1016.13 1252.83 1007.02 1243.73 1007.02 1232.69C1007.02 1221.64 1016.13 1212.54 1027.17 1212.54C1038.41 1212.54 1047.32 1221.64 1047.32 1232.69Z"
          fill="white"
        />
        <path
          d="M951.614 1245.47H977.574C971.762 1278.41 942.896 1303.4 908.024 1303.4C869.277 1303.4 837.505 1271.63 837.505 1232.69C837.505 1193.94 869.277 1162.17 908.024 1162.17C942.702 1162.17 971.762 1187.16 977.574 1220.09H951.614C946.189 1201.3 928.559 1187.35 908.024 1187.35C883.226 1187.35 862.69 1207.7 862.69 1232.69C862.69 1257.68 883.032 1278.21 908.024 1278.21C928.559 1278.21 946.189 1264.27 951.614 1245.47Z"
          fill="white"
        />
        <path
          d="M1190.87 1245.47H1216.83C1211.02 1278.41 1182.16 1303.4 1147.28 1303.4C1108.54 1303.4 1076.77 1271.63 1076.77 1232.69C1076.77 1193.94 1108.54 1162.17 1147.28 1162.17C1181.96 1162.17 1211.02 1187.16 1216.83 1220.09H1190.87C1185.45 1201.3 1167.82 1187.35 1147.28 1187.35C1122.49 1187.35 1101.95 1207.7 1101.95 1232.69C1101.95 1257.68 1122.29 1278.21 1147.28 1278.21C1167.82 1278.21 1185.45 1264.27 1190.87 1245.47Z"
          fill="white"
        />
        <path
          d="M1047.32 1232.69C1047.32 1243.73 1038.41 1252.83 1027.17 1252.83C1016.13 1252.83 1007.02 1243.73 1007.02 1232.69C1007.02 1221.64 1016.13 1212.54 1027.17 1212.54C1038.41 1212.54 1047.32 1221.64 1047.32 1232.69Z"
          fill="white"
        />
        <path
          d="M951.614 1245.47H977.574C971.762 1278.41 942.896 1303.4 908.024 1303.4C869.277 1303.4 837.505 1271.63 837.505 1232.69C837.505 1193.94 869.277 1162.17 908.024 1162.17C942.702 1162.17 971.762 1187.16 977.574 1220.09H951.614C946.189 1201.3 928.559 1187.35 908.024 1187.35C883.226 1187.35 862.69 1207.7 862.69 1232.69C862.69 1257.68 883.032 1278.21 908.024 1278.21C928.559 1278.21 946.189 1264.27 951.614 1245.47Z"
          fill="white"
        />
        <path
          d="M1167.04 1120.12C1167.04 1131.17 1158.13 1140.27 1146.9 1140.27C1135.85 1140.27 1126.75 1131.17 1126.75 1120.12C1126.75 1109.08 1135.85 1099.98 1146.9 1099.98C1158.13 1099.98 1167.04 1108.89 1167.04 1120.12Z"
          fill="white"
        />
        <path
          d="M1071.34 1132.91H1097.3C1091.49 1165.85 1062.62 1190.84 1027.75 1190.84C989.004 1190.84 957.232 1159.07 957.232 1120.12C957.232 1081.38 989.004 1049.61 1027.75 1049.61C1062.43 1049.61 1091.49 1074.6 1097.3 1107.53H1071.34C1065.92 1088.74 1048.29 1074.79 1027.75 1074.79C1002.95 1074.79 982.417 1095.13 982.417 1120.12C982.417 1145.12 1002.76 1165.65 1027.75 1165.65C1048.29 1165.65 1065.92 1151.7 1071.34 1132.91Z"
          fill="white"
        />
        <path
          d="M1190.87 1020.16H1216.83C1211.02 1053.1 1182.16 1078.09 1147.28 1078.09C1108.54 1078.09 1076.77 1046.31 1076.77 1007.37C1076.77 968.628 1108.54 936.856 1147.28 936.856C1181.96 936.856 1211.02 961.847 1216.83 994.782H1190.87C1185.45 975.99 1167.82 962.041 1147.28 962.041C1122.49 962.041 1101.95 982.383 1101.95 1007.37C1101.95 1032.37 1122.29 1052.9 1147.28 1052.9C1167.82 1052.9 1185.45 1038.95 1190.87 1020.16Z"
          fill="white"
        />
        <path
          d="M1047.32 1007.57C1047.32 1018.61 1038.41 1027.71 1027.17 1027.71C1016.13 1027.71 1007.02 1018.61 1007.02 1007.57C1007.02 996.524 1016.13 987.418 1027.17 987.418C1038.41 987.225 1047.32 996.33 1047.32 1007.57Z"
          fill="white"
        />
        <path
          d="M951.614 1020.16H977.574C971.762 1053.1 942.896 1078.09 908.024 1078.09C869.277 1078.09 837.505 1046.31 837.505 1007.37C837.505 968.628 869.277 936.856 908.024 936.856C942.702 936.856 971.762 961.847 977.574 994.782H951.614C946.189 975.99 928.559 962.041 908.024 962.041C883.226 962.041 862.69 982.383 862.69 1007.37C862.69 1032.37 883.032 1052.9 908.024 1052.9C928.559 1052.9 946.189 1038.95 951.614 1020.16Z"
          fill="white"
        />
        <path
          d="M1190.87 1020.16H1216.83C1211.02 1053.1 1182.16 1078.09 1147.28 1078.09C1108.54 1078.09 1076.77 1046.31 1076.77 1007.37C1076.77 968.628 1108.54 936.856 1147.28 936.856C1181.96 936.856 1211.02 961.847 1216.83 994.782H1190.87C1185.45 975.99 1167.82 962.041 1147.28 962.041C1122.49 962.041 1101.95 982.383 1101.95 1007.37C1101.95 1032.37 1122.29 1052.9 1147.28 1052.9C1167.82 1052.9 1185.45 1038.95 1190.87 1020.16Z"
          fill="white"
        />
        <path
          d="M1047.32 1007.57C1047.32 1018.61 1038.41 1027.71 1027.17 1027.71C1016.13 1027.71 1007.02 1018.61 1007.02 1007.57C1007.02 996.524 1016.13 987.418 1027.17 987.418C1038.41 987.225 1047.32 996.33 1047.32 1007.57Z"
          fill="white"
        />
        <path
          d="M951.614 1020.16H977.574C971.762 1053.1 942.896 1078.09 908.024 1078.09C869.277 1078.09 837.505 1046.31 837.505 1007.37C837.505 968.628 869.277 936.856 908.024 936.856C942.702 936.856 971.762 961.847 977.574 994.782H951.614C946.189 975.99 928.559 962.041 908.024 962.041C883.226 962.041 862.69 982.383 862.69 1007.37C862.69 1032.37 883.032 1052.9 908.024 1052.9C928.559 1052.9 946.189 1038.95 951.614 1020.16Z"
          fill="white"
        />
        <path
          d="M1167.04 894.813C1167.04 905.856 1158.13 914.961 1146.9 914.961C1135.85 914.961 1126.75 905.856 1126.75 894.813C1126.75 883.77 1135.85 874.665 1146.9 874.665C1158.13 874.665 1167.04 883.77 1167.04 894.813Z"
          fill="white"
        />
        <path
          d="M1071.34 907.599H1097.3C1091.49 940.534 1062.62 965.525 1027.75 965.525C989.004 965.525 957.232 933.753 957.232 894.813C957.232 856.066 989.004 824.294 1027.75 824.294C1062.43 824.294 1091.49 849.286 1097.3 882.22H1071.34C1065.92 863.428 1048.29 849.479 1027.75 849.479C1002.95 849.479 982.417 869.821 982.417 894.813C982.417 919.804 1002.76 940.34 1027.75 940.34C1048.29 940.34 1065.92 926.391 1071.34 907.599Z"
          fill="white"
        />
        <path
          d="M1190.87 795.041H1216.83C1211.02 827.976 1182.16 852.967 1147.28 852.967C1108.54 852.967 1076.77 821.195 1076.77 782.255C1076.77 743.509 1108.54 711.736 1147.28 711.736C1181.96 711.736 1211.02 736.728 1216.83 769.662H1190.87C1185.45 750.87 1167.82 736.922 1147.28 736.922C1122.49 736.922 1101.95 757.264 1101.95 782.255C1101.95 807.247 1122.29 827.782 1147.28 827.782C1167.82 827.782 1185.45 813.833 1190.87 795.041Z"
          fill="white"
        />
        <path
          d="M1047.32 782.255C1047.32 793.298 1038.41 802.403 1027.17 802.403C1016.13 802.403 1007.02 793.298 1007.02 782.255C1007.02 771.212 1016.13 762.107 1027.17 762.107C1038.41 762.107 1047.32 771.019 1047.32 782.255Z"
          fill="white"
        />
        <path
          d="M951.614 795.041H977.574C971.762 827.976 942.896 852.967 908.024 852.967C869.277 852.967 837.505 821.195 837.505 782.255C837.505 743.509 869.277 711.736 908.024 711.736C942.702 711.736 971.762 736.728 977.574 769.662H951.614C946.189 750.87 928.559 736.922 908.024 736.922C883.226 736.922 862.69 757.264 862.69 782.255C862.69 807.247 883.032 827.782 908.024 827.782C928.559 827.782 946.189 813.833 951.614 795.041Z"
          fill="white"
        />
        <path
          d="M1190.87 795.045H1216.83C1211.02 827.98 1182.16 852.971 1147.28 852.971C1108.54 852.971 1076.77 821.199 1076.77 782.259C1076.77 743.512 1108.54 711.74 1147.28 711.74C1181.96 711.74 1211.02 736.732 1216.83 769.666H1190.87C1185.45 750.874 1167.82 736.925 1147.28 736.925C1122.49 736.925 1101.95 757.267 1101.95 782.259C1101.95 807.25 1122.29 827.786 1147.28 827.786C1167.82 827.786 1185.45 813.837 1190.87 795.045Z"
          fill="white"
        />
        <path
          d="M1047.32 782.259C1047.32 793.302 1038.41 802.407 1027.17 802.407C1016.13 802.407 1007.02 793.302 1007.02 782.259C1007.02 771.216 1016.13 762.111 1027.17 762.111C1038.41 762.111 1047.32 771.022 1047.32 782.259Z"
          fill="white"
        />
        <path
          d="M951.614 795.045H977.574C971.762 827.98 942.896 852.971 908.024 852.971C869.277 852.971 837.505 821.199 837.505 782.259C837.505 743.512 869.277 711.74 908.024 711.74C942.702 711.74 971.762 736.732 977.574 769.666H951.614C946.189 750.874 928.559 736.925 908.024 736.925C883.226 736.925 862.69 757.267 862.69 782.259C862.69 807.25 883.032 827.786 908.024 827.786C928.559 827.786 946.189 813.837 951.614 795.045Z"
          fill="white"
        />
        <path
          d="M1167.04 669.7C1167.04 680.743 1158.13 689.848 1146.9 689.848C1135.85 689.848 1126.75 680.743 1126.75 669.7C1126.75 658.657 1135.85 649.552 1146.9 649.552C1158.13 649.358 1167.04 658.464 1167.04 669.7Z"
          fill="white"
        />
        <path
          d="M1071.34 682.291H1097.3C1091.49 715.225 1062.62 740.217 1027.75 740.217C989.004 740.217 957.232 708.444 957.232 669.504C957.232 630.758 989.004 598.986 1027.75 598.986C1062.43 598.986 1091.49 623.977 1097.3 656.912H1071.34C1065.92 638.12 1048.29 624.171 1027.75 624.171C1002.95 624.171 982.417 644.513 982.417 669.504C982.417 694.496 1002.76 715.031 1027.75 715.031C1048.29 715.031 1065.92 701.083 1071.34 682.291Z"
          fill="white"
        />
        <path
          d="M1190.87 569.733H1216.83C1211.02 602.667 1182.16 627.659 1147.28 627.659C1108.54 627.659 1076.77 595.887 1076.77 556.946C1076.77 518.2 1108.54 486.428 1147.28 486.428C1181.96 486.428 1211.02 511.419 1216.83 544.354H1190.87C1185.45 525.562 1167.82 511.613 1147.28 511.613C1122.49 511.613 1101.95 531.955 1101.95 556.946C1101.95 581.938 1122.29 602.474 1147.28 602.474C1167.82 602.474 1185.45 588.525 1190.87 569.733Z"
          fill="white"
        />
        <path
          d="M1047.32 556.946C1047.32 567.989 1038.41 577.095 1027.17 577.095C1016.13 577.095 1007.02 567.989 1007.02 556.946C1007.02 545.904 1016.13 536.798 1027.17 536.798C1038.41 536.798 1047.32 545.904 1047.32 556.946Z"
          fill="white"
        />
        <path
          d="M951.614 569.733H977.574C971.762 602.667 942.896 627.659 908.024 627.659C869.277 627.659 837.505 595.887 837.505 556.946C837.505 518.2 869.277 486.428 908.024 486.428C942.702 486.428 971.762 511.419 977.574 544.354H951.614C946.189 525.562 928.559 511.613 908.024 511.613C883.226 511.613 862.69 531.955 862.69 556.946C862.69 581.938 883.032 602.474 908.024 602.474C928.559 602.474 946.189 588.525 951.614 569.733Z"
          fill="white"
        />
        <path
          d="M1190.87 569.733H1216.83C1211.02 602.667 1182.16 627.659 1147.28 627.659C1108.54 627.659 1076.77 595.887 1076.77 556.946C1076.77 518.2 1108.54 486.428 1147.28 486.428C1181.96 486.428 1211.02 511.419 1216.83 544.354H1190.87C1185.45 525.562 1167.82 511.613 1147.28 511.613C1122.49 511.613 1101.95 531.955 1101.95 556.946C1101.95 581.938 1122.29 602.474 1147.28 602.474C1167.82 602.474 1185.45 588.525 1190.87 569.733Z"
          fill="white"
        />
        <path
          d="M1047.32 556.946C1047.32 567.989 1038.41 577.095 1027.17 577.095C1016.13 577.095 1007.02 567.989 1007.02 556.946C1007.02 545.904 1016.13 536.798 1027.17 536.798C1038.41 536.798 1047.32 545.904 1047.32 556.946Z"
          fill="white"
        />
        <path
          d="M951.614 569.733H977.574C971.762 602.667 942.896 627.659 908.024 627.659C869.277 627.659 837.505 595.887 837.505 556.946C837.505 518.2 869.277 486.428 908.024 486.428C942.702 486.428 971.762 511.419 977.574 544.354H951.614C946.189 525.562 928.559 511.613 908.024 511.613C883.226 511.613 862.69 531.955 862.69 556.946C862.69 581.938 883.032 602.474 908.024 602.474C928.559 602.474 946.189 588.525 951.614 569.733Z"
          fill="white"
        />
        <path
          d="M1167.04 444.385C1167.04 455.428 1158.13 464.533 1146.9 464.533C1135.85 464.533 1126.75 455.428 1126.75 444.385C1126.75 433.342 1135.85 424.237 1146.9 424.237C1158.13 424.237 1167.04 433.342 1167.04 444.385Z"
          fill="white"
        />
        <path
          d="M1071.34 457.171H1097.3C1091.49 490.106 1062.62 515.097 1027.75 515.097C989.004 515.097 957.232 483.325 957.232 444.385C957.232 405.638 989.004 373.866 1027.75 373.866C1062.43 373.866 1091.49 398.858 1097.3 431.792H1071.34C1065.92 413 1048.29 399.052 1027.75 399.052C1002.95 399.052 982.417 419.393 982.417 444.385C982.417 469.376 1002.76 489.912 1027.75 489.912C1048.29 489.912 1065.92 475.963 1071.34 457.171Z"
          fill="white"
        />
        <path
          d="M1190.87 344.421H1216.83C1211.02 377.355 1182.16 402.347 1147.28 402.347C1108.54 402.347 1076.77 370.574 1076.77 331.634C1076.77 292.888 1108.54 261.116 1147.28 261.116C1181.96 261.116 1211.02 286.107 1216.83 319.042H1190.87C1185.45 300.25 1167.82 286.301 1147.28 286.301C1122.49 286.301 1101.95 306.643 1101.95 331.634C1101.95 356.626 1122.29 377.161 1147.28 377.161C1167.82 377.161 1185.45 363.213 1190.87 344.421Z"
          fill="white"
        />
        <path
          d="M1047.32 331.826C1047.32 342.869 1038.41 351.975 1027.17 351.975C1016.13 351.975 1007.02 342.869 1007.02 331.826C1007.02 320.784 1016.13 311.678 1027.17 311.678C1038.41 311.484 1047.32 320.59 1047.32 331.826Z"
          fill="white"
        />
        <path
          d="M951.614 344.421H977.574C971.762 377.355 942.896 402.347 908.024 402.347C869.277 402.347 837.505 370.574 837.505 331.634C837.505 292.888 869.277 261.116 908.024 261.116C942.702 261.116 971.762 286.107 977.574 319.042H951.614C946.189 300.25 928.559 286.301 908.024 286.301C883.226 286.301 862.69 306.643 862.69 331.634C862.69 356.626 883.032 377.161 908.024 377.161C928.559 377.161 946.189 363.213 951.614 344.421Z"
          fill="white"
        />
        <path
          d="M1190.87 344.421H1216.83C1211.02 377.355 1182.16 402.347 1147.28 402.347C1108.54 402.347 1076.77 370.574 1076.77 331.634C1076.77 292.888 1108.54 261.116 1147.28 261.116C1181.96 261.116 1211.02 286.107 1216.83 319.042H1190.87C1185.45 300.25 1167.82 286.301 1147.28 286.301C1122.49 286.301 1101.95 306.643 1101.95 331.634C1101.95 356.626 1122.29 377.161 1147.28 377.161C1167.82 377.161 1185.45 363.213 1190.87 344.421Z"
          fill="white"
        />
        <path
          d="M1047.32 331.83C1047.32 342.873 1038.41 351.978 1027.17 351.978C1016.13 351.978 1007.02 342.873 1007.02 331.83C1007.02 320.787 1016.13 311.682 1027.17 311.682C1038.41 311.488 1047.32 320.594 1047.32 331.83Z"
          fill="white"
        />
        <path
          d="M951.614 344.421H977.574C971.762 377.355 942.896 402.347 908.024 402.347C869.277 402.347 837.505 370.574 837.505 331.634C837.505 292.888 869.277 261.116 908.024 261.116C942.702 261.116 971.762 286.107 977.574 319.042H951.614C946.189 300.25 928.559 286.301 908.024 286.301C883.226 286.301 862.69 306.643 862.69 331.634C862.69 356.626 883.032 377.161 908.024 377.161C928.559 377.161 946.189 363.213 951.614 344.421Z"
          fill="white"
        />
        <path
          d="M1167.04 219.076C1167.04 230.119 1158.13 239.225 1146.9 239.225C1135.85 239.225 1126.75 230.119 1126.75 219.076C1126.75 208.034 1135.85 198.928 1146.9 198.928C1158.13 198.928 1167.04 208.034 1167.04 219.076Z"
          fill="white"
        />
        <path
          d="M1071.34 231.863H1097.3C1091.49 264.797 1062.62 289.789 1027.75 289.789C989.004 289.789 957.232 258.017 957.232 219.076C957.232 180.33 989.004 148.558 1027.75 148.558C1062.43 148.558 1091.49 173.549 1097.3 206.484H1071.34C1065.92 187.692 1048.29 173.743 1027.75 173.743C1002.95 173.743 982.417 194.085 982.417 219.076C982.417 244.068 1002.76 264.603 1027.75 264.603C1048.29 264.603 1065.92 250.655 1071.34 231.863Z"
          fill="white"
        />
        <path
          d="M1190.87 119.305H1216.83C1211.02 152.239 1182.16 177.231 1147.28 177.231C1108.54 177.231 1076.77 145.459 1076.77 106.519C1076.77 67.7721 1108.54 36 1147.28 36C1181.96 36 1211.02 60.9915 1216.83 93.926H1190.87C1185.45 75.1339 1167.82 61.1852 1147.28 61.1852C1122.49 61.1852 1101.95 81.5271 1101.95 106.519C1101.95 131.51 1122.29 152.046 1147.28 152.046C1167.82 152.046 1185.45 138.097 1190.87 119.305Z"
          fill="white"
        />
        <path
          d="M1047.32 106.519C1047.32 117.561 1038.41 126.667 1027.17 126.667C1016.13 126.667 1007.02 117.561 1007.02 106.519C1007.02 95.4759 1016.13 86.3704 1027.17 86.3704C1038.41 86.3704 1047.32 95.4759 1047.32 106.519Z"
          fill="white"
        />
        <path
          d="M951.614 119.305H977.574C971.762 152.239 942.896 177.231 908.024 177.231C869.277 177.231 837.505 145.459 837.505 106.519C837.505 67.7721 869.277 36 908.024 36C942.702 36 971.762 60.9915 977.574 93.926H951.614C946.189 75.1339 928.559 61.1852 908.024 61.1852C883.226 61.1852 862.69 81.5271 862.69 106.519C862.69 131.51 883.032 152.046 908.024 152.046C928.559 152.046 946.189 138.097 951.614 119.305Z"
          fill="white"
        />
        <path
          d="M927.591 1345.24C927.591 1356.29 918.679 1365.39 907.443 1365.39C896.4 1365.39 887.295 1356.29 887.295 1345.24C887.295 1334.2 896.4 1325.1 907.443 1325.1C918.679 1325.1 927.591 1334.2 927.591 1345.24Z"
          fill="white"
        />
        <path
          d="M951.614 1245.47H977.574C971.762 1278.41 942.896 1303.4 908.024 1303.4C869.277 1303.4 837.505 1271.63 837.505 1232.69C837.505 1193.94 869.277 1162.17 908.024 1162.17C942.702 1162.17 971.762 1187.16 977.574 1220.09H951.614C946.189 1201.3 928.559 1187.35 908.024 1187.35C883.226 1187.35 862.69 1207.7 862.69 1232.69C862.69 1257.68 883.032 1278.21 908.024 1278.21C928.559 1278.21 946.189 1264.27 951.614 1245.47Z"
          fill="white"
        />
        <path
          d="M808.058 1232.69C808.058 1243.73 799.146 1252.83 787.91 1252.83C776.867 1252.83 767.762 1243.73 767.762 1232.69C767.762 1221.64 776.867 1212.54 787.91 1212.54C799.146 1212.54 808.058 1221.64 808.058 1232.69Z"
          fill="white"
        />
        <path
          d="M712.354 1245.47H738.314C732.502 1278.41 703.636 1303.4 668.764 1303.4C630.018 1303.4 598.246 1271.63 598.246 1232.69C598.246 1193.94 630.018 1162.17 668.764 1162.17C703.442 1162.17 732.502 1187.16 738.314 1220.09H712.354C706.93 1201.3 689.3 1187.35 668.764 1187.35C643.966 1187.35 623.431 1207.7 623.431 1232.69C623.431 1257.68 643.773 1278.21 668.764 1278.21C689.3 1278.21 706.93 1264.27 712.354 1245.47Z"
          fill="white"
        />
        <path
          d="M951.614 1245.47H977.574C971.762 1278.41 942.896 1303.4 908.024 1303.4C869.277 1303.4 837.505 1271.63 837.505 1232.69C837.505 1193.94 869.277 1162.17 908.024 1162.17C942.702 1162.17 971.762 1187.16 977.574 1220.09H951.614C946.189 1201.3 928.559 1187.35 908.024 1187.35C883.226 1187.35 862.69 1207.7 862.69 1232.69C862.69 1257.68 883.032 1278.21 908.024 1278.21C928.559 1278.21 946.189 1264.27 951.614 1245.47Z"
          fill="white"
        />
        <path
          d="M808.058 1232.69C808.058 1243.73 799.146 1252.83 787.91 1252.83C776.867 1252.83 767.762 1243.73 767.762 1232.69C767.762 1221.64 776.867 1212.54 787.91 1212.54C799.146 1212.54 808.058 1221.64 808.058 1232.69Z"
          fill="white"
        />
        <path
          d="M712.354 1245.47H738.314C732.502 1278.41 703.636 1303.4 668.764 1303.4C630.018 1303.4 598.246 1271.63 598.246 1232.69C598.246 1193.94 630.018 1162.17 668.764 1162.17C703.442 1162.17 732.502 1187.16 738.314 1220.09H712.354C706.93 1201.3 689.3 1187.35 668.764 1187.35C643.966 1187.35 623.431 1207.7 623.431 1232.69C623.431 1257.68 643.773 1278.21 668.764 1278.21C689.3 1278.21 706.93 1264.27 712.354 1245.47Z"
          fill="white"
        />
        <path
          d="M927.591 1120.12C927.591 1131.17 918.679 1140.27 907.443 1140.27C896.4 1140.27 887.295 1131.17 887.295 1120.12C887.295 1109.08 896.4 1099.98 907.443 1099.98C918.679 1099.98 927.591 1108.89 927.591 1120.12Z"
          fill="white"
        />
        <path
          d="M831.887 1132.91H857.848C852.036 1165.85 823.17 1190.84 788.298 1190.84C749.551 1190.84 717.779 1159.07 717.779 1120.12C717.779 1081.38 749.551 1049.61 788.298 1049.61C822.976 1049.61 852.036 1074.6 857.848 1107.53H831.887C826.463 1088.74 808.833 1074.79 788.298 1074.79C763.5 1074.79 742.964 1095.13 742.964 1120.12C742.964 1145.12 763.306 1165.65 788.298 1165.65C809.027 1165.65 826.463 1151.7 831.887 1132.91Z"
          fill="white"
        />
        <path
          d="M951.614 1020.16H977.574C971.762 1053.1 942.896 1078.09 908.024 1078.09C869.277 1078.09 837.505 1046.31 837.505 1007.37C837.505 968.628 869.277 936.856 908.024 936.856C942.702 936.856 971.762 961.847 977.574 994.782H951.614C946.189 975.99 928.559 962.041 908.024 962.041C883.226 962.041 862.69 982.383 862.69 1007.37C862.69 1032.37 883.032 1052.9 908.024 1052.9C928.559 1052.9 946.189 1038.95 951.614 1020.16Z"
          fill="white"
        />
        <path
          d="M808.058 1007.57C808.058 1018.61 799.146 1027.71 787.91 1027.71C776.867 1027.71 767.762 1018.61 767.762 1007.57C767.762 996.524 776.867 987.418 787.91 987.418C799.146 987.225 808.058 996.33 808.058 1007.57Z"
          fill="white"
        />
        <path
          d="M712.354 1020.16H738.314C732.502 1053.1 703.636 1078.09 668.764 1078.09C630.018 1078.09 598.246 1046.31 598.246 1007.37C598.246 968.628 630.018 936.856 668.764 936.856C703.442 936.856 732.502 961.847 738.314 994.782H712.354C706.93 975.99 689.3 962.041 668.764 962.041C643.966 962.041 623.431 982.383 623.431 1007.37C623.431 1032.37 643.773 1052.9 668.764 1052.9C689.3 1052.9 706.93 1038.95 712.354 1020.16Z"
          fill="white"
        />
        <path
          d="M951.614 1020.16H977.574C971.762 1053.1 942.896 1078.09 908.024 1078.09C869.277 1078.09 837.505 1046.31 837.505 1007.37C837.505 968.628 869.277 936.856 908.024 936.856C942.702 936.856 971.762 961.847 977.574 994.782H951.614C946.189 975.99 928.559 962.041 908.024 962.041C883.226 962.041 862.69 982.383 862.69 1007.37C862.69 1032.37 883.032 1052.9 908.024 1052.9C928.559 1052.9 946.189 1038.95 951.614 1020.16Z"
          fill="white"
        />
        <path
          d="M808.058 1007.57C808.058 1018.61 799.146 1027.71 787.91 1027.71C776.867 1027.71 767.762 1018.61 767.762 1007.57C767.762 996.524 776.867 987.418 787.91 987.418C799.146 987.225 808.058 996.33 808.058 1007.57Z"
          fill="white"
        />
        <path
          d="M712.354 1020.16H738.314C732.502 1053.1 703.636 1078.09 668.764 1078.09C630.018 1078.09 598.246 1046.31 598.246 1007.37C598.246 968.628 630.018 936.856 668.764 936.856C703.442 936.856 732.502 961.847 738.314 994.782H712.354C706.93 975.99 689.3 962.041 668.764 962.041C643.966 962.041 623.431 982.383 623.431 1007.37C623.431 1032.37 643.773 1052.9 668.764 1052.9C689.3 1052.9 706.93 1038.95 712.354 1020.16Z"
          fill="white"
        />
        <path
          d="M927.591 894.813C927.591 905.856 918.679 914.961 907.443 914.961C896.4 914.961 887.295 905.856 887.295 894.813C887.295 883.77 896.4 874.665 907.443 874.665C918.679 874.665 927.591 883.77 927.591 894.813Z"
          fill="white"
        />
        <path
          d="M831.887 907.599H857.848C852.036 940.534 823.17 965.525 788.298 965.525C749.551 965.525 717.779 933.753 717.779 894.813C717.779 856.066 749.551 824.294 788.298 824.294C822.976 824.294 852.036 849.286 857.848 882.22H831.887C826.463 863.428 808.833 849.479 788.298 849.479C763.5 849.479 742.964 869.821 742.964 894.813C742.964 919.804 763.306 940.34 788.298 940.34C809.027 940.34 826.463 926.391 831.887 907.599Z"
          fill="white"
        />
        <path
          d="M951.614 795.041H977.574C971.762 827.976 942.896 852.967 908.024 852.967C869.277 852.967 837.505 821.195 837.505 782.255C837.505 743.509 869.277 711.736 908.024 711.736C942.702 711.736 971.762 736.728 977.574 769.662H951.614C946.189 750.87 928.559 736.922 908.024 736.922C883.226 736.922 862.69 757.264 862.69 782.255C862.69 807.247 883.032 827.782 908.024 827.782C928.559 827.782 946.189 813.833 951.614 795.041Z"
          fill="white"
        />
        <path
          d="M808.058 782.255C808.058 793.298 799.146 802.403 787.91 802.403C776.867 802.403 767.762 793.298 767.762 782.255C767.762 771.212 776.867 762.107 787.91 762.107C799.146 762.107 808.058 771.019 808.058 782.255Z"
          fill="white"
        />
        <path
          d="M712.354 795.041H738.314C732.502 827.976 703.636 852.967 668.764 852.967C630.018 852.967 598.246 821.195 598.246 782.255C598.246 743.509 630.018 711.736 668.764 711.736C703.442 711.736 732.502 736.728 738.314 769.662H712.354C706.93 750.87 689.3 736.922 668.764 736.922C643.966 736.922 623.431 757.264 623.431 782.255C623.431 807.247 643.773 827.782 668.764 827.782C689.3 827.782 706.93 813.833 712.354 795.041Z"
          fill="white"
        />
        <path
          d="M951.614 795.045H977.574C971.762 827.98 942.896 852.971 908.024 852.971C869.277 852.971 837.505 821.199 837.505 782.259C837.505 743.512 869.277 711.74 908.024 711.74C942.702 711.74 971.762 736.732 977.574 769.666H951.614C946.189 750.874 928.559 736.925 908.024 736.925C883.226 736.925 862.69 757.267 862.69 782.259C862.69 807.25 883.032 827.786 908.024 827.786C928.559 827.786 946.189 813.837 951.614 795.045Z"
          fill="white"
        />
        <path
          d="M808.058 782.259C808.058 793.302 799.146 802.407 787.91 802.407C776.867 802.407 767.762 793.302 767.762 782.259C767.762 771.216 776.867 762.111 787.91 762.111C799.146 762.111 808.058 771.022 808.058 782.259Z"
          fill="white"
        />
        <path
          d="M712.354 795.045H738.314C732.502 827.98 703.636 852.971 668.764 852.971C630.018 852.971 598.246 821.199 598.246 782.259C598.246 743.512 630.018 711.74 668.764 711.74C703.442 711.74 732.502 736.732 738.314 769.666H712.354C706.93 750.874 689.3 736.925 668.764 736.925C643.966 736.925 623.431 757.267 623.431 782.259C623.431 807.25 643.773 827.786 668.764 827.786C689.3 827.786 706.93 813.837 712.354 795.045Z"
          fill="white"
        />
        <path
          d="M927.591 669.7C927.591 680.743 918.679 689.848 907.443 689.848C896.4 689.848 887.295 680.743 887.295 669.7C887.295 658.657 896.4 649.552 907.443 649.552C918.679 649.358 927.591 658.464 927.591 669.7Z"
          fill="white"
        />
        <path
          d="M831.887 682.291H857.848C852.036 715.225 823.17 740.217 788.298 740.217C749.551 740.217 717.779 708.444 717.779 669.504C717.779 630.758 749.551 598.986 788.298 598.986C822.976 598.986 852.036 623.977 857.848 656.912H831.887C826.463 638.12 808.833 624.171 788.298 624.171C763.5 624.171 742.964 644.513 742.964 669.504C742.964 694.496 763.306 715.031 788.298 715.031C809.027 715.031 826.463 701.083 831.887 682.291Z"
          fill="white"
        />
        <path
          d="M951.614 569.733H977.574C971.762 602.667 942.896 627.659 908.024 627.659C869.277 627.659 837.505 595.887 837.505 556.946C837.505 518.2 869.277 486.428 908.024 486.428C942.702 486.428 971.762 511.419 977.574 544.354H951.614C946.189 525.562 928.559 511.613 908.024 511.613C883.226 511.613 862.69 531.955 862.69 556.946C862.69 581.938 883.032 602.474 908.024 602.474C928.559 602.474 946.189 588.525 951.614 569.733Z"
          fill="white"
        />
        <path
          d="M808.058 556.946C808.058 567.989 799.146 577.095 787.91 577.095C776.867 577.095 767.762 567.989 767.762 556.946C767.762 545.904 776.867 536.798 787.91 536.798C799.146 536.798 808.058 545.904 808.058 556.946Z"
          fill="white"
        />
        <path
          d="M712.354 569.733H738.314C732.502 602.667 703.636 627.659 668.764 627.659C630.018 627.659 598.246 595.887 598.246 556.946C598.246 518.2 630.018 486.428 668.764 486.428C703.442 486.428 732.502 511.419 738.314 544.354H712.354C706.93 525.562 689.3 511.613 668.764 511.613C643.966 511.613 623.431 531.955 623.431 556.946C623.431 581.938 643.773 602.474 668.764 602.474C689.3 602.474 706.93 588.525 712.354 569.733Z"
          fill="white"
        />
        <path
          d="M951.614 569.733H977.574C971.762 602.667 942.896 627.659 908.024 627.659C869.277 627.659 837.505 595.887 837.505 556.946C837.505 518.2 869.277 486.428 908.024 486.428C942.702 486.428 971.762 511.419 977.574 544.354H951.614C946.189 525.562 928.559 511.613 908.024 511.613C883.226 511.613 862.69 531.955 862.69 556.946C862.69 581.938 883.032 602.474 908.024 602.474C928.559 602.474 946.189 588.525 951.614 569.733Z"
          fill="white"
        />
        <path
          d="M808.058 556.946C808.058 567.989 799.146 577.095 787.91 577.095C776.867 577.095 767.762 567.989 767.762 556.946C767.762 545.904 776.867 536.798 787.91 536.798C799.146 536.798 808.058 545.904 808.058 556.946Z"
          fill="white"
        />
        <path
          d="M712.354 569.733H738.314C732.502 602.667 703.636 627.659 668.764 627.659C630.018 627.659 598.246 595.887 598.246 556.946C598.246 518.2 630.018 486.428 668.764 486.428C703.442 486.428 732.502 511.419 738.314 544.354H712.354C706.93 525.562 689.3 511.613 668.764 511.613C643.966 511.613 623.431 531.955 623.431 556.946C623.431 581.938 643.773 602.474 668.764 602.474C689.3 602.474 706.93 588.525 712.354 569.733Z"
          fill="white"
        />
        <path
          d="M927.591 444.385C927.591 455.428 918.679 464.533 907.443 464.533C896.4 464.533 887.295 455.428 887.295 444.385C887.295 433.342 896.4 424.237 907.443 424.237C918.679 424.237 927.591 433.342 927.591 444.385Z"
          fill="white"
        />
        <path
          d="M831.887 457.171H857.848C852.036 490.106 823.17 515.097 788.298 515.097C749.551 515.097 717.779 483.325 717.779 444.385C717.779 405.638 749.551 373.866 788.298 373.866C822.976 373.866 852.036 398.858 857.848 431.792H831.887C826.463 413 808.833 399.052 788.298 399.052C763.5 399.052 742.964 419.393 742.964 444.385C742.964 469.376 763.306 489.912 788.298 489.912C809.027 489.912 826.463 475.963 831.887 457.171Z"
          fill="white"
        />
        <path
          d="M951.614 344.421H977.574C971.762 377.355 942.896 402.347 908.024 402.347C869.277 402.347 837.505 370.574 837.505 331.634C837.505 292.888 869.277 261.116 908.024 261.116C942.702 261.116 971.762 286.107 977.574 319.042H951.614C946.189 300.25 928.559 286.301 908.024 286.301C883.226 286.301 862.69 306.643 862.69 331.634C862.69 356.626 883.032 377.161 908.024 377.161C928.559 377.161 946.189 363.213 951.614 344.421Z"
          fill="white"
        />
        <path
          d="M808.058 331.826C808.058 342.869 799.146 351.975 787.91 351.975C776.867 351.975 767.762 342.869 767.762 331.826C767.762 320.784 776.867 311.678 787.91 311.678C799.146 311.484 808.058 320.59 808.058 331.826Z"
          fill="white"
        />
        <path
          d="M712.354 344.421H738.314C732.502 377.355 703.636 402.347 668.764 402.347C630.018 402.347 598.246 370.574 598.246 331.634C598.246 292.888 630.018 261.116 668.764 261.116C703.442 261.116 732.502 286.107 738.314 319.042H712.354C706.93 300.25 689.3 286.301 668.764 286.301C643.966 286.301 623.431 306.643 623.431 331.634C623.431 356.626 643.773 377.161 668.764 377.161C689.3 377.161 706.93 363.213 712.354 344.421Z"
          fill="white"
        />
        <path
          d="M951.614 344.421H977.574C971.762 377.355 942.896 402.347 908.024 402.347C869.277 402.347 837.505 370.574 837.505 331.634C837.505 292.888 869.277 261.116 908.024 261.116C942.702 261.116 971.762 286.107 977.574 319.042H951.614C946.189 300.25 928.559 286.301 908.024 286.301C883.226 286.301 862.69 306.643 862.69 331.634C862.69 356.626 883.032 377.161 908.024 377.161C928.559 377.161 946.189 363.213 951.614 344.421Z"
          fill="white"
        />
        <path
          d="M808.058 331.83C808.058 342.873 799.146 351.978 787.91 351.978C776.867 351.978 767.762 342.873 767.762 331.83C767.762 320.787 776.867 311.682 787.91 311.682C799.146 311.488 808.058 320.594 808.058 331.83Z"
          fill="white"
        />
        <path
          d="M712.354 344.421H738.314C732.502 377.355 703.636 402.347 668.764 402.347C630.018 402.347 598.246 370.574 598.246 331.634C598.246 292.888 630.018 261.116 668.764 261.116C703.442 261.116 732.502 286.107 738.314 319.042H712.354C706.93 300.25 689.3 286.301 668.764 286.301C643.966 286.301 623.431 306.643 623.431 331.634C623.431 356.626 643.773 377.161 668.764 377.161C689.3 377.161 706.93 363.213 712.354 344.421Z"
          fill="white"
        />
        <path
          d="M927.591 219.076C927.591 230.119 918.679 239.225 907.443 239.225C896.4 239.225 887.295 230.119 887.295 219.076C887.295 208.034 896.4 198.928 907.443 198.928C918.679 198.928 927.591 208.034 927.591 219.076Z"
          fill="white"
        />
        <path
          d="M831.887 231.863H857.848C852.036 264.797 823.17 289.789 788.298 289.789C749.551 289.789 717.779 258.017 717.779 219.076C717.779 180.33 749.551 148.558 788.298 148.558C822.976 148.558 852.036 173.549 857.848 206.484H831.887C826.463 187.692 808.833 173.743 788.298 173.743C763.5 173.743 742.964 194.085 742.964 219.076C742.964 244.068 763.306 264.603 788.298 264.603C809.027 264.603 826.463 250.655 831.887 231.863Z"
          fill="white"
        />
        <path
          d="M951.614 119.305H977.574C971.762 152.239 942.896 177.231 908.024 177.231C869.277 177.231 837.505 145.459 837.505 106.519C837.505 67.7721 869.277 36 908.024 36C942.702 36 971.762 60.9915 977.574 93.926H951.614C946.189 75.1339 928.559 61.1852 908.024 61.1852C883.226 61.1852 862.69 81.5271 862.69 106.519C862.69 131.51 883.032 152.046 908.024 152.046C928.559 152.046 946.189 138.097 951.614 119.305Z"
          fill="white"
        />
        <path
          d="M808.058 106.519C808.058 117.561 799.146 126.667 787.91 126.667C776.867 126.667 767.762 117.561 767.762 106.519C767.762 95.4759 776.867 86.3704 787.91 86.3704C799.146 86.3704 808.058 95.4759 808.058 106.519Z"
          fill="white"
        />
        <path
          d="M712.354 119.305H738.314C732.502 152.239 703.636 177.231 668.764 177.231C630.018 177.231 598.246 145.459 598.246 106.519C598.246 67.7721 630.018 36 668.764 36C703.442 36 732.502 60.9915 738.314 93.926H712.354C706.93 75.1339 689.3 61.1852 668.764 61.1852C643.966 61.1852 623.431 81.5271 623.431 106.519C623.431 131.51 643.773 152.046 668.764 152.046C689.3 152.046 706.93 138.097 712.354 119.305Z"
          fill="white"
        />
        <path
          d="M688.332 1345.24C688.332 1356.29 679.42 1365.39 668.184 1365.39C657.141 1365.39 648.035 1356.29 648.035 1345.24C648.035 1334.2 657.141 1325.1 668.184 1325.1C679.42 1325.1 688.332 1334.2 688.332 1345.24Z"
          fill="white"
        />
        <path
          d="M712.354 1245.47H738.314C732.502 1278.41 703.636 1303.4 668.764 1303.4C630.018 1303.4 598.246 1271.63 598.246 1232.69C598.246 1193.94 630.018 1162.17 668.764 1162.17C703.442 1162.17 732.502 1187.16 738.314 1220.09H712.354C706.93 1201.3 689.3 1187.35 668.764 1187.35C643.966 1187.35 623.431 1207.7 623.431 1232.69C623.431 1257.68 643.773 1278.21 668.764 1278.21C689.3 1278.21 706.93 1264.27 712.354 1245.47Z"
          fill="white"
        />
        <path
          d="M568.605 1232.69C568.605 1243.73 559.693 1252.83 548.457 1252.83C537.414 1252.83 528.309 1243.73 528.309 1232.69C528.309 1221.64 537.414 1212.54 548.457 1212.54C559.887 1212.54 568.605 1221.64 568.605 1232.69Z"
          fill="white"
        />
        <path
          d="M472.901 1245.47H498.861C493.049 1278.41 464.183 1303.4 429.311 1303.4C390.565 1303.4 358.793 1271.63 358.793 1232.69C358.793 1193.94 390.565 1162.17 429.311 1162.17C463.989 1162.17 493.049 1187.16 498.861 1220.09H472.901C467.477 1201.3 449.847 1187.35 429.311 1187.35C404.513 1187.35 383.978 1207.7 383.978 1232.69C383.978 1257.68 404.32 1278.21 429.311 1278.21C450.041 1278.21 467.477 1264.27 472.901 1245.47Z"
          fill="white"
        />
        <path
          d="M712.354 1245.47H738.314C732.502 1278.41 703.636 1303.4 668.764 1303.4C630.018 1303.4 598.246 1271.63 598.246 1232.69C598.246 1193.94 630.018 1162.17 668.764 1162.17C703.442 1162.17 732.502 1187.16 738.314 1220.09H712.354C706.93 1201.3 689.3 1187.35 668.764 1187.35C643.966 1187.35 623.431 1207.7 623.431 1232.69C623.431 1257.68 643.773 1278.21 668.764 1278.21C689.3 1278.21 706.93 1264.27 712.354 1245.47Z"
          fill="white"
        />
        <path
          d="M568.605 1232.69C568.605 1243.73 559.693 1252.83 548.457 1252.83C537.414 1252.83 528.309 1243.73 528.309 1232.69C528.309 1221.64 537.414 1212.54 548.457 1212.54C559.887 1212.54 568.605 1221.64 568.605 1232.69Z"
          fill="white"
        />
        <path
          d="M472.901 1245.47H498.861C493.049 1278.41 464.183 1303.4 429.311 1303.4C390.565 1303.4 358.793 1271.63 358.793 1232.69C358.793 1193.94 390.565 1162.17 429.311 1162.17C463.989 1162.17 493.049 1187.16 498.861 1220.09H472.901C467.477 1201.3 449.847 1187.35 429.311 1187.35C404.513 1187.35 383.978 1207.7 383.978 1232.69C383.978 1257.68 404.32 1278.21 429.311 1278.21C450.041 1278.21 467.477 1264.27 472.901 1245.47Z"
          fill="white"
        />
        <path
          d="M688.332 1120.12C688.332 1131.17 679.42 1140.27 668.184 1140.27C657.141 1140.27 648.035 1131.17 648.035 1120.12C648.035 1109.08 657.141 1099.98 668.184 1099.98C679.42 1099.98 688.332 1108.89 688.332 1120.12Z"
          fill="white"
        />
        <path
          d="M592.628 1132.91H618.588C612.776 1165.85 583.91 1190.84 549.038 1190.84C510.292 1190.84 478.52 1159.07 478.52 1120.12C478.52 1081.38 510.292 1049.61 549.038 1049.61C583.716 1049.61 612.776 1074.6 618.588 1107.53H592.628C587.203 1088.74 569.574 1074.79 549.038 1074.79C524.24 1074.79 503.705 1095.13 503.705 1120.12C503.705 1145.12 524.047 1165.65 549.038 1165.65C569.574 1165.65 587.203 1151.7 592.628 1132.91Z"
          fill="white"
        />
        <path
          d="M712.354 1020.16H738.314C732.502 1053.1 703.636 1078.09 668.764 1078.09C630.018 1078.09 598.246 1046.31 598.246 1007.37C598.246 968.628 630.018 936.856 668.764 936.856C703.442 936.856 732.502 961.847 738.314 994.782H712.354C706.93 975.99 689.3 962.041 668.764 962.041C643.966 962.041 623.431 982.383 623.431 1007.37C623.431 1032.37 643.773 1052.9 668.764 1052.9C689.3 1052.9 706.93 1038.95 712.354 1020.16Z"
          fill="white"
        />
        <path
          d="M568.605 1007.57C568.605 1018.61 559.693 1027.71 548.457 1027.71C537.414 1027.71 528.309 1018.61 528.309 1007.57C528.309 996.524 537.414 987.418 548.457 987.418C559.887 987.225 568.605 996.33 568.605 1007.57Z"
          fill="white"
        />
        <path
          d="M472.901 1020.16H498.861C493.049 1053.1 464.183 1078.09 429.311 1078.09C390.565 1078.09 358.793 1046.31 358.793 1007.37C358.793 968.628 390.565 936.856 429.311 936.856C463.989 936.856 493.049 961.847 498.861 994.782H472.901C467.477 975.99 449.847 962.041 429.311 962.041C404.513 962.041 383.978 982.383 383.978 1007.37C383.978 1032.37 404.32 1052.9 429.311 1052.9C450.041 1052.9 467.477 1038.95 472.901 1020.16Z"
          fill="white"
        />
        <path
          d="M712.354 1020.16H738.314C732.502 1053.1 703.636 1078.09 668.764 1078.09C630.018 1078.09 598.246 1046.31 598.246 1007.37C598.246 968.628 630.018 936.856 668.764 936.856C703.442 936.856 732.502 961.847 738.314 994.782H712.354C706.93 975.99 689.3 962.041 668.764 962.041C643.966 962.041 623.431 982.383 623.431 1007.37C623.431 1032.37 643.773 1052.9 668.764 1052.9C689.3 1052.9 706.93 1038.95 712.354 1020.16Z"
          fill="white"
        />
        <path
          d="M568.605 1007.57C568.605 1018.61 559.693 1027.71 548.457 1027.71C537.414 1027.71 528.309 1018.61 528.309 1007.57C528.309 996.524 537.414 987.418 548.457 987.418C559.887 987.225 568.605 996.33 568.605 1007.57Z"
          fill="white"
        />
        <path
          d="M472.901 1020.16H498.861C493.049 1053.1 464.183 1078.09 429.311 1078.09C390.565 1078.09 358.793 1046.31 358.793 1007.37C358.793 968.628 390.565 936.856 429.311 936.856C463.989 936.856 493.049 961.847 498.861 994.782H472.901C467.477 975.99 449.847 962.041 429.311 962.041C404.513 962.041 383.978 982.383 383.978 1007.37C383.978 1032.37 404.32 1052.9 429.311 1052.9C450.041 1052.9 467.477 1038.95 472.901 1020.16Z"
          fill="white"
        />
        <path
          d="M688.332 894.813C688.332 905.856 679.42 914.961 668.184 914.961C657.141 914.961 648.035 905.856 648.035 894.813C648.035 883.77 657.141 874.665 668.184 874.665C679.42 874.665 688.332 883.77 688.332 894.813Z"
          fill="white"
        />
        <path
          d="M592.628 907.599H618.588C612.776 940.534 583.91 965.525 549.038 965.525C510.292 965.525 478.52 933.753 478.52 894.813C478.52 856.066 510.292 824.294 549.038 824.294C583.716 824.294 612.776 849.286 618.588 882.22H592.628C587.203 863.428 569.574 849.479 549.038 849.479C524.24 849.479 503.705 869.821 503.705 894.813C503.705 919.804 524.047 940.34 549.038 940.34C569.574 940.34 587.203 926.391 592.628 907.599Z"
          fill="white"
        />
        <path
          d="M712.354 795.041H738.314C732.502 827.976 703.636 852.967 668.764 852.967C630.018 852.967 598.246 821.195 598.246 782.255C598.246 743.509 630.018 711.736 668.764 711.736C703.442 711.736 732.502 736.728 738.314 769.662H712.354C706.93 750.87 689.3 736.922 668.764 736.922C643.966 736.922 623.431 757.264 623.431 782.255C623.431 807.247 643.773 827.782 668.764 827.782C689.3 827.782 706.93 813.833 712.354 795.041Z"
          fill="white"
        />
        <path
          d="M568.605 782.255C568.605 793.298 559.693 802.403 548.457 802.403C537.414 802.403 528.309 793.298 528.309 782.255C528.309 771.212 537.414 762.107 548.457 762.107C559.887 762.107 568.605 771.019 568.605 782.255Z"
          fill="white"
        />
        <path
          d="M472.901 795.041H498.861C493.049 827.976 464.183 852.967 429.311 852.967C390.565 852.967 358.793 821.195 358.793 782.255C358.793 743.509 390.565 711.736 429.311 711.736C463.989 711.736 493.049 736.728 498.861 769.662H472.901C467.477 750.87 449.847 736.922 429.311 736.922C404.513 736.922 383.978 757.264 383.978 782.255C383.978 807.247 404.32 827.782 429.311 827.782C450.041 827.782 467.477 813.833 472.901 795.041Z"
          fill="white"
        />
        <path
          d="M712.354 795.045H738.314C732.502 827.98 703.636 852.971 668.764 852.971C630.018 852.971 598.246 821.199 598.246 782.259C598.246 743.512 630.018 711.74 668.764 711.74C703.442 711.74 732.502 736.732 738.314 769.666H712.354C706.93 750.874 689.3 736.925 668.764 736.925C643.966 736.925 623.431 757.267 623.431 782.259C623.431 807.25 643.773 827.786 668.764 827.786C689.3 827.786 706.93 813.837 712.354 795.045Z"
          fill="white"
        />
        <path
          d="M568.605 782.259C568.605 793.302 559.693 802.407 548.457 802.407C537.414 802.407 528.309 793.302 528.309 782.259C528.309 771.216 537.414 762.111 548.457 762.111C559.887 762.111 568.605 771.022 568.605 782.259Z"
          fill="white"
        />
        <path
          d="M472.901 795.045H498.861C493.049 827.98 464.183 852.971 429.311 852.971C390.565 852.971 358.793 821.199 358.793 782.259C358.793 743.512 390.565 711.74 429.311 711.74C463.989 711.74 493.049 736.732 498.861 769.666H472.901C467.477 750.874 449.847 736.925 429.311 736.925C404.513 736.925 383.978 757.267 383.978 782.259C383.978 807.25 404.32 827.786 429.311 827.786C450.041 827.786 467.477 813.837 472.901 795.045Z"
          fill="white"
        />
        <path
          d="M688.332 669.7C688.332 680.743 679.42 689.848 668.184 689.848C657.141 689.848 648.035 680.743 648.035 669.7C648.035 658.657 657.141 649.552 668.184 649.552C679.42 649.358 688.332 658.464 688.332 669.7Z"
          fill="white"
        />
        <path
          d="M592.628 682.291H618.588C612.776 715.225 583.91 740.217 549.038 740.217C510.292 740.217 478.52 708.444 478.52 669.504C478.52 630.758 510.292 598.986 549.038 598.986C583.716 598.986 612.776 623.977 618.588 656.912H592.628C587.203 638.12 569.574 624.171 549.038 624.171C524.24 624.171 503.705 644.513 503.705 669.504C503.705 694.496 524.047 715.031 549.038 715.031C569.574 715.031 587.203 701.083 592.628 682.291Z"
          fill="white"
        />
        <path
          d="M712.354 569.733H738.314C732.502 602.667 703.636 627.659 668.764 627.659C630.018 627.659 598.246 595.887 598.246 556.946C598.246 518.2 630.018 486.428 668.764 486.428C703.442 486.428 732.502 511.419 738.314 544.354H712.354C706.93 525.562 689.3 511.613 668.764 511.613C643.966 511.613 623.431 531.955 623.431 556.946C623.431 581.938 643.773 602.474 668.764 602.474C689.3 602.474 706.93 588.525 712.354 569.733Z"
          fill="white"
        />
        <path
          d="M568.605 556.946C568.605 567.989 559.693 577.095 548.457 577.095C537.414 577.095 528.309 567.989 528.309 556.946C528.309 545.904 537.414 536.798 548.457 536.798C559.887 536.798 568.605 545.904 568.605 556.946Z"
          fill="white"
        />
        <path
          d="M472.901 569.733H498.861C493.049 602.667 464.183 627.659 429.311 627.659C390.565 627.659 358.793 595.887 358.793 556.946C358.793 518.2 390.565 486.428 429.311 486.428C463.989 486.428 493.049 511.419 498.861 544.354H472.901C467.477 525.562 449.847 511.613 429.311 511.613C404.513 511.613 383.978 531.955 383.978 556.946C383.978 581.938 404.32 602.474 429.311 602.474C450.041 602.474 467.477 588.525 472.901 569.733Z"
          fill="white"
        />
        <path
          d="M712.354 569.733H738.314C732.502 602.667 703.636 627.659 668.764 627.659C630.018 627.659 598.246 595.887 598.246 556.946C598.246 518.2 630.018 486.428 668.764 486.428C703.442 486.428 732.502 511.419 738.314 544.354H712.354C706.93 525.562 689.3 511.613 668.764 511.613C643.966 511.613 623.431 531.955 623.431 556.946C623.431 581.938 643.773 602.474 668.764 602.474C689.3 602.474 706.93 588.525 712.354 569.733Z"
          fill="white"
        />
        <path
          d="M568.605 556.946C568.605 567.989 559.693 577.095 548.457 577.095C537.414 577.095 528.309 567.989 528.309 556.946C528.309 545.904 537.414 536.798 548.457 536.798C559.887 536.798 568.605 545.904 568.605 556.946Z"
          fill="white"
        />
        <path
          d="M472.901 569.733H498.861C493.049 602.667 464.183 627.659 429.311 627.659C390.565 627.659 358.793 595.887 358.793 556.946C358.793 518.2 390.565 486.428 429.311 486.428C463.989 486.428 493.049 511.419 498.861 544.354H472.901C467.477 525.562 449.847 511.613 429.311 511.613C404.513 511.613 383.978 531.955 383.978 556.946C383.978 581.938 404.32 602.474 429.311 602.474C450.041 602.474 467.477 588.525 472.901 569.733Z"
          fill="white"
        />
        <path
          d="M688.332 444.385C688.332 455.428 679.42 464.533 668.184 464.533C657.141 464.533 648.035 455.428 648.035 444.385C648.035 433.342 657.141 424.237 668.184 424.237C679.42 424.237 688.332 433.342 688.332 444.385Z"
          fill="white"
        />
        <path
          d="M592.628 457.171H618.588C612.776 490.106 583.91 515.097 549.038 515.097C510.292 515.097 478.52 483.325 478.52 444.385C478.52 405.638 510.292 373.866 549.038 373.866C583.716 373.866 612.776 398.858 618.588 431.792H592.628C587.203 413 569.574 399.052 549.038 399.052C524.24 399.052 503.705 419.393 503.705 444.385C503.705 469.376 524.047 489.912 549.038 489.912C569.574 489.912 587.203 475.963 592.628 457.171Z"
          fill="white"
        />
        <path
          d="M712.354 344.421H738.314C732.502 377.355 703.636 402.347 668.764 402.347C630.018 402.347 598.246 370.574 598.246 331.634C598.246 292.888 630.018 261.116 668.764 261.116C703.442 261.116 732.502 286.107 738.314 319.042H712.354C706.93 300.25 689.3 286.301 668.764 286.301C643.966 286.301 623.431 306.643 623.431 331.634C623.431 356.626 643.773 377.161 668.764 377.161C689.3 377.161 706.93 363.213 712.354 344.421Z"
          fill="white"
        />
        <path
          d="M568.605 331.826C568.605 342.869 559.693 351.975 548.457 351.975C537.414 351.975 528.309 342.869 528.309 331.826C528.309 320.784 537.414 311.678 548.457 311.678C559.887 311.484 568.605 320.59 568.605 331.826Z"
          fill="white"
        />
        <path
          d="M472.901 344.421H498.861C493.049 377.355 464.183 402.347 429.311 402.347C390.565 402.347 358.793 370.574 358.793 331.634C358.793 292.888 390.565 261.116 429.311 261.116C463.989 261.116 493.049 286.107 498.861 319.042H472.901C467.477 300.25 449.847 286.301 429.311 286.301C404.513 286.301 383.978 306.643 383.978 331.634C383.978 356.626 404.32 377.161 429.311 377.161C450.041 377.161 467.477 363.213 472.901 344.421Z"
          fill="white"
        />
        <path
          d="M712.354 344.421H738.314C732.502 377.355 703.636 402.347 668.764 402.347C630.018 402.347 598.246 370.574 598.246 331.634C598.246 292.888 630.018 261.116 668.764 261.116C703.442 261.116 732.502 286.107 738.314 319.042H712.354C706.93 300.25 689.3 286.301 668.764 286.301C643.966 286.301 623.431 306.643 623.431 331.634C623.431 356.626 643.773 377.161 668.764 377.161C689.3 377.161 706.93 363.213 712.354 344.421Z"
          fill="white"
        />
        <path
          d="M568.605 331.83C568.605 342.873 559.693 351.978 548.457 351.978C537.414 351.978 528.309 342.873 528.309 331.83C528.309 320.787 537.414 311.682 548.457 311.682C559.887 311.488 568.605 320.594 568.605 331.83Z"
          fill="white"
        />
        <path
          d="M472.901 344.421H498.861C493.049 377.355 464.183 402.347 429.311 402.347C390.565 402.347 358.793 370.574 358.793 331.634C358.793 292.888 390.565 261.116 429.311 261.116C463.989 261.116 493.049 286.107 498.861 319.042H472.901C467.477 300.25 449.847 286.301 429.311 286.301C404.513 286.301 383.978 306.643 383.978 331.634C383.978 356.626 404.32 377.161 429.311 377.161C450.041 377.161 467.477 363.213 472.901 344.421Z"
          fill="white"
        />
        <path
          d="M688.332 219.076C688.332 230.119 679.42 239.225 668.184 239.225C657.141 239.225 648.035 230.119 648.035 219.076C648.035 208.034 657.141 198.928 668.184 198.928C679.42 198.928 688.332 208.034 688.332 219.076Z"
          fill="white"
        />
        <path
          d="M592.628 231.863H618.588C612.776 264.797 583.91 289.789 549.038 289.789C510.292 289.789 478.52 258.017 478.52 219.076C478.52 180.33 510.292 148.558 549.038 148.558C583.716 148.558 612.776 173.549 618.588 206.484H592.628C587.203 187.692 569.574 173.743 549.038 173.743C524.24 173.743 503.705 194.085 503.705 219.076C503.705 244.068 524.047 264.603 549.038 264.603C569.574 264.603 587.203 250.655 592.628 231.863Z"
          fill="white"
        />
        <path
          d="M712.354 119.305H738.314C732.502 152.239 703.636 177.231 668.764 177.231C630.018 177.231 598.246 145.459 598.246 106.519C598.246 67.7721 630.018 36 668.764 36C703.442 36 732.502 60.9915 738.314 93.926H712.354C706.93 75.1339 689.3 61.1852 668.764 61.1852C643.966 61.1852 623.431 81.5271 623.431 106.519C623.431 131.51 643.773 152.046 668.764 152.046C689.3 152.046 706.93 138.097 712.354 119.305Z"
          fill="white"
        />
        <path
          d="M568.605 106.519C568.605 117.561 559.693 126.667 548.457 126.667C537.414 126.667 528.309 117.561 528.309 106.519C528.309 95.4759 537.414 86.3704 548.457 86.3704C559.887 86.3704 568.605 95.4759 568.605 106.519Z"
          fill="white"
        />
        <path
          d="M472.901 119.305H498.861C493.049 152.239 464.183 177.231 429.311 177.231C390.565 177.231 358.793 145.459 358.793 106.519C358.793 67.7721 390.565 36 429.311 36C463.989 36 493.049 60.9915 498.861 93.926H472.901C467.477 75.1339 449.847 61.1852 429.311 61.1852C404.513 61.1852 383.978 81.5271 383.978 106.519C383.978 131.51 404.32 152.046 429.311 152.046C450.041 152.046 467.477 138.097 472.901 119.305Z"
          fill="white"
        />
        <path
          d="M449.072 1345.24C449.072 1356.29 440.161 1365.39 428.924 1365.39C417.881 1365.39 408.776 1356.29 408.776 1345.24C408.776 1334.2 417.881 1325.1 428.924 1325.1C440.161 1325.1 449.072 1334.2 449.072 1345.24Z"
          fill="white"
        />
        <path
          d="M472.901 1245.47H498.861C493.049 1278.41 464.183 1303.4 429.311 1303.4C390.565 1303.4 358.793 1271.63 358.793 1232.69C358.793 1193.94 390.565 1162.17 429.311 1162.17C463.989 1162.17 493.049 1187.16 498.861 1220.09H472.901C467.477 1201.3 449.847 1187.35 429.311 1187.35C404.513 1187.35 383.978 1207.7 383.978 1232.69C383.978 1257.68 404.32 1278.21 429.311 1278.21C450.041 1278.21 467.477 1264.27 472.901 1245.47Z"
          fill="white"
        />
        <path
          d="M329.345 1232.69C329.345 1243.73 320.434 1252.83 309.197 1252.83C298.154 1252.83 289.049 1243.73 289.049 1232.69C289.049 1221.64 298.154 1212.54 309.197 1212.54C320.434 1212.54 329.345 1221.64 329.345 1232.69Z"
          fill="white"
        />
        <path
          d="M233.642 1245.47H259.602C253.79 1278.41 224.924 1303.4 190.052 1303.4C151.305 1303.4 119.533 1271.63 119.533 1232.69C119.533 1193.94 151.305 1162.17 190.052 1162.17C224.73 1162.17 253.79 1187.16 259.602 1220.09H233.642C228.217 1201.3 210.587 1187.35 190.052 1187.35C165.254 1187.35 144.719 1207.7 144.719 1232.69C144.719 1257.68 165.06 1278.21 190.052 1278.21C210.587 1278.21 228.217 1264.27 233.642 1245.47Z"
          fill="white"
        />
        <path
          d="M472.901 1245.47H498.861C493.049 1278.41 464.183 1303.4 429.311 1303.4C390.565 1303.4 358.793 1271.63 358.793 1232.69C358.793 1193.94 390.565 1162.17 429.311 1162.17C463.989 1162.17 493.049 1187.16 498.861 1220.09H472.901C467.477 1201.3 449.847 1187.35 429.311 1187.35C404.513 1187.35 383.978 1207.7 383.978 1232.69C383.978 1257.68 404.32 1278.21 429.311 1278.21C450.041 1278.21 467.477 1264.27 472.901 1245.47Z"
          fill="white"
        />
        <path
          d="M329.345 1232.69C329.345 1243.73 320.434 1252.83 309.197 1252.83C298.154 1252.83 289.049 1243.73 289.049 1232.69C289.049 1221.64 298.154 1212.54 309.197 1212.54C320.434 1212.54 329.345 1221.64 329.345 1232.69Z"
          fill="white"
        />
        <path
          d="M233.642 1245.47H259.602C253.79 1278.41 224.924 1303.4 190.052 1303.4C151.305 1303.4 119.533 1271.63 119.533 1232.69C119.533 1193.94 151.305 1162.17 190.052 1162.17C224.73 1162.17 253.79 1187.16 259.602 1220.09H233.642C228.217 1201.3 210.587 1187.35 190.052 1187.35C165.254 1187.35 144.719 1207.7 144.719 1232.69C144.719 1257.68 165.06 1278.21 190.052 1278.21C210.587 1278.21 228.217 1264.27 233.642 1245.47Z"
          fill="white"
        />
        <path
          d="M449.072 1120.12C449.072 1131.17 440.161 1140.27 428.924 1140.27C417.881 1140.27 408.776 1131.17 408.776 1120.12C408.776 1109.08 417.881 1099.98 428.924 1099.98C440.161 1099.98 449.072 1108.89 449.072 1120.12Z"
          fill="white"
        />
        <path
          d="M353.369 1132.91H379.329C373.517 1165.85 344.651 1190.84 309.779 1190.84C271.032 1190.84 239.26 1159.07 239.26 1120.12C239.26 1081.38 271.032 1049.61 309.779 1049.61C344.457 1049.61 373.517 1074.6 379.329 1107.53H353.369C347.944 1088.74 330.314 1074.79 309.779 1074.79C284.981 1074.79 264.445 1095.13 264.445 1120.12C264.445 1145.12 284.787 1165.65 309.779 1165.65C330.314 1165.65 347.944 1151.7 353.369 1132.91Z"
          fill="white"
        />
        <path
          d="M472.901 1020.16H498.861C493.049 1053.1 464.183 1078.09 429.311 1078.09C390.565 1078.09 358.793 1046.31 358.793 1007.37C358.793 968.628 390.565 936.856 429.311 936.856C463.989 936.856 493.049 961.847 498.861 994.782H472.901C467.477 975.99 449.847 962.041 429.311 962.041C404.513 962.041 383.978 982.383 383.978 1007.37C383.978 1032.37 404.32 1052.9 429.311 1052.9C450.041 1052.9 467.477 1038.95 472.901 1020.16Z"
          fill="white"
        />
        <path
          d="M329.345 1007.57C329.345 1018.61 320.434 1027.71 309.197 1027.71C298.154 1027.71 289.049 1018.61 289.049 1007.57C289.049 996.524 298.154 987.418 309.197 987.418C320.434 987.225 329.345 996.33 329.345 1007.57Z"
          fill="white"
        />
        <path
          d="M233.642 1020.16H259.602C253.79 1053.1 224.924 1078.09 190.052 1078.09C151.305 1078.09 119.533 1046.31 119.533 1007.37C119.533 968.628 151.305 936.856 190.052 936.856C224.73 936.856 253.79 961.847 259.602 994.782H233.642C228.217 975.99 210.587 962.041 190.052 962.041C165.254 962.041 144.719 982.383 144.719 1007.37C144.719 1032.37 165.06 1052.9 190.052 1052.9C210.587 1052.9 228.217 1038.95 233.642 1020.16Z"
          fill="white"
        />
        <path
          d="M472.901 1020.16H498.861C493.049 1053.1 464.183 1078.09 429.311 1078.09C390.565 1078.09 358.793 1046.31 358.793 1007.37C358.793 968.628 390.565 936.856 429.311 936.856C463.989 936.856 493.049 961.847 498.861 994.782H472.901C467.477 975.99 449.847 962.041 429.311 962.041C404.513 962.041 383.978 982.383 383.978 1007.37C383.978 1032.37 404.32 1052.9 429.311 1052.9C450.041 1052.9 467.477 1038.95 472.901 1020.16Z"
          fill="white"
        />
        <path
          d="M329.345 1007.57C329.345 1018.61 320.434 1027.71 309.197 1027.71C298.154 1027.71 289.049 1018.61 289.049 1007.57C289.049 996.524 298.154 987.418 309.197 987.418C320.434 987.225 329.345 996.33 329.345 1007.57Z"
          fill="white"
        />
        <path
          d="M233.642 1020.16H259.602C253.79 1053.1 224.924 1078.09 190.052 1078.09C151.305 1078.09 119.533 1046.31 119.533 1007.37C119.533 968.628 151.305 936.856 190.052 936.856C224.73 936.856 253.79 961.847 259.602 994.782H233.642C228.217 975.99 210.587 962.041 190.052 962.041C165.254 962.041 144.719 982.383 144.719 1007.37C144.719 1032.37 165.06 1052.9 190.052 1052.9C210.587 1052.9 228.217 1038.95 233.642 1020.16Z"
          fill="white"
        />
        <path
          d="M449.072 894.813C449.072 905.856 440.161 914.961 428.924 914.961C417.881 914.961 408.776 905.856 408.776 894.813C408.776 883.77 417.881 874.665 428.924 874.665C440.161 874.665 449.072 883.77 449.072 894.813Z"
          fill="white"
        />
        <path
          d="M353.369 907.599H379.329C373.517 940.534 344.651 965.525 309.779 965.525C271.032 965.525 239.26 933.753 239.26 894.813C239.26 856.066 271.032 824.294 309.779 824.294C344.457 824.294 373.517 849.286 379.329 882.22H353.369C347.944 863.428 330.314 849.479 309.779 849.479C284.981 849.479 264.445 869.821 264.445 894.813C264.445 919.804 284.787 940.34 309.779 940.34C330.314 940.34 347.944 926.391 353.369 907.599Z"
          fill="white"
        />
        <path
          d="M472.901 795.041H498.861C493.049 827.976 464.183 852.967 429.311 852.967C390.565 852.967 358.793 821.195 358.793 782.255C358.793 743.509 390.565 711.736 429.311 711.736C463.989 711.736 493.049 736.728 498.861 769.662H472.901C467.477 750.87 449.847 736.922 429.311 736.922C404.513 736.922 383.978 757.264 383.978 782.255C383.978 807.247 404.32 827.782 429.311 827.782C450.041 827.782 467.477 813.833 472.901 795.041Z"
          fill="white"
        />
        <path
          d="M329.345 782.255C329.345 793.298 320.434 802.403 309.197 802.403C298.154 802.403 289.049 793.298 289.049 782.255C289.049 771.212 298.154 762.107 309.197 762.107C320.434 762.107 329.345 771.019 329.345 782.255Z"
          fill="white"
        />
        <path
          d="M233.642 795.041H259.602C253.79 827.976 224.924 852.967 190.052 852.967C151.305 852.967 119.533 821.195 119.533 782.255C119.533 743.509 151.305 711.736 190.052 711.736C224.73 711.736 253.79 736.728 259.602 769.662H233.642C228.217 750.87 210.587 736.922 190.052 736.922C165.254 736.922 144.719 757.264 144.719 782.255C144.719 807.247 165.06 827.782 190.052 827.782C210.587 827.782 228.217 813.833 233.642 795.041Z"
          fill="white"
        />
        <path
          d="M472.901 795.045H498.861C493.049 827.98 464.183 852.971 429.311 852.971C390.565 852.971 358.793 821.199 358.793 782.259C358.793 743.512 390.565 711.74 429.311 711.74C463.989 711.74 493.049 736.732 498.861 769.666H472.901C467.477 750.874 449.847 736.925 429.311 736.925C404.513 736.925 383.978 757.267 383.978 782.259C383.978 807.25 404.32 827.786 429.311 827.786C450.041 827.786 467.477 813.837 472.901 795.045Z"
          fill="white"
        />
        <path
          d="M329.345 782.259C329.345 793.302 320.434 802.407 309.197 802.407C298.154 802.407 289.049 793.302 289.049 782.259C289.049 771.216 298.154 762.111 309.197 762.111C320.434 762.111 329.345 771.022 329.345 782.259Z"
          fill="white"
        />
        <path
          d="M233.642 795.045H259.602C253.79 827.98 224.924 852.971 190.052 852.971C151.305 852.971 119.533 821.199 119.533 782.259C119.533 743.512 151.305 711.74 190.052 711.74C224.73 711.74 253.79 736.732 259.602 769.666H233.642C228.217 750.874 210.587 736.925 190.052 736.925C165.254 736.925 144.719 757.267 144.719 782.259C144.719 807.25 165.06 827.786 190.052 827.786C210.587 827.786 228.217 813.837 233.642 795.045Z"
          fill="white"
        />
        <path
          d="M449.072 669.7C449.072 680.743 440.161 689.848 428.924 689.848C417.881 689.848 408.776 680.743 408.776 669.7C408.776 658.657 417.881 649.552 428.924 649.552C440.161 649.358 449.072 658.464 449.072 669.7Z"
          fill="white"
        />
        <path
          d="M353.369 682.291H379.329C373.517 715.225 344.651 740.217 309.779 740.217C271.032 740.217 239.26 708.444 239.26 669.504C239.26 630.758 271.032 598.986 309.779 598.986C344.457 598.986 373.517 623.977 379.329 656.912H353.369C347.944 638.12 330.314 624.171 309.779 624.171C284.981 624.171 264.445 644.513 264.445 669.504C264.445 694.496 284.787 715.031 309.779 715.031C330.314 715.031 347.944 701.083 353.369 682.291Z"
          fill="white"
        />
        <path
          d="M472.901 569.733H498.861C493.049 602.667 464.183 627.659 429.311 627.659C390.565 627.659 358.793 595.887 358.793 556.946C358.793 518.2 390.565 486.428 429.311 486.428C463.989 486.428 493.049 511.419 498.861 544.354H472.901C467.477 525.562 449.847 511.613 429.311 511.613C404.513 511.613 383.978 531.955 383.978 556.946C383.978 581.938 404.32 602.474 429.311 602.474C450.041 602.474 467.477 588.525 472.901 569.733Z"
          fill="white"
        />
        <path
          d="M329.345 556.946C329.345 567.989 320.434 577.095 309.197 577.095C298.154 577.095 289.049 567.989 289.049 556.946C289.049 545.904 298.154 536.798 309.197 536.798C320.434 536.798 329.345 545.904 329.345 556.946Z"
          fill="white"
        />
        <path
          d="M233.642 569.733H259.602C253.79 602.667 224.924 627.659 190.052 627.659C151.305 627.659 119.533 595.887 119.533 556.946C119.533 518.2 151.305 486.428 190.052 486.428C224.73 486.428 253.79 511.419 259.602 544.354H233.642C228.217 525.562 210.587 511.613 190.052 511.613C165.254 511.613 144.719 531.955 144.719 556.946C144.719 581.938 165.06 602.474 190.052 602.474C210.587 602.474 228.217 588.525 233.642 569.733Z"
          fill="white"
        />
        <path
          d="M472.901 569.733H498.861C493.049 602.667 464.183 627.659 429.311 627.659C390.565 627.659 358.793 595.887 358.793 556.946C358.793 518.2 390.565 486.428 429.311 486.428C463.989 486.428 493.049 511.419 498.861 544.354H472.901C467.477 525.562 449.847 511.613 429.311 511.613C404.513 511.613 383.978 531.955 383.978 556.946C383.978 581.938 404.32 602.474 429.311 602.474C450.041 602.474 467.477 588.525 472.901 569.733Z"
          fill="white"
        />
        <path
          d="M329.345 556.946C329.345 567.989 320.434 577.095 309.197 577.095C298.154 577.095 289.049 567.989 289.049 556.946C289.049 545.904 298.154 536.798 309.197 536.798C320.434 536.798 329.345 545.904 329.345 556.946Z"
          fill="white"
        />
        <path
          d="M233.642 569.733H259.602C253.79 602.667 224.924 627.659 190.052 627.659C151.305 627.659 119.533 595.887 119.533 556.946C119.533 518.2 151.305 486.428 190.052 486.428C224.73 486.428 253.79 511.419 259.602 544.354H233.642C228.217 525.562 210.587 511.613 190.052 511.613C165.254 511.613 144.719 531.955 144.719 556.946C144.719 581.938 165.06 602.474 190.052 602.474C210.587 602.474 228.217 588.525 233.642 569.733Z"
          fill="white"
        />
        <path
          d="M449.072 444.385C449.072 455.428 440.161 464.533 428.924 464.533C417.881 464.533 408.776 455.428 408.776 444.385C408.776 433.342 417.881 424.237 428.924 424.237C440.161 424.237 449.072 433.342 449.072 444.385Z"
          fill="white"
        />
        <path
          d="M353.369 457.171H379.329C373.517 490.106 344.651 515.097 309.779 515.097C271.032 515.097 239.26 483.325 239.26 444.385C239.26 405.638 271.032 373.866 309.779 373.866C344.457 373.866 373.517 398.858 379.329 431.792H353.369C347.944 413 330.314 399.052 309.779 399.052C284.981 399.052 264.445 419.393 264.445 444.385C264.445 469.376 284.787 489.912 309.779 489.912C330.314 489.912 347.944 475.963 353.369 457.171Z"
          fill="white"
        />
        <path
          d="M472.901 344.421H498.861C493.049 377.355 464.183 402.347 429.311 402.347C390.565 402.347 358.793 370.574 358.793 331.634C358.793 292.888 390.565 261.116 429.311 261.116C463.989 261.116 493.049 286.107 498.861 319.042H472.901C467.477 300.25 449.847 286.301 429.311 286.301C404.513 286.301 383.978 306.643 383.978 331.634C383.978 356.626 404.32 377.161 429.311 377.161C450.041 377.161 467.477 363.213 472.901 344.421Z"
          fill="white"
        />
        <path
          d="M329.345 331.826C329.345 342.869 320.434 351.975 309.197 351.975C298.154 351.975 289.049 342.869 289.049 331.826C289.049 320.784 298.154 311.678 309.197 311.678C320.434 311.484 329.345 320.59 329.345 331.826Z"
          fill="white"
        />
        <path
          d="M233.642 344.421H259.602C253.79 377.355 224.924 402.347 190.052 402.347C151.305 402.347 119.533 370.574 119.533 331.634C119.533 292.888 151.305 261.116 190.052 261.116C224.73 261.116 253.79 286.107 259.602 319.042H233.642C228.217 300.25 210.587 286.301 190.052 286.301C165.254 286.301 144.719 306.643 144.719 331.634C144.719 356.626 165.06 377.161 190.052 377.161C210.587 377.161 228.217 363.213 233.642 344.421Z"
          fill="white"
        />
        <path
          d="M472.901 344.421H498.861C493.049 377.355 464.183 402.347 429.311 402.347C390.565 402.347 358.793 370.574 358.793 331.634C358.793 292.888 390.565 261.116 429.311 261.116C463.989 261.116 493.049 286.107 498.861 319.042H472.901C467.477 300.25 449.847 286.301 429.311 286.301C404.513 286.301 383.978 306.643 383.978 331.634C383.978 356.626 404.32 377.161 429.311 377.161C450.041 377.161 467.477 363.213 472.901 344.421Z"
          fill="white"
        />
        <path
          d="M329.345 331.83C329.345 342.873 320.434 351.978 309.197 351.978C298.154 351.978 289.049 342.873 289.049 331.83C289.049 320.787 298.154 311.682 309.197 311.682C320.434 311.488 329.345 320.594 329.345 331.83Z"
          fill="white"
        />
        <path
          d="M233.642 344.421H259.602C253.79 377.355 224.924 402.347 190.052 402.347C151.305 402.347 119.533 370.574 119.533 331.634C119.533 292.888 151.305 261.116 190.052 261.116C224.73 261.116 253.79 286.107 259.602 319.042H233.642C228.217 300.25 210.587 286.301 190.052 286.301C165.254 286.301 144.719 306.643 144.719 331.634C144.719 356.626 165.06 377.161 190.052 377.161C210.587 377.161 228.217 363.213 233.642 344.421Z"
          fill="white"
        />
        <path
          d="M449.072 219.076C449.072 230.119 440.161 239.225 428.924 239.225C417.881 239.225 408.776 230.119 408.776 219.076C408.776 208.034 417.881 198.928 428.924 198.928C440.161 198.928 449.072 208.034 449.072 219.076Z"
          fill="white"
        />
        <path
          d="M353.369 231.863H379.329C373.517 264.797 344.651 289.789 309.779 289.789C271.032 289.789 239.26 258.017 239.26 219.076C239.26 180.33 271.032 148.558 309.779 148.558C344.457 148.558 373.517 173.549 379.329 206.484H353.369C347.944 187.692 330.314 173.743 309.779 173.743C284.981 173.743 264.445 194.085 264.445 219.076C264.445 244.068 284.787 264.603 309.779 264.603C330.314 264.603 347.944 250.655 353.369 231.863Z"
          fill="white"
        />
        <path
          d="M472.901 119.305H498.861C493.049 152.239 464.183 177.231 429.311 177.231C390.565 177.231 358.793 145.459 358.793 106.519C358.793 67.7721 390.565 36 429.311 36C463.989 36 493.049 60.9915 498.861 93.926H472.901C467.477 75.1339 449.847 61.1852 429.311 61.1852C404.513 61.1852 383.978 81.5271 383.978 106.519C383.978 131.51 404.32 152.046 429.311 152.046C450.041 152.046 467.477 138.097 472.901 119.305Z"
          fill="white"
        />
        <path
          d="M329.345 106.519C329.345 117.561 320.434 126.667 309.197 126.667C298.154 126.667 289.049 117.561 289.049 106.519C289.049 95.4759 298.154 86.3704 309.197 86.3704C320.434 86.3704 329.345 95.4759 329.345 106.519Z"
          fill="white"
        />
        <path
          d="M233.642 119.305H259.602C253.79 152.239 224.924 177.231 190.052 177.231C151.305 177.231 119.533 145.459 119.533 106.519C119.533 67.7721 151.305 36 190.052 36C224.73 36 253.79 60.9915 259.602 93.926H233.642C228.217 75.1339 210.587 61.1852 190.052 61.1852C165.254 61.1852 144.719 81.5271 144.719 106.519C144.719 131.51 165.06 152.046 190.052 152.046C210.587 152.046 228.217 138.097 233.642 119.305Z"
          fill="white"
        />
        <path
          d="M209.812 1345.24C209.812 1356.29 200.901 1365.39 189.664 1365.39C178.621 1365.39 169.516 1356.29 169.516 1345.24C169.516 1334.2 178.621 1325.1 189.664 1325.1C200.901 1325.1 209.812 1334.2 209.812 1345.24Z"
          fill="white"
        />
        <path
          d="M233.642 1245.47H259.602C253.79 1278.41 224.924 1303.4 190.052 1303.4C151.305 1303.4 119.533 1271.63 119.533 1232.69C119.533 1193.94 151.305 1162.17 190.052 1162.17C224.73 1162.17 253.79 1187.16 259.602 1220.09H233.642C228.217 1201.3 210.587 1187.35 190.052 1187.35C165.254 1187.35 144.719 1207.7 144.719 1232.69C144.719 1257.68 165.06 1278.21 190.052 1278.21C210.587 1278.21 228.217 1264.27 233.642 1245.47Z"
          fill="white"
        />
        <path
          d="M90.0854 1232.69C90.0854 1243.73 81.1737 1252.83 69.9372 1252.83C58.8945 1252.83 49.7892 1243.73 49.7892 1232.69C49.7892 1221.64 58.8945 1212.54 69.9372 1212.54C81.1737 1212.54 90.0854 1221.64 90.0854 1232.69Z"
          fill="white"
        />
        <path
          d="M233.642 1245.47H259.602C253.79 1278.41 224.924 1303.4 190.052 1303.4C151.305 1303.4 119.533 1271.63 119.533 1232.69C119.533 1193.94 151.305 1162.17 190.052 1162.17C224.73 1162.17 253.79 1187.16 259.602 1220.09H233.642C228.217 1201.3 210.587 1187.35 190.052 1187.35C165.254 1187.35 144.719 1207.7 144.719 1232.69C144.719 1257.68 165.06 1278.21 190.052 1278.21C210.587 1278.21 228.217 1264.27 233.642 1245.47Z"
          fill="white"
        />
        <path
          d="M90.0854 1232.69C90.0854 1243.73 81.1737 1252.83 69.9372 1252.83C58.8945 1252.83 49.7892 1243.73 49.7892 1232.69C49.7892 1221.64 58.8945 1212.54 69.9372 1212.54C81.1737 1212.54 90.0854 1221.64 90.0854 1232.69Z"
          fill="white"
        />
        <path
          d="M209.812 1120.12C209.812 1131.17 200.901 1140.27 189.664 1140.27C178.621 1140.27 169.516 1131.17 169.516 1120.12C169.516 1109.08 178.621 1099.98 189.664 1099.98C200.901 1099.98 209.812 1108.89 209.812 1120.12Z"
          fill="white"
        />
        <path
          d="M114.108 1132.91H140.069C134.257 1165.85 105.391 1190.84 70.5187 1190.84C31.7722 1190.84 0 1159.07 0 1120.12C0 1081.38 31.7722 1049.61 70.5187 1049.61C105.197 1049.61 134.257 1074.6 140.069 1107.53H114.108C108.684 1088.74 91.0543 1074.79 70.5187 1074.79C45.7209 1074.79 25.1853 1095.13 25.1853 1120.12C25.1853 1145.12 45.5272 1165.65 70.5187 1165.65C91.0543 1165.65 108.684 1151.7 114.108 1132.91Z"
          fill="white"
        />
        <path
          d="M233.642 1020.16H259.602C253.79 1053.1 224.924 1078.09 190.052 1078.09C151.305 1078.09 119.533 1046.31 119.533 1007.37C119.533 968.628 151.305 936.856 190.052 936.856C224.73 936.856 253.79 961.847 259.602 994.782H233.642C228.217 975.99 210.587 962.041 190.052 962.041C165.254 962.041 144.719 982.383 144.719 1007.37C144.719 1032.37 165.06 1052.9 190.052 1052.9C210.587 1052.9 228.217 1038.95 233.642 1020.16Z"
          fill="white"
        />
        <path
          d="M90.0854 1007.57C90.0854 1018.61 81.1737 1027.71 69.9372 1027.71C58.8945 1027.71 49.7892 1018.61 49.7892 1007.57C49.7892 996.524 58.8945 987.418 69.9372 987.418C81.1737 987.225 90.0854 996.33 90.0854 1007.57Z"
          fill="white"
        />
        <path
          d="M233.642 1020.16H259.602C253.79 1053.1 224.924 1078.09 190.052 1078.09C151.305 1078.09 119.533 1046.31 119.533 1007.37C119.533 968.628 151.305 936.856 190.052 936.856C224.73 936.856 253.79 961.847 259.602 994.782H233.642C228.217 975.99 210.587 962.041 190.052 962.041C165.254 962.041 144.719 982.383 144.719 1007.37C144.719 1032.37 165.06 1052.9 190.052 1052.9C210.587 1052.9 228.217 1038.95 233.642 1020.16Z"
          fill="white"
        />
        <path
          d="M90.0854 1007.57C90.0854 1018.61 81.1737 1027.71 69.9372 1027.71C58.8945 1027.71 49.7892 1018.61 49.7892 1007.57C49.7892 996.524 58.8945 987.418 69.9372 987.418C81.1737 987.225 90.0854 996.33 90.0854 1007.57Z"
          fill="white"
        />
        <path
          d="M209.812 894.813C209.812 905.856 200.901 914.961 189.664 914.961C178.621 914.961 169.516 905.856 169.516 894.813C169.516 883.77 178.621 874.665 189.664 874.665C200.901 874.665 209.812 883.77 209.812 894.813Z"
          fill="white"
        />
        <path
          d="M114.108 907.599H140.069C134.257 940.534 105.391 965.525 70.5187 965.525C31.7722 965.525 0 933.753 0 894.813C0 856.066 31.7722 824.294 70.5187 824.294C105.197 824.294 134.257 849.286 140.069 882.22H114.108C108.684 863.428 91.0543 849.479 70.5187 849.479C45.7209 849.479 25.1853 869.821 25.1853 894.813C25.1853 919.804 45.5272 940.34 70.5187 940.34C91.0543 940.34 108.684 926.391 114.108 907.599Z"
          fill="white"
        />
        <path
          d="M233.642 795.041H259.602C253.79 827.976 224.924 852.967 190.052 852.967C151.305 852.967 119.533 821.195 119.533 782.255C119.533 743.509 151.305 711.736 190.052 711.736C224.73 711.736 253.79 736.728 259.602 769.662H233.642C228.217 750.87 210.587 736.922 190.052 736.922C165.254 736.922 144.719 757.264 144.719 782.255C144.719 807.247 165.06 827.782 190.052 827.782C210.587 827.782 228.217 813.833 233.642 795.041Z"
          fill="white"
        />
        <path
          d="M90.0854 782.255C90.0854 793.298 81.1737 802.403 69.9372 802.403C58.8945 802.403 49.7892 793.298 49.7892 782.255C49.7892 771.212 58.8945 762.107 69.9372 762.107C81.1737 762.107 90.0854 771.019 90.0854 782.255Z"
          fill="white"
        />
        <path
          d="M233.642 795.045H259.602C253.79 827.98 224.924 852.971 190.052 852.971C151.305 852.971 119.533 821.199 119.533 782.259C119.533 743.512 151.305 711.74 190.052 711.74C224.73 711.74 253.79 736.732 259.602 769.666H233.642C228.217 750.874 210.587 736.925 190.052 736.925C165.254 736.925 144.719 757.267 144.719 782.259C144.719 807.25 165.06 827.786 190.052 827.786C210.587 827.786 228.217 813.837 233.642 795.045Z"
          fill="white"
        />
        <path
          d="M90.0854 782.259C90.0854 793.302 81.1737 802.407 69.9372 802.407C58.8945 802.407 49.7892 793.302 49.7892 782.259C49.7892 771.216 58.8945 762.111 69.9372 762.111C81.1737 762.111 90.0854 771.022 90.0854 782.259Z"
          fill="white"
        />
        <path
          d="M209.812 669.7C209.812 680.743 200.901 689.848 189.664 689.848C178.621 689.848 169.516 680.743 169.516 669.7C169.516 658.657 178.621 649.552 189.664 649.552C200.901 649.358 209.812 658.464 209.812 669.7Z"
          fill="white"
        />
        <path
          d="M114.108 682.291H140.069C134.257 715.225 105.391 740.217 70.5187 740.217C31.7722 740.217 0 708.444 0 669.504C0 630.758 31.7722 598.986 70.5187 598.986C105.197 598.986 134.257 623.977 140.069 656.912H114.108C108.684 638.12 91.0543 624.171 70.5187 624.171C45.7209 624.171 25.1853 644.513 25.1853 669.504C25.1853 694.496 45.5272 715.031 70.5187 715.031C91.0543 715.031 108.684 701.083 114.108 682.291Z"
          fill="white"
        />
        <path
          d="M233.642 569.733H259.602C253.79 602.667 224.924 627.659 190.052 627.659C151.305 627.659 119.533 595.887 119.533 556.946C119.533 518.2 151.305 486.428 190.052 486.428C224.73 486.428 253.79 511.419 259.602 544.354H233.642C228.217 525.562 210.587 511.613 190.052 511.613C165.254 511.613 144.719 531.955 144.719 556.946C144.719 581.938 165.06 602.474 190.052 602.474C210.587 602.474 228.217 588.525 233.642 569.733Z"
          fill="white"
        />
        <path
          d="M90.0854 556.946C90.0854 567.989 81.1737 577.095 69.9372 577.095C58.8945 577.095 49.7892 567.989 49.7892 556.946C49.7892 545.904 58.8945 536.798 69.9372 536.798C81.1737 536.798 90.0854 545.904 90.0854 556.946Z"
          fill="white"
        />
        <path
          d="M233.642 569.733H259.602C253.79 602.667 224.924 627.659 190.052 627.659C151.305 627.659 119.533 595.887 119.533 556.946C119.533 518.2 151.305 486.428 190.052 486.428C224.73 486.428 253.79 511.419 259.602 544.354H233.642C228.217 525.562 210.587 511.613 190.052 511.613C165.254 511.613 144.719 531.955 144.719 556.946C144.719 581.938 165.06 602.474 190.052 602.474C210.587 602.474 228.217 588.525 233.642 569.733Z"
          fill="white"
        />
        <path
          d="M90.0854 556.946C90.0854 567.989 81.1737 577.095 69.9372 577.095C58.8945 577.095 49.7892 567.989 49.7892 556.946C49.7892 545.904 58.8945 536.798 69.9372 536.798C81.1737 536.798 90.0854 545.904 90.0854 556.946Z"
          fill="white"
        />
        <path
          d="M209.812 444.385C209.812 455.428 200.901 464.533 189.664 464.533C178.621 464.533 169.516 455.428 169.516 444.385C169.516 433.342 178.621 424.237 189.664 424.237C200.901 424.237 209.812 433.342 209.812 444.385Z"
          fill="white"
        />
        <path
          d="M114.108 457.171H140.069C134.257 490.106 105.391 515.097 70.5187 515.097C31.7722 515.097 0 483.325 0 444.385C0 405.638 31.7722 373.866 70.5187 373.866C105.197 373.866 134.257 398.858 140.069 431.792H114.108C108.684 413 91.0543 399.052 70.5187 399.052C45.7209 399.052 25.1853 419.393 25.1853 444.385C25.1853 469.376 45.5272 489.912 70.5187 489.912C91.0543 489.912 108.684 475.963 114.108 457.171Z"
          fill="white"
        />
        <path
          d="M233.642 344.421H259.602C253.79 377.355 224.924 402.347 190.052 402.347C151.305 402.347 119.533 370.574 119.533 331.634C119.533 292.888 151.305 261.116 190.052 261.116C224.73 261.116 253.79 286.107 259.602 319.042H233.642C228.217 300.25 210.587 286.301 190.052 286.301C165.254 286.301 144.719 306.643 144.719 331.634C144.719 356.626 165.06 377.161 190.052 377.161C210.587 377.161 228.217 363.213 233.642 344.421Z"
          fill="white"
        />
        <path
          d="M90.0854 331.826C90.0854 342.869 81.1737 351.975 69.9372 351.975C58.8945 351.975 49.7892 342.869 49.7892 331.826C49.7892 320.784 58.8945 311.678 69.9372 311.678C81.1737 311.484 90.0854 320.59 90.0854 331.826Z"
          fill="white"
        />
        <path
          d="M233.642 344.421H259.602C253.79 377.355 224.924 402.347 190.052 402.347C151.305 402.347 119.533 370.574 119.533 331.634C119.533 292.888 151.305 261.116 190.052 261.116C224.73 261.116 253.79 286.107 259.602 319.042H233.642C228.217 300.25 210.587 286.301 190.052 286.301C165.254 286.301 144.719 306.643 144.719 331.634C144.719 356.626 165.06 377.161 190.052 377.161C210.587 377.161 228.217 363.213 233.642 344.421Z"
          fill="white"
        />
        <path
          d="M90.0854 331.83C90.0854 342.873 81.1737 351.978 69.9372 351.978C58.8945 351.978 49.7892 342.873 49.7892 331.83C49.7892 320.787 58.8945 311.682 69.9372 311.682C81.1737 311.488 90.0854 320.594 90.0854 331.83Z"
          fill="white"
        />
        <path
          d="M209.812 219.076C209.812 230.119 200.901 239.225 189.664 239.225C178.621 239.225 169.516 230.119 169.516 219.076C169.516 208.034 178.621 198.928 189.664 198.928C200.901 198.928 209.812 208.034 209.812 219.076Z"
          fill="white"
        />
        <path
          d="M114.108 231.863H140.069C134.257 264.797 105.391 289.789 70.5187 289.789C31.7722 289.789 0 258.017 0 219.076C0 180.33 31.7722 148.558 70.5187 148.558C105.197 148.558 134.257 173.549 140.069 206.484H114.108C108.684 187.692 91.0543 173.743 70.5187 173.743C45.7209 173.743 25.1853 194.085 25.1853 219.076C25.1853 244.068 45.5272 264.603 70.5187 264.603C91.0543 264.603 108.684 250.655 114.108 231.863Z"
          fill="white"
        />
        <path
          d="M233.642 119.305H259.602C253.79 152.239 224.924 177.231 190.052 177.231C151.305 177.231 119.533 145.459 119.533 106.519C119.533 67.7721 151.305 36 190.052 36C224.73 36 253.79 60.9915 259.602 93.926H233.642C228.217 75.1339 210.587 61.1852 190.052 61.1852C165.254 61.1852 144.719 81.5271 144.719 106.519C144.719 131.51 165.06 152.046 190.052 152.046C210.587 152.046 228.217 138.097 233.642 119.305Z"
          fill="white"
        />
        <path
          d="M90.0854 106.519C90.0854 117.561 81.1737 126.667 69.9372 126.667C58.8945 126.667 49.7892 117.561 49.7892 106.519C49.7892 95.4759 58.8945 86.3704 69.9372 86.3704C81.1737 86.3704 90.0854 95.4759 90.0854 106.519Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_16783_42489">
        <rect width="1440" height="223" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>
