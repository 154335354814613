import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Login } from "../models/login.model";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  firebaseToken: string;

  constructor(private http: HttpClient) {}

  Authentify(login: Login) {
    return this.http.post(`/api/Login/LoginAdmin`, login, {
      observe: "response",
    });
  }

  login(login: Login) {
    this.firebaseToken =
      this.firebaseToken == undefined || this.firebaseToken == null
        ? ""
        : this.firebaseToken;
    let headers = new HttpHeaders().set("firebaseToken", this.firebaseToken);
    return this.http.post(`/api/Login/Login`, login, {
      observe: "response",
      headers: headers,
    });
  }
}
