import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SmsService {
  constructor(private http: HttpClient) {}
  sendSMS(body) {
    // let body
    return this.http.post(`/api/Payment/SendSms`, body, {
      observe: "response",
    });
  }
  sendSMSEmail(transferId, email) {
    let lang = localStorage.getItem("lang");
    let sendData = {
      transferId: transferId,
      email: email,
      lang: lang ? lang : "en",
    };
    return this.http.post(`/api/Payment/SendEmailReciept`, sendData);
  }

  getSMShistory() {
    return this.http.get(`/api/Merchant/GetSMSHistories`, {
      observe: "response",
    });
  }
}
