import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Merchant } from '../models/merchant.model';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor(private http:HttpClient) { }

  getMerchantById(id:number){
    const params=new HttpParams()
    .set("id",id.toString())
    return this.http.get(`/api/Merchant/GetMerchant`,{params,observe:"response"})
  }

  updateMerchant(m:Merchant){
    return this.http.put(`/api/Merchant/UpdateMerchant`,m,{observe:"body"})
  }
  cahngeMerchantStatus(id: number, status: number){
    let body = {
      merchantId: id,
      merchantStatus: status
    }
    return this.http.post(`/api/Merchant/ChangeStatus`,body,{observe:"body"})
  }

  getMerchants(){
    return this.http.get(`/api/Merchant/GetMerchants`,{observe:"response"})
  }

  addMerchant(merchant:Merchant){
    return this.http.post(`/api/Merchant/AddMerchant`,merchant,{observe:"response"})
  }

  deleteMerchant(id:number){
    const params=new HttpParams()
    .set("id",id.toString())
    return this.http.delete(`/api/Merchant/RemoveMerchant`,{params,observe:"response"})
  }

  deactivateMerchant(id:number){
    // const params=new HttpParams()
    // .set("id",id.toString())
    return this.http.put(`/api/Merchant/ChangeStatus?id=${id}`,{observe:"response"})
  }

  getMerchantsByPage(page:number, count: number){
    const params=new HttpParams()
    .set("page",page.toString())
    .set("count", count.toString())
    return this.http.get(`/api/Merchant/GetMerchantsByPage`,{params,observe:'response'})
  }

  getMerhcnatFiltersByPage(page:number, count: number, body:any){
    const params=new HttpParams()
    .set("page",page.toString())
    .set("count", count.toString())
    return this.http.post(`/api/Merchant/SearchMerchants`,  body, {params,observe:'response'})
  }


  getFilteredByName(n:number){
    return this.http.get(`/api/Merchant/GetFilteredByName?orderingType=${n}`,{observe:"response"})
  }

  getHeaders(){
    return this.http.get(`/api/MerchantTable/GetHeaders`,{observe:'response'})
  }

  updateHeader(id:number){
    return this.http.put(`/api/MerchantTable/UpdateHeader?id=${id}`,{observe:"response"})
  }

  getSMS(){
    return this.http.get(`/api/Merchant/GetSMSHistories`,{observe:"body"})
  }

  genratePayxToken()
  {
    return this.http.get(`/api/Merchant/GeneratePayxToken`, {observe: 'response'})
  }
  getDomains()
  {
    return this.http.get(`/api/Domain/GetDomains`, {observe: 'response'})
  }

  endTMerchantsForExcel(body) {
    // const headers = new HttpHeaders().set('Content-Type', 'xlxx');
    return this.http.post(`/api/Merchant/GetMerchantsExcel`, body, {
      responseType: "blob",
    });
  }
}
