<div class="wrapper">
  <div class="all">

    <form class="pass_form" [formGroup]="passwordForm" (submit)="changePassword()">
      <div class="footer">
        <div class="password">
          <input [(ngModel)]="oldPassword" [ngModelOptions]="{standalone: true}" type="password" class="input_pass"
            placeholder="{{'changePassword.oldPassword' | translate}}">
          <input type="password" class="input_pass" placeholder="{{'changePassword.newPassword' | translate}}"
            formControlName="password">
          <input type="password" class="input_pass" placeholder="{{'changePassword.confirmPassword' | translate}}"
            formControlName="confirmPassword">
          <p class="error" *ngIf="passwordForm.errors?.misMatch">{{'changePassword.passwordsDontMatch' | translate}}</p>
          <button [disabled]="!passwordForm.valid" class=" enter pass_enter">{{'changePassword.changeThePassword' |
            translate}}</button>
        </div>
      </div>
    </form>
  </div>
  <!-- <input type="file" name="" id="" (change)="onFile($event)"> -->
</div>
