<div class="wrapper">
  <div class="cont">
    <header>
      <app-header></app-header>
    </header>
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
