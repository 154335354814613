<div class="wrapper">

  <div class="button">
    <button class="btn my-btn-color" data-bs-toggle="modal" data-bs-target="#addnewEmployee" (click)="buildForm()">Add
      employee</button>
  </div>

  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Full name</th>
        <th scope="col">Username</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let u of employees; index as i" style="cursor: pointer;" data-bs-toggle="modal"
        data-bs-target="#infoModalUpdate" (click)="useData(u)">
        <td>{{i + 1}}</td>
        <td>{{u?.fullname}}</td>
        <td>{{u?.username}}</td>
      </tr>
    </tbody>
  </table>
</div>


<div class="modal fade" id="infoModalUpdate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update employee</h5>
        <button #closeUpdateModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body" *ngIf="newEmployee">
        <div class="my-modal-body" [formGroup]="newEmployee">

          <div class="bl">
            <div class="name">
              Full Name:&nbsp;
            </div>
            <div class="data">
              <input type="text" class="form-control disabled" formControlName="fullname">
            </div>
          </div>

          <div class="bl">
            <div class="name">
              Username:&nbsp;
            </div>
            <div class="data">
              <input type="text" class="form-control disabled" formControlName="username">
            </div>
          </div>

          <div class="bl">
            <div class="name">
              Password:&nbsp;
            </div>
            <div class="data">
              <input placeholder="Chi ashxatum" type="password" class="form-control disabled">
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeInfoModal'>Cancel</button>
        <!-- <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteEmployee()">Delete</button> -->
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="updateEmployee()">Update</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="addnewEmployee" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New employee</h5>
        <button #closeAddModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body" *ngIf="newEmployee">
        <div class="my-modal-body" [formGroup]="newEmployee">

          <div class="bl">
            <div class="name">
              Full Name:&nbsp;
            </div>
            <div class="data">
              <input type="text" class="form-control disabled" formControlName="fullname">
            </div>
          </div>

          <div class="bl">
            <div class="name">
              Username:&nbsp;
            </div>
            <div class="data">
              <input type="text" class="form-control disabled" formControlName="username">
            </div>
          </div>

          <div class="bl">
            <div class="name">
              Password:&nbsp;
            </div>
            <div class="data">
              <input type="password" class="form-control disabled" formControlName="password">
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal" (click)="addNewEmployee()">Add</button>
      </div>
    </div>
  </div>
</div>
