import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterApplePayModel } from 'src/app/models/apple-pay/RegisterApplePay.model';
import { Merchant } from 'src/app/models/merchant.model';
import { MerchantGroup } from 'src/app/models/merchantGroup.model';
import { MerchantUser } from 'src/app/models/merchantUser.model';
import { ApplePayService } from 'src/app/services/applePay.service';
import { MerchantService } from 'src/app/services/merchant.service';
import { MerchantGroupService } from 'src/app/services/merchantGroup.service';
import { MerchantUserService } from 'src/app/services/merchantUser.service';

@Component({
  selector: 'app-mercahnt-info',
  templateUrl: './mercahnt-info.component.html',
  styleUrls: ['./mercahnt-info.component.scss']
})
export class MercahntInfoComponent implements OnInit, OnDestroy {

  merchantID: number
  merchant:Merchant;
  user:MerchantUser=new MerchantUser();
  selecteduser: MerchantUser

  users:MerchantUser[] = [];
  groups: MerchantGroup[] = [];

  registerMerchantApplePayFormGroup: FormGroup;

  registerMerchantDialog: any;


  disableMerchantDelete: boolean = true
  merchantGroupName: string = ''
  isMerchantGroup: boolean

  @ViewChild('mainUserModal') mainUserModal: ElementRef
  @ViewChild('closeUserModal') closeUserModal: ElementRef
  @ViewChild('closeUserUpdateModal') closeUserUpdateModal: ElementRef
  @ViewChild('allusersmodal') allusersmodal: ElementRef



  constructor(
    private activatedRoute: ActivatedRoute,
    private merchantService:MerchantService,
    private router: Router,
    private userService: MerchantUserService,
    private merchantGroupService:MerchantGroupService,
    private applePayService: ApplePayService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private fb: FormBuilder
    ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      res =>
      {
        this.merchantID = parseInt(res['id'])
        this.getMerchantInfo(this.merchantID)
      },
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )
  }

  getMerchantInfo(id:number)
  {
    this.merchantService.getMerchantById(id).subscribe(
      res=>{
        this.merchant=res.body as Merchant;
        this.isMerchantGroup = this.merchant.merchantArcaDetails.merchantGroupId != 0 ? true : false
        this.getUsers(id)
        this.disableMerchantDelete = this.merchant.status == 2 ? false : true
        if(this.isMerchantGroup)
        {
          this.getGroup(this.merchant.merchantArcaDetails.merchantGroupId)
        }
      },
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )
  }

  changeCommentEdit(e)
  {
    let updateMerchant: Merchant = this.merchant
    updateMerchant.canChangeComment = e.target.checked
    this.merchantService.updateMerchant(updateMerchant).subscribe(
      res => {

      },
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )
  }

  getUsers(id:number){
    this.userService.getUsersByMerchant(id).subscribe(
      res=>{
        this.users=res.body as MerchantUser[];
        console.log(this.users)
      },
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )
  }

  updateUser(user:MerchantUser, type: string = '0'){
    this.userService.updateUser(user).subscribe(
      res=>{
        this.getUsers(this.merchantID);
        if(type == '1')
        {
          this.closeUserUpdateModal.nativeElement.click()
        }
      },
        err=>{
        }
    )
  }

  deleteUser(id){
    this.userService.disableMerchantUser(id).subscribe(
      res=>{
        // this.getUsers(this.merchantID);
        // let index = this.users.findIndex(u => u.merchantUserId == id)
        // this.users.splice(index, 1)
        // let user = this.users.find(u => u.merchantUserId == id)
        let index = this.users.findIndex(u => u.merchantUserId == id)
        this.users[index] = res as MerchantUser
      },
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )
  }


  changeStatus(val)
  {
    this.merchantService.cahngeMerchantStatus(this.merchant.merchantId, val*1).subscribe(
      res=>{
        this.disableMerchantDelete = val*1 == 2 ? false : true
      },
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )
  }

  disableMerchant(){
    if(this.merchant.status == 2)
    return
    this.merchantService.deactivateMerchant(this.merchantID).subscribe(
      res=>{
        // this.router.navigateByUrl("/admin/merchants?page=1")
      },
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )
  }


  updatePage()
  {
    this.router.navigateByUrl(`admin/merchants/update-merchant?id=${this.merchantID}`)
  }

  closeMainUserModal()
  {
    this.mainUserModal.nativeElement.click()
  }

  getGroup(id:number)
  {
    this.merchantGroupService.getMerchantGroupsByBank(this.merchant.bank.id).subscribe(
      res =>
      {
        this.groups = res.body as MerchantGroup[]
        this.merchantGroupName = this.groups.find(gr=>gr.merchantArcaDetails.merchantGroupId == id)?.merchantGroupName
      },
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )
  }

  newUser()
  {
    let merchant_obj = {
      id:this.merchant.merchantId,
      title:this.merchant.merchantInfo.companyNameEn
    }
    this.user.merchants.push(merchant_obj)
    this.userService.addUser(this.user).subscribe(
      res=>{
        this.getUsers(this.merchantID)
        this.closeUserModal.nativeElement.click()
        // this.allusersmodal.nativeElement.click()
      },
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )

  }

  attachMerchant()
  {
    console.log(this.selecteduser)
  }

  openRegisterMerchantDialog(templateRef)
  {
    this.registerMerchantApplePayFormGroup = this.fb.group({
      encryptTo: [null, [Validators.required]],
      domainNames: [[], [Validators.required]],
      MerchantId: [this.merchantID.toString()],
      partnerMerchantName: [this.merchant.merchantInfo.companyNameEn, [Validators.required]],
    })
    this.registerMerchantDialog = this.dialog.open(templateRef, {
      width: "40%"
    })
  }


  registerMerchantToApplePay()
  {
    let registerMerchantApplePayBody: RegisterApplePayModel = {
      domainNames: (this.registerMerchantApplePayFormGroup.get('domainNames').value as string).trim().split(" "),
      MerchantId: this.registerMerchantApplePayFormGroup.get('MerchantId').value,
      partnerMerchantName: this.registerMerchantApplePayFormGroup.get('partnerMerchantName').value
    }
    if(this.registerMerchantApplePayFormGroup.get('encryptTo').value)
    {
      registerMerchantApplePayBody.encryptTo = this.registerMerchantApplePayFormGroup.get('encryptTo').value
    }
    this.applePayService.registerMerchant(registerMerchantApplePayBody).subscribe(
      res =>{
        this.registerMerchantDialog.close()
      }
    )

  }

  ngOnDestroy(): void {
      this.mainUserModal.nativeElement?.click()
      this.closeUserModal?.nativeElement.click()
      this.closeUserUpdateModal?.nativeElement.click()
      // this.allusersmodal?.nativeElement.click()
  }






}
