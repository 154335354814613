<div class="success_meassage zoom" *ngIf="showSuccessMessage">
  <img src="../../../../../assets/image/checked64.png" alt="" />
</div>
<div class="wrapper">
  <form [formGroup]="qrForm">
    <div class="mb-3">
      <label for="amount" class="form-label">{{
        "qrModal.amount" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="amount"
        suffix=" ֏"
        mask="separator.2"
        thousandSeparator=","
        formControlName="amount"
        onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
      />
    </div>
    <div class="mb-3">
      <label for="comment" class="form-label">{{
        "qrModal.comment" | translate
      }}</label>
      <textarea
        class="form-control"
        id="comment"
        rows="3"
        formControlName="comment"
      ></textarea>
    </div>
    <div class="mb-3 toggles" *ngIf="canGenerateMultiQR">
      <div class="tog">
        <label for="multi" class="form-label">{{
          "qrModal.isMulti" | translate
        }}</label>
        <label class="switch" id="multi">
          <input
            type="checkbox"
            formControlName="isMulti"
            (change)="amDisable($event)"
          />
          <span class="slider round"></span>
        </label>
      </div>

      <div class="t">
        <div class="tog" *ngIf="!canChangeComments">
          <label for="multi" class="form-label">{{
            "qrModal.changeComment" | translate
          }}</label>
          <label class="switch" id="multi">
            <input
              disabled
              type="checkbox"
              formControlName="canChangeComment"
              (change)="changeCommentStatus($event)"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="tog" *ngIf="canChangeComments">
          <label for="multi" class="form-label">{{
            "qrModal.changeComment" | translate
          }}</label>
          <label class="switch" id="multi">
            <input
              type="checkbox"
              formControlName="canChangeComment"
              (change)="changeCommentStatus($event)"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="mb-3 toggles" *ngIf="!canGenerateMultiQR">
      <div class="tog">
        <label for="multi" class="form-label">{{
          "qrModal.isMulti" | translate
        }}</label>
        <label class="switch" id="multi">
          <input
            disabled
            type="checkbox"
            formControlName="isMulti"
            (change)="amDisable($event)"
          />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="t">
        <div class="tog" *ngIf="!canChangeComments">
          <label for="multi" class="form-label">{{
            "qrModal.changeComment" | translate
          }}</label>
          <label class="switch" id="multi">
            <input
              disabled
              type="checkbox"
              formControlName="canChangeComment"
              (change)="changeCommentStatus($event)"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="tog" *ngIf="canChangeComments">
          <label for="multi" class="form-label">{{
            "qrModal.changeComment" | translate
          }}</label>
          <label class="switch" id="multi">
            <input
              type="checkbox"
              formControlName="canChangeComment"
              (change)="changeCommentStatus($event)"
            />
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
    <button class="btn btn-success" (click)="generateQR()">
      {{ "qrModal.button" | translate }}
    </button>
  </form>

  <button
    type="button"
    #qr_modal
    class="btn btn-primary hidden_button"
    data-bs-toggle="modal"
    data-bs-target="#qrlink"
  ></button>

  <div
    class="modal fade"
    id="qr-modal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="qr-modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">QR</h5>
          <button
            type="button"
            #close_modal_qr
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="dissmiss()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="my-modal-body">
            <div class="spinner-grow text-warning" *ngIf="loading"></div>
          </div>
          <div class="header" *ngIf="!loading">
            <h3>{{ "qrModal.amount" | translate }} {{ this.amountValue }}</h3>
            <qr-code
              *ngIf="canGenerateQR"
              value="{{ qrCode }}"
              size="200"
              errorCorrectionLevel="M"
            ></qr-code>
          </div>
          <div class="modal-footer" *ngIf="!loading">
            <div class="cop">
              <!-- <div class="alert alert-secondary text-center" role="alert" *ngIf="copied">
                {{'qrModal.copied' | translate}}
              </div> -->
              <button
                class="btn btn-success btn-for-qr"
                (click)="copyLink()"
                data-bs-toggle="modal"
                data-bs-target="#qrlink"
              >
                {{ "qrModal.copyLinkAndSend" | translate }}
              </button>
            </div>
            <div class="sms">
              <button
                class="btn btn-success btn-for-qr btn-for-qr-y"
                data-bs-toggle="modal"
                data-bs-target="#phoneModal"
              >
                {{ "qrModal.sendSMSRequest" | translate }}
              </button>
            </div>

            <!-- <div class="sms">
              <button class="btn btn-success btn-for-qr" data-bs-toggle="modal" data-bs-target="#qrlink">
                {{'qrModal.qrLink' |translate}}
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="phone-modal modal fade"
    id="phoneModal"
    tabindex="-1"
    aria-labelledby="phoneModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- <div class="modal-header">
            <h5 class="modal-title" id="phoneModalLabel">Modal title</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div> -->
        <div class="modal-body">
          <label for="phoneNumber" class="form-label">{{
            "qrModal.phoneNumber" | translate
          }}</label>
          <input
            type="text"
            class="form-control"
            prefix="+374"
            mask="00 00 00 00"
            [(ngModel)]="phoneNumber"
            id="phoneNumber"
            onkeydown="return event.keyCode !== 69 && event.keyCode !== 189"
          />
          <div
            class="alert alert-danger mt-3"
            *ngIf="invalidPhoneNumber"
            role="alert"
          >
            {{ "qrModal.invalidPhone" | translate }}
          </div>
          <div
            class="alert alert-success mt-3"
            *ngIf="successPhone"
            role="alert"
          >
            {{ "qrModal.sended" | translate }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            #closeModal
            data-bs-dismiss="modal"
          >
            {{ "qrModal.close" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            [disabled]="!sendSmsButton"
            (click)="sendSMS()"
          >
            {{ "qrModal.sendSMS" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="qrlink"
    tabindex="-1"
    aria-labelledby="qrlinkLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="phoneModalLabel">QR LINK</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            #closeQrLink
          ></button>
        </div>
        <div class="modal-body qr-body">
          <div class="my-modal-body">
            <div class="spinner-grow text-warning" *ngIf="loading"></div>
          </div>
          <div class="header" *ngIf="!loading">
            <h3>{{ "qrModal.amount" | translate }} {{ this.amountValue }}</h3>
            <qr-code
              value="{{ this.link }}"
              size="200"
              errorCorrectionLevel="M"
            ></qr-code>
          </div>
          <!-- <div class="alert alert-secondary text-center" role="alert" *ngIf="copied">
            {{'qrModal.copied' | translate}}
          </div> -->
          <!-- <p>{{this.link}}</p> -->
        </div>
        <!-- <div class="modal-footer my-modal-footer">
          <button type="button" class="btn btn-secondary" #closeModal data-bs-dismiss="modal">{{'qrModal.close' |
            translate}}</button>
        </div> -->

        <div class="modal-footer" *ngIf="!loading">
          <div class="cop">
            <div
              class="alert alert-secondary text-center"
              role="alert"
              *ngIf="copied"
            >
              {{ "qrModal.copied" | translate }}
            </div>
            <button class="btn btn-success btn-for-qr" (click)="copyLink()">
              {{ "qrModal.copyLinkAndSend" | translate }}
            </button>
          </div>
          <div class="sms">
            <button
              class="btn btn-success btn-for-qr btn-for-qr-y"
              data-bs-toggle="modal"
              data-bs-target="#phoneModal"
            >
              {{ "qrModal.sendSMSRequest" | translate }}
            </button>
          </div>

          <!-- <div class="sms">
            <button class="btn btn-success btn-for-qr" data-bs-toggle="modal" data-bs-target="#qrlink">
              {{'qrModal.qrLink' |translate}}
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
