import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SmsService } from 'src/app/services/sms.service';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit {

  history: any[] = []

  constructor(
    private smsService: SmsService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getHistory()
  }

  getHistory()
  {
    this.smsService.getSMShistory().subscribe(
      res => {
        this.history = res.body as any[]
      }
      ,
      err =>{
        let errmer = err?.error?.message
        this._snackBar.open(errmer,'', {
          duration: 7000
        });
      }
    )
  }

}
