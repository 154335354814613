<div class="wrapper">
  <header>
    <div class="logo">
      <a href="https://www.conversebank.am" style="display: inline-flex">
        <!-- <svg
          width="193"
          height="24"
          viewBox="0 0 193 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.41464 19.7565C7.19319 19.7565 6.17532 19.2696 5.56459 18.5044C4.68243 17.5304 4.411 16.4174 4.411 11.8957C4.411 7.37392 4.75029 6.26087 5.56459 5.28696C6.17532 4.52174 7.19319 4.03478 8.41464 4.03478C10.654 4.03478 11.8076 5.49565 12.2826 7.37392H16.8291C16.0148 2.50435 12.6897 0 8.41464 0C5.90388 0 3.86813 0.904348 2.23953 2.57391C-0.0676489 4.93913 0.00020944 7.86087 0.00020944 11.8957C0.00020944 15.9304 -0.0676489 18.8522 2.23953 21.2174C3.86813 22.887 5.90388 23.7913 8.41464 23.7913C12.6219 23.7913 16.0148 21.287 16.8291 16.3478H12.2826C11.8754 18.2957 10.7218 19.7565 8.41464 19.7565Z"
            fill="#007DC3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.1079 6.26086C22.5972 6.26086 20.9686 7.23478 19.9507 8.34782C18.4578 9.94782 18.0507 11.8956 18.0507 15.0261C18.0507 18.1565 18.4578 20.1043 19.9507 21.7043C20.9686 22.8174 22.665 23.7913 25.1079 23.7913C27.5508 23.7913 29.2473 22.8174 30.2652 21.7043C31.758 20.1043 32.1652 18.1565 32.1652 15.0261C32.1652 11.9652 31.758 10.0174 30.2652 8.34782C29.3151 7.30434 27.6187 6.26086 25.1079 6.26086ZM27.0758 19.2C26.6008 19.687 25.9901 19.9652 25.1079 19.9652C24.2936 19.9652 23.6829 19.687 23.2079 19.2C22.3257 18.2957 22.2579 16.7652 22.2579 15.0261C22.2579 13.2174 22.3257 11.7565 23.2079 10.8522C23.6829 10.3652 24.2936 10.087 25.1079 10.087C25.9222 10.087 26.6008 10.3652 27.0758 10.8522C27.958 11.7565 28.0258 13.2174 28.0258 15.0261C28.0258 16.8348 27.958 18.3652 27.0758 19.2Z"
            fill="#007DC3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M42.8189 6.26086C41.2582 6.26086 39.7653 6.88695 38.6117 8.06956V6.46956H34.5402V23.6522H38.7474V13.287C38.7474 10.9217 40.2403 10.1565 41.5296 10.1565C42.8189 10.1565 44.3118 10.9217 44.3118 13.287V23.6522H48.519V12.6609C48.519 10.7826 48.1797 9.1826 46.8226 7.7913C45.8726 6.81739 44.5154 6.26086 42.8189 6.26086Z"
            fill="#007DC3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M60.3265 6.46957L57.0014 17.1131L53.5406 6.46957H49.1977L55.3728 23.6522H58.63L64.7372 6.46957H60.3265Z"
            fill="#007DC3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M71.7267 6.26086C67.3837 6.26086 64.4658 9.46087 64.4658 15.0261C64.4658 21.7043 67.9944 23.6522 71.7267 23.7913C71.8624 23.7913 71.9981 23.7913 72.2017 23.7913C75.1874 23.7913 76.8839 22.8174 78.5803 21.0783L76.0696 18.5739C74.9839 19.687 74.1017 20.1739 72.2695 20.1739C72.1338 20.1739 71.9302 20.1739 71.7945 20.1739C69.7588 19.9652 68.6052 18.4348 68.6052 16.3478H71.7945H79.0554V14.4696C79.0554 9.80869 76.4089 6.26086 71.7267 6.26086ZM71.7267 13.4956H68.5373C68.5373 12.7304 68.673 12.2435 68.9445 11.687C69.4195 10.6435 70.3016 9.87826 71.7267 9.87826C73.1517 9.87826 74.1017 10.6435 74.5088 11.687C74.7803 12.313 74.8481 12.8 74.916 13.4956H71.7267Z"
            fill="#007DC3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M89.5734 6.26086C87.8091 6.26086 86.1805 7.09565 85.3662 8.06956V6.46956H81.2947V23.6522H85.5019V13.287C85.5019 11.1304 86.9269 10.1565 88.2162 10.1565C89.2341 10.1565 89.777 10.5043 90.4556 11.2L93.577 7.93043C92.3556 6.74782 91.202 6.26086 89.5734 6.26086Z"
            fill="#007DC3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.584 13.2174L98.9378 12.9391C97.445 12.8 97.1735 12.1044 97.1735 11.4783C97.1735 10.6435 97.8521 9.80871 99.5486 9.80871C100.974 9.80871 102.466 10.087 103.416 10.9913L105.995 8.34784C104.367 6.8174 102.195 6.33044 99.6164 6.33044C96.2235 6.33044 93.1699 8.20871 93.1699 11.8261C93.1699 14.9565 95.0021 16.3478 98.0557 16.6261L100.702 16.9044C101.991 17.0435 102.466 17.6 102.466 18.5044C102.466 19.7565 100.974 20.3131 99.4807 20.3131C98.1914 20.3131 96.4271 20.1044 95.0021 18.7131L92.2877 21.4957C94.3913 23.6522 96.7664 24 99.4128 24C103.349 24 106.538 22.1913 106.538 18.4348C106.538 15.3739 104.909 13.5652 101.584 13.2174Z"
            fill="#007DC3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M122.213 16.4174V14.5391C122.213 9.80871 119.567 6.33044 114.952 6.33044C110.609 6.33044 107.692 9.46088 107.692 15.0957C107.692 21.7739 111.22 23.7218 114.952 23.8609C115.088 23.8609 115.224 23.8609 115.427 23.8609C118.413 23.8609 120.11 22.887 121.806 21.1478L119.295 18.6435C118.21 19.7565 117.327 20.2435 115.495 20.2435C115.36 20.2435 115.156 20.2435 115.02 20.2435C112.985 20.0348 111.831 18.5044 111.831 16.4174H115.02H122.213V16.4174ZM112.102 11.6174C112.577 10.5739 113.46 9.80871 114.885 9.80871C116.31 9.80871 117.26 10.5739 117.667 11.6174C117.938 12.2435 118.006 12.7304 118.074 13.4261H114.885H111.695C111.763 12.7304 111.899 12.2435 112.102 11.6174Z"
            fill="#007DC3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M138.974 11.6174C140.128 11.0609 141.824 9.39131 141.824 6.74783C141.824 2.71304 139.246 0.13913 134.767 0.13913H133.885H125.606V23.6522H133.885H135.174C139.246 23.6522 142.163 21.287 142.163 16.9044C142.163 13.7739 140.399 12.313 138.974 11.6174ZM130.017 4.31304H133.817H134.36C136.26 4.31304 137.346 5.42609 137.346 7.02609C137.346 8.62609 136.26 9.73913 134.36 9.73913H133.817H130.017V4.31304ZM134.699 19.5478H133.885H130.085V13.7739H133.885H134.699C136.735 13.7739 137.685 15.0261 137.685 16.6261C137.685 18.2261 136.735 19.5478 134.699 19.5478Z"
            fill="#F78E1E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M150.442 6.26086H150.307C147.524 6.26086 145.896 6.81739 144.267 8.62608L146.914 11.2696C147.796 10.2261 148.542 9.87826 150.103 9.87826C150.171 9.87826 150.307 9.87826 150.374 9.87826C152.41 9.94782 153.224 10.7826 153.224 12.5913V13.4261H150.374H149.289C145.421 13.4261 143.589 15.6522 143.589 18.3652C143.589 19.8956 144.064 21.287 144.946 22.1913C145.964 23.2348 147.321 23.7217 149.221 23.7217C149.628 23.7217 150.035 23.7217 150.442 23.6522C151.664 23.4435 152.478 22.9565 153.36 22.0522V23.6522H157.432V12.313C157.432 8.34782 155.057 6.33043 150.442 6.26086ZM153.292 17.3217C153.292 18.3652 153.157 19.0609 152.682 19.4783C152.003 20.1043 151.392 20.2435 150.442 20.313C150.307 20.313 150.171 20.313 150.035 20.313C148.407 20.313 147.66 19.5478 147.66 18.3652C147.66 17.113 148.474 16.4174 150.035 16.4174H150.51H153.36L153.292 17.3217Z"
            fill="#F78E1E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M168.561 6.26086C167 6.26086 165.507 6.88695 164.353 8.06956V6.46956H160.282V23.6522H164.489V13.287C164.489 10.9217 165.982 10.1565 167.271 10.1565C168.561 10.1565 170.053 10.9217 170.053 13.287V23.6522H174.261V12.6609C174.261 10.7826 173.921 9.1826 172.564 7.7913C171.614 6.81739 170.257 6.26086 168.561 6.26086Z"
            fill="#F78E1E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M185.932 13.4261L191.904 6.46958H186.882L181.318 13.4957V0.20871H177.111V23.6522H181.318V18.5044L183.082 16.487L187.154 23.6522H192.379L185.932 13.4261Z"
            fill="#F78E1E"
          />
        </svg> -->

        <img src="../../../../../assets/image/logoic.png" alt="" />
      </a>
    </div>
    <nav class="main-nav">
      <ul>
        <li routerLink="qr" routerLinkActive="active">
          <a>{{ "userNavbar.buttons.qr" | translate }}</a>
        </li>

        <li
          routerLink="transactions"
          [queryParams]="{ page: '1', status: 'all' }"
          routerLinkActive="active"
        >
          <a>{{ "userNavbar.buttons.transactions" | translate }}</a>
        </li>
      </ul>
    </nav>
    <div class="additional">
      <div class="ad company first" tabindex="1">
        <div class="ico">
          <!-- <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 14.5V13C13 12.2044 12.6839 11.4413 12.1213 10.8787C11.5587 10.3161 10.7956 10 10 10H4C3.20435 10 2.44129 10.3161 1.87868 10.8787C1.31607 11.4413 1 12.2044 1 13V14.5"
              stroke="#007DC3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M7 7C8.65685 7 10 5.65685 10 4C10 2.34315 8.65685 1 7 1C5.34315 1 4 2.34315 4 4C4 5.65685 5.34315 7 7 7Z"
              stroke="#007DC3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg> -->
          <img
            [src]="replaceConverseImgLink(merchant?.merchantInfo?.logo)"
            alt="Logo"
          />
        </div>
        <div class="title t-company">
          {{ merchant?.merchantInfo?.companyNameEn }}
        </div>
        <div class="arrow">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6L8 10L12 6"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="nested n-company" style="min-width: 150px">
          <ul>
            <li routerLink="change-password">
              <a>
                {{ "userNavbar.buttons.changePassword" | translate }}
              </a>
            </li>
            <li routerLink="help">
              <a>
                {{ "userNavbar.buttons.help" | translate }}
              </a>
            </li>
            <li class="log-out" (click)="logout()">
              <a>
                {{ "userNavbar.buttons.logout" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="ad lang" tabindex="1">
        <div class="ico">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 7H13"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.99998 1C8.50074 2.64301 9.35363 4.77522 9.39998 7C9.35363 9.22478 8.50074 11.357 6.99998 13C5.49921 11.357 4.64632 9.22478 4.59998 7C4.64632 4.77522 5.49921 2.64301 6.99998 1V1Z"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="title t-lang">
          <div>
            <ng-container *ngIf="langText == 'en'">
              <svg
                class="flag"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <rect
                  x="1"
                  y="4"
                  width="30"
                  height="24"
                  rx="4"
                  ry="4"
                  fill="#071b65"
                ></rect>
                <path
                  d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
                  fill="#fff"
                ></path>
                <path
                  d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
                  fill="#fff"
                ></path>
                <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
                <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
                <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
                <rect
                  x="14"
                  y="1"
                  width="4"
                  height="30"
                  transform="translate(32) rotate(90)"
                  fill="#b92932"
                ></rect>
                <path
                  d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </ng-container>
            <ng-container *ngIf="langText == 'ru'">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flag"
                viewBox="0 0 32 32"
              >
                <path fill="#1435a1" d="M1 11H31V21H1z"></path>
                <path
                  d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  fill="#fff"
                ></path>
                <path
                  d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  transform="rotate(180 16 24)"
                  fill="#c53a28"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </ng-container>
            <ng-container *ngIf="langText == 'am'">
              <svg
                class="flag"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <path fill="#102f9b" d="M1 11H31V21H1z"></path>
                <path
                  d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  fill="#c82a20"
                ></path>
                <path
                  d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  transform="rotate(180 16 24)"
                  fill="#e8ad3b"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </ng-container>
          </div>
          <!-- {{ langText == "en" ? "EN" : langText == "ru" ? "RU" : "AM" }} -->
        </div>
        <div class="arrow">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6L8 10L12 6"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="nested n-lang">
          <ul>
            <li
              (click)="changeLang('en')"
              [ngClass]="{ active: langText == 'en' }"
              class="en"
            >
              <svg
                class="flag"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <rect
                  x="1"
                  y="4"
                  width="30"
                  height="24"
                  rx="4"
                  ry="4"
                  fill="#071b65"
                ></rect>
                <path
                  d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
                  fill="#fff"
                ></path>
                <path
                  d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
                  fill="#fff"
                ></path>
                <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
                <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
                <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
                <rect
                  x="14"
                  y="1"
                  width="4"
                  height="30"
                  transform="translate(32) rotate(90)"
                  fill="#b92932"
                ></rect>
                <path
                  d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </li>
            <li
              (click)="changeLang('ru')"
              [ngClass]="{ active: langText == 'ru' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flag"
                viewBox="0 0 32 32"
              >
                <path fill="#1435a1" d="M1 11H31V21H1z"></path>
                <path
                  d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  fill="#fff"
                ></path>
                <path
                  d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  transform="rotate(180 16 24)"
                  fill="#c53a28"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </li>
            <li
              (click)="changeLang('am')"
              [ngClass]="{ active: langText == 'am' }"
              class="am"
            >
              <svg
                class="flag"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <path fill="#102f9b" d="M1 11H31V21H1z"></path>
                <path
                  d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  fill="#c82a20"
                ></path>
                <path
                  d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  transform="rotate(180 16 24)"
                  fill="#e8ad3b"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ham" id="hamburger" (click)="openMenu()">
      <div class="header__burger" id="header__burger">
        <span></span>
      </div>
    </div>
  </header>
  <div class="menu" id="menu">
    <div class="header">
      <div class="c-logo">
        <img
          [src]="replaceConverseImgLink(merchant?.merchantInfo?.logo)"
          alt="Logo"
        />
      </div>

      <nav>
        <ul>
          <li routerLink="qr" routerLinkActive="active">
            <a>{{ "userNavbar.buttons.qr" | translate }}</a>
          </li>

          <li
            routerLink="transactions"
            [queryParams]="{ page: '1', status: 'all' }"
            routerLinkActive="active"
          >
            <a>{{ "userNavbar.buttons.transactions" | translate }}</a>
          </li>
        </ul>
      </nav>

      <div class="ad company" tabindex="1">
        <div class="ico">
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 14.5V13C13 12.2044 12.6839 11.4413 12.1213 10.8787C11.5587 10.3161 10.7956 10 10 10H4C3.20435 10 2.44129 10.3161 1.87868 10.8787C1.31607 11.4413 1 12.2044 1 13V14.5"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7 7C8.65685 7 10 5.65685 10 4C10 2.34315 8.65685 1 7 1C5.34315 1 4 2.34315 4 4C4 5.65685 5.34315 7 7 7Z"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="title t-company">
          {{ merchant?.merchantInfo?.companyNameEn }}
        </div>
        <div class="arrow">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6L8 10L12 6"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="nested n-company">
          <ul>
            <li routerLink="change-password">
              <a>
                {{ "userNavbar.buttons.changePassword" | translate }}
              </a>
            </li>
            <li routerLink="help">
              <a>
                {{ "userNavbar.buttons.help" | translate }}
              </a>
            </li>
            <li class="log-out" (click)="logout()">
              <a>
                {{ "userNavbar.buttons.logout" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="ad lang" tabindex="1">
        <div class="ico">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1 7H13"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.99998 1C8.50074 2.64301 9.35363 4.77522 9.39998 7C9.35363 9.22478 8.50074 11.357 6.99998 13C5.49921 11.357 4.64632 9.22478 4.59998 7C4.64632 4.77522 5.49921 2.64301 6.99998 1V1Z"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="title t-lang">
          <div>
            <ng-container *ngIf="langText == 'en'">
              <svg
                class="flag"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <rect
                  x="1"
                  y="4"
                  width="30"
                  height="24"
                  rx="4"
                  ry="4"
                  fill="#071b65"
                ></rect>
                <path
                  d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
                  fill="#fff"
                ></path>
                <path
                  d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
                  fill="#fff"
                ></path>
                <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
                <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
                <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
                <rect
                  x="14"
                  y="1"
                  width="4"
                  height="30"
                  transform="translate(32) rotate(90)"
                  fill="#b92932"
                ></rect>
                <path
                  d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </ng-container>
            <ng-container *ngIf="langText == 'ru'">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flag"
                viewBox="0 0 32 32"
              >
                <path fill="#1435a1" d="M1 11H31V21H1z"></path>
                <path
                  d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  fill="#fff"
                ></path>
                <path
                  d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  transform="rotate(180 16 24)"
                  fill="#c53a28"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </ng-container>
            <ng-container *ngIf="langText == 'am'">
              <svg
                class="flag"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <path fill="#102f9b" d="M1 11H31V21H1z"></path>
                <path
                  d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  fill="#c82a20"
                ></path>
                <path
                  d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  transform="rotate(180 16 24)"
                  fill="#e8ad3b"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </ng-container>
          </div>
        </div>
        <div class="arrow">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6L8 10L12 6"
              stroke="#007DC3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="nested n-lang">
          <ul>
            <li
              (click)="changeLang('en')"
              [ngClass]="{ active: langText == 'en' }"
              class="en"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flag"
                viewBox="0 0 32 32"
              >
                <rect
                  x="1"
                  y="4"
                  width="30"
                  height="24"
                  rx="4"
                  ry="4"
                  fill="#071b65"
                ></rect>
                <path
                  d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z"
                  fill="#fff"
                ></path>
                <path
                  d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z"
                  fill="#fff"
                ></path>
                <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
                <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
                <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
                <rect
                  x="14"
                  y="1"
                  width="4"
                  height="30"
                  transform="translate(32) rotate(90)"
                  fill="#b92932"
                ></rect>
                <path
                  d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z"
                  fill="#b92932"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </li>
            <li
              (click)="changeLang('ru')"
              [ngClass]="{ active: langText == 'ru' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flag"
                viewBox="0 0 32 32"
              >
                <path fill="#1435a1" d="M1 11H31V21H1z"></path>
                <path
                  d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  fill="#fff"
                ></path>
                <path
                  d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  transform="rotate(180 16 24)"
                  fill="#c53a28"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </li>
            <li
              (click)="changeLang('am')"
              [ngClass]="{ active: langText == 'am' }"
              class="am"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flag"
                viewBox="0 0 32 32"
              >
                <path fill="#102f9b" d="M1 11H31V21H1z"></path>
                <path
                  d="M5,4H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  fill="#c82a20"
                ></path>
                <path
                  d="M5,20H27c2.208,0,4,1.792,4,4v4H1v-4c0-2.208,1.792-4,4-4Z"
                  transform="rotate(180 16 24)"
                  fill="#e8ad3b"
                ></path>
                <path
                  d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
                  opacity=".15"
                ></path>
                <path
                  d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
                  fill="#fff"
                  opacity=".2"
                ></path>
              </svg>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
