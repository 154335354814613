import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { MerchantIdTitle } from "src/app/models/merchantIdTitle";
import { Transactions } from "src/app/models/transactions.model";
import { TransactionsToExcel } from "src/app/models/transactionsToExcel";
import { DataExchangeService } from "src/app/services/dataExchange.service";
import { GetMerchantForUserService } from "src/app/services/getMerchantForUser.service";
import { ShowTransactionsService } from "src/app/services/showTransactions.service";
import { saveAs } from "file-saver";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { SmsService } from "src/app/services/sms.service";
import { MerchantUser } from "src/app/models/merchantUser.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { EposService } from "src/app/services/eposVTB/epos.service";
import { HttpErrorResponse } from "@angular/common/http";

enum fields {
  transactionId = 1,
  createDate,
  transactionDate,
  bankTid,
  amount,
  authCode,
  appName,
  maskedPan,
  transactionStatus,
  comment,
  cardHolderName,
}

@Component({
  selector: "app-user-transaction",
  templateUrl: "./user-transaction.component.html",
  styleUrls: ["./user-transaction.component.css"],
})
export class UserTransactionComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  merchants: MerchantIdTitle[];
  transactions: Transactions[];
  currentTransaction: Transactions;
  transactionsForDateFilter: Transactions[];
  dataExchange: Subscription;
  changedId: MerchantIdTitle;
  merchantId: number;
  merchantIdSubscription: Subscription;
  merchantIdSubscriptionCheck: boolean;
  paidUnpaid: number;
  showData: Transactions;
  transactionCount: number = 0;
  showLoader: boolean = false;
  blob: Blob;
  excelTransactionModel: TransactionsToExcel = new TransactionsToExcel();
  modalIf: boolean = false;
  selectedOption: boolean = false;

  addCardHolderName: boolean = false;
  inqDate: boolean = false;
  payerApp: boolean = true;
  comment: boolean = true;
  state: boolean = true;
  cardType: boolean = true;

  sortToggle: boolean = false;
  showModalInfo: boolean = false;
  dateFromTo: FormGroup;
  emailSend: FormGroup;
  errorSendMailSms: boolean = false;
  sendedSms: boolean = false;
  disableEmailButton: boolean = false;
  canReverseMerchant: boolean = false;
  canRefundMerchant: boolean = false;
  refverseErrorMsg: boolean = false;
  getNotification: Subscription;

  page: number = 1;
  tin: string;
  legalAddress: string;

  errorRefund: boolean = false;
  sendedRefund: boolean = false;
  disableRefundButton: boolean = false;
  refundSend: FormGroup;

  tzoffset = new Date().getTimezoneOffset() * 60000;
  localISOTime = new Date(Date.now() - this.tzoffset)
    .toISOString()
    .slice(0, -1);
  curdate: any = this.localISOTime;
  curStatus: number;

  dateFilters = {
    start: "2018-03-24T10:49:00.795Z",
    end: this.curdate,
  };

  constructor(
    private dataEx: DataExchangeService,
    private getMerFUser: GetMerchantForUserService,
    private shTrSer: ShowTransactionsService,
    private formBuilder: FormBuilder,
    private sms: SmsService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private eposService: EposService
  ) {}

  @ViewChild("downloadExcel") downloadExcel: ElementRef;
  @ViewChild("infoModal") infoModal: ElementRef;
  @ViewChild("myPaginator") myPaginator: MatPaginator;
  @ViewChild("paidUnpaid_s") paidUnpaid_s: ElementRef;
  @ViewChild("reverseModal") reverseModal: ElementRef;
  @ViewChild("disableReverseBtn") disableReverseBtn: ElementRef;

  // myPaginator: MatPaginator

  ngAfterViewInit(): void {
    // let pageCount = this.transactionCount / 50 + 1
    // this.myPaginator.pageIndex = this.page - 1
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      this.getNotification = this.dataEx.getNotification().subscribe(
        (res) => {
          console.log("qr res", res);
          if (res == "true") {
            this.page = 1;
            this.ngOnInit();
          }
        },
        (err) => {
          let errmer = err.error.message;
          this._snackBar.open(errmer, "", {
            duration: 2000,
          });
        }
      );
      this.getLocalStorageFields();
      if (
        res["page"] * 1 <= 0 ||
        !Number.isInteger(res["page"] * 1) ||
        res["page"] == undefined
      )
        this.page = 1;
      else this.page = res["page"] * 1;
      // if(this.page > this.transactionCount)
      // {
      //   this.page = this.transactionCount
      // }
      this.builForm();
      let status =
        res["status"] == "paid"
          ? 2
          : res["status"] == "unpaid"
          ? 0
          : res["status"] == "reverse"
          ? 3
          : res["status"] == "refund"
          ? 4
          : 5;
      this.curStatus = status;
      let el = document.getElementById("select-type") as HTMLSelectElement;
      el.value = status.toString();
      const get_id_promise = new Promise((reslove, reject) => {
        let id = localStorage.getItem("id");
        reslove(id);
      });
      get_id_promise.then((data) => {
        this.getMerFUser.getMerchantUser(data).subscribe((res) => {
          this.merchants = res["merchants"];
          this.canReverseMerchant = res["canReverse"];
          this.canRefundMerchant = res["canRefund"];
          // console.log(this.canReverseMerchant);
          this.getTransactionsFuction(this.dataEx.retId(), status, this.page);
        });
      });
      // this.merchantIdSubscription = this.dataEx.getMerId().subscribe(
      //   res => {
      //     this.merchantId = res
      //     this.getTransactionsFuction(this.merchantId)
      //   }
      // );
      this.dataExchange = this.dataEx.getMerchantTr().subscribe((res) => {
        this.changedId = res;
        this.selectedOption = true;
        this.getTransactionsFuction(this.changedId.id * 1, status, this.page);
      });
    });
  }

  setToLocalStorage() {
    let locobj = {
      addCardHolderName: this.addCardHolderName,
      inqDate: this.inqDate,
      payerApp: this.payerApp,
      comment: this.comment,
      state: this.state,
      cardType: this.cardType,
    };
    let str = JSON.stringify(locobj);
    localStorage.setItem("fields_u", str);
  }

  getLocalStorageFields() {
    if (localStorage.getItem("fields_u")) {
      let l_str = JSON.parse(localStorage.getItem("fields_u"));
      this.addCardHolderName = l_str.addCardHolderName;
      this.inqDate = l_str.inqDate;
      this.payerApp = l_str.payerApp;
      this.comment = l_str.comment;
      this.state = l_str.state;
      this.cardType = l_str.cardType;
    } else return;
  }

  getTransactionsFuction(id: number, status, page) {
    this.showLoader = true;
    this.myPaginator.pageIndex = this.page - 1;
    this.getMerFUser
      .getTransactions(id, status, page, this.dateFilters)
      .subscribe(
        (res) => {
          this.transactions = res.body["transactions"] as Transactions[];
          this.tin = res.body["tin"] as string;
          this.legalAddress = res.body["addressEn"] as string;
          this.transactionCount = parseInt(res.headers.get("count"));
          // this.transactions.sort(function(a,b){
          //   return new Date(a.createDate).valueOf() - new Date(b.createDate).valueOf();
          //   return a.createDate.getTime() - b.createDate.getTime()
          // })
          // this.transactions.sort((b, a) => (new Date(a.createDate).getTime()  > new Date(b.createDate).getTime()) ? 1 : (new Date(a.createDate).getTime() === new Date(b.createDate).getTime()) ? ((new Date(a.createDate).getTime() > new Date(b.createDate).getTime()) ? 1 : -1) : -1 )
          this.transactions.sort((b, a) =>
            new Date(a.createDate).getTime() > new Date(b.createDate).getTime()
              ? 1
              : -1
          );
          this.excelTransactionModel.transactions = this.transactions;
          this.transactions.forEach((value, index) => {
            value["id"] = this.transactions.length - index;
          });
          this.transactionsForDateFilter = this.transactions;
          this.showLoader = false;
        },
        (err) => {
          if (this.transactions)
            this.transactions.splice(0, this.transactions.length);
          let errmer = err?.error?.message;
          this._snackBar.open(errmer, "", {
            duration: 2000,
          });
          this.showLoader = false;
        }
      );
  }
  selectPaidUnpaid(val: number) {
    // this.showLoader = true;
    this.excelTransactionModel.transactions = [];
    this.selectedOption = false;
    this.page = 1;
    let trId =
      this.changedId == undefined ? this.dataEx.retId() : this.changedId.id;
    let queryParams = {};
    if (val * 1 == 2) queryParams["status"] = "paid";
    else if (val * 1 == 0) queryParams["status"] = "unpaid";
    else if (val * 1 == 3) queryParams["status"] = "reverse";
    else if (val * 1 == 4) queryParams["status"] = "refund";
    else queryParams["status"] = "all";

    queryParams["page"] = 1;

    this.router.navigate([], {
      queryParamsHandling: "merge",
      relativeTo: this.route,
      queryParams: queryParams,
    });
    this.getTransactionsFuction(trId, val * 1, this.page);
  }
  sendData(data: Transactions) {
    this.modalIf = true;
    this.showData = data;
  }
  openReciept(data: Transactions) {
    this.currentTransaction = data;
    this.showModalInfo = true;
  }

  checkTransactionStatus(transaction) {
    this.shTrSer
      .checkStatus(transaction.arcaOrderId)
      .toPromise()
      .finally(() => location.reload());
  }

  closeModal() {
    this.refverseErrorMsg = false;
    this.infoModal.nativeElement.click();
  }

  reverseTransaction(obj: Transactions) {
    this.refverseErrorMsg = false;
    // this.disableReverseBtn.nativeElement
    this.shTrSer.reverseTransaction(obj.transactionId).subscribe(
      (res) => {
        location.reload();
      },
      (err) => {
        this.refverseErrorMsg = true;
      }
    );
  }

  sortByDate() {
    this.sortToggle = !this.sortToggle;
    if (this.sortToggle)
      this.transactions.sort((a, b) =>
        new Date(a.createDate).getTime() > new Date(b.createDate).getTime()
          ? 1
          : -1
      );
    else
      this.transactions.sort((b, a) =>
        new Date(a.createDate).getTime() > new Date(b.createDate).getTime()
          ? 1
          : -1
      );
  }

  createExcelModel() {
    this.excelTransactionModel.keys.set("A", {
      name: "Order ID",
      status: true,
      id: fields.transactionId,
    });
    this.excelTransactionModel.keys.set("B", {
      name: "Request date",
      status: this.inqDate,
      id: fields.createDate,
    });
    this.excelTransactionModel.keys.set("C", {
      name: "Payment date",
      status: true,
      id: fields.transactionDate,
    });
    this.excelTransactionModel.keys.set("D", {
      name: "Department",
      status: this.state,
      id: fields.bankTid,
    });
    this.excelTransactionModel.keys.set("E", {
      name: "Amount",
      status: true,
      id: fields.amount,
    });
    this.excelTransactionModel.keys.set("F", {
      name: "Authorization code",
      status: true,
      id: fields.authCode,
    });
    this.excelTransactionModel.keys.set("G", {
      name: "Payer app",
      status: this.payerApp,
      id: fields.appName,
    });
    this.excelTransactionModel.keys.set("H", {
      name: "Card number",
      status: true,
      id: fields.maskedPan,
    });
    this.excelTransactionModel.keys.set("I", {
      name: "Card type",
      status: this.cardType,
      id: fields.maskedPan,
    });
    this.excelTransactionModel.keys.set("J", {
      name: "Status",
      status: true,
      id: fields.transactionStatus,
    });
    this.excelTransactionModel.keys.set("K", {
      name: "Comments",
      status: this.comment,
      id: fields.comment,
    });
    this.excelTransactionModel.keys.set("L", {
      name: "Card Holder Name",
      status: this.addCardHolderName,
      id: fields.cardHolderName,
    });
    const convMap = {};
    this.excelTransactionModel.keys.forEach((val: string, key: string) => {
      convMap[key] = val;
    });
    let obj = {
      keys: convMap,
      transactions: this.excelTransactionModel.transactions,
    };
    let trId =
      this.changedId == undefined ? this.dataEx.retId() : this.changedId.id;

    let body = {
      id: trId,
      keys: convMap,
      page: 1,
      count: 10000,
      status: this.curStatus,
      start: this.dateFilters.start,
      end: this.dateFilters.end,
    };
    this.shTrSer.endTransactionsForExcelNew(body).subscribe((res) => {
      let currentDate = new Date();
      let dformat =
        [
          currentDate.getMonth() + 1,
          currentDate.getDate(),
          currentDate.getFullYear(),
        ].join("/") +
        " " +
        [
          currentDate.getHours(),
          currentDate.getMinutes(),
          currentDate.getSeconds(),
        ].join(":");
      // saveAs(res)
      let blob = new Blob([res], { type: "application/octet-stream" });
      let url = window.URL.createObjectURL(blob);
      let anchor = document.createElement("a");
      anchor.download = `PayXTransactions-${dformat}.xlsx`;
      anchor.href = url;
      anchor.click();
    });
  }

  getFullYear(date: string) {
    // console.log(date)
    let year = new Date(date);
    return year.getFullYear();
  }

  builForm() {
    this.dateFromTo = this.formBuilder.group({
      dateRange: new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
      }),
    });
    this.emailSend = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.refundSend = this.formBuilder.group({
      amount: ["", [Validators.required]],
    });
  }

  refundAmount() {
    this.disableRefundButton = true;
    this.sendedRefund = false;
    this.errorRefund = false;
    this.eposService
      .refund(
        this.refundSend.get("amount").value,
        this.currentTransaction.transactionId
      )
      .subscribe(
        (res) => {
          this.sendedRefund = true;
          this.disableRefundButton = false;
          this.ngOnInit();
          setTimeout(() => {
            this.sendedRefund = false;
          }, 5000);
        },
        (err) => {
          this.errorRefund = true;
          this.disableRefundButton = false;
          setTimeout(() => {
            this.errorRefund = false;
          }, 5000);
        }
      );
  }

  sendSMS() {
    this.disableEmailButton = true;
    this.sendedSms = false;
    this.errorSendMailSms = false;
    this.sms
      .sendSMSEmail(
        this.currentTransaction.hashOrderId,
        this.emailSend.get("email").value
      )
      .subscribe(
        (res) => {
          this.sendedSms = true;
          this.disableEmailButton = false;
          setTimeout(() => {
            this.sendedSms = false;
          }, 5000);
        },
        (err) => {
          this.errorSendMailSms = true;
          this.disableEmailButton = false;
          setTimeout(() => {
            this.errorSendMailSms = false;
          }, 5000);
        }
      );
  }

  handlePageEvent(e: PageEvent) {
    this.page = e.pageIndex + 1;
    this.goNextPreviousPage(this.page);
    // console.log('pageIndex', e.pageIndex)
    // console.log('previousPageIndex', e.previousPageIndex)
    // console.log('pageSize', e.pageSize)
    // console.log('length', e.length)
  }

  goNextPreviousPage(currPage) {
    const queryParams: Params = { page: `${currPage}` };
    this.router.navigate([], {
      queryParamsHandling: "merge",
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  showDataButton() {
    let start = new Date(this.dateFromTo.get("dateRange").get("start").value);
    let end = new Date(this.dateFromTo.get("dateRange").get("end").value);

    if (start.getFullYear() == 1970 && end.getFullYear() == 1970) {
      return;
    } else {
      if (end.getFullYear() == 1970) {
        end = new Date(start);
        end.setDate(start.getDate());
      }
      end.setDate(end.getDate() + 2);
      this.dateFilters.start = start.toISOString();
      this.dateFilters.end = end.toISOString();
      this.filter();
    }
  }

  filter() {
    let queryParams = {};
    queryParams["start"] = this.dateFilters.start;
    queryParams["end"] = this.dateFilters.end;
    queryParams["page"] = 1;
    this.router.navigate([], {
      queryParamsHandling: "merge",
      relativeTo: this.route,
      queryParams: queryParams,
    });
    // this.closeModal.nativeElement.click()
  }

  resetDate() {
    this.dateFromTo.get("dateRange").get("start").reset();
    this.dateFromTo.get("dateRange").get("end").reset();
    this.dateFilters.start = "2018-03-24T10:49:00.795Z";
    this.dateFilters.end = this.curdate;
    this.filter();
    // this.transactionsForDateFilter = this.transactions;
  }

  blockTransaction() {
    this.shTrSer
      .blockTransaction(this.currentTransaction.transactionId)
      .subscribe((res) => {
        this.reverseModal.nativeElement.click();
        this.ngOnInit();
      });
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.infoModal?.nativeElement?.click();
    this.reverseModal?.nativeElement?.click();
  }

  ngOnDestroy() {
    this.infoModal.nativeElement.click();
    this.reverseModal.nativeElement.click();
    this.dataExchange.unsubscribe();
    // this.merchantIdSubscription.unsubscribe()
  }
}
