<div class="wrapper">
  <div class="con">
    <header>
      <a class="logo" href="https://www.conversebank.am">
        <img src="../../../../../assets/image/login/logo.png" alt="">
      </a>
      <div class="l">
        <div class="ad lang" tabindex="1">
          <div class="ico">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                stroke="#007DC3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M1 7H13" stroke="#007DC3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M6.99998 1C8.50074 2.64301 9.35363 4.77522 9.39998 7C9.35363 9.22478 8.50074 11.357 6.99998 13C5.49921 11.357 4.64632 9.22478 4.59998 7C4.64632 4.77522 5.49921 2.64301 6.99998 1V1Z"
                stroke="#007DC3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div class="title t-lang">{{langText == 'en' ? 'Eng' : langText == 'ru' ? 'Rus': 'Arm'}}</div>
          <div class="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path d="M1 1L5 5L9 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>

          <div class="nested n-lang">
            <ul>
              <li (click)="changeLang('en')" [ngClass]="{'active': langText == 'en'}" class="en">Eng</li>
              <li (click)="changeLang('ru')" [ngClass]="{'active': langText == 'ru'}">Rus</li>
              <li (click)="changeLang('am')" [ngClass]="{'active': langText == 'am'}" class="am">Arm</li>
            </ul>
          </div>
        </div>
      </div>
    </header>

    <section class="main">
      <div class="left">
        <p>QR Thing Title Here</p>
        <div class="big-text">
          <h1>Important principles <br /> may, and must, be <br /> <span style="color: #F68D1E;">inflexible</span>.</h1>
        </div>
        <p style="font-size: 13px;">To live a pure unselfish life, one must count nothing as ones own in the midst of
          abundance.</p>
      </div>
      <div class="right">
        <form (ngSubmit)="onSubBtnClick()">
          <div class="title">Admin Login</div>
          <input type="text" placeholder="Username" [ngModelOptions]="{standalone: true}" [(ngModel)]="login.username">
          <input type="password" placeholder="Password" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="login.password">
          <div class="wrong-password-or-login" [ngClass]="{'invalid':!loginIsValid}">Incorrect username or password
          </div>
          <div class="terms">By login, you agree to our <span class="t">Terms & Conditions</span></div>
          <button type="submit">Login</button>
          <div class="forgot-password"><span>Forgot password?</span> </div>
        </form>
      </div>
    </section>

    <footer>
      <p>© {{date.getFullYear()}} Conversebank. All rights reserved.</p>
    </footer>

  </div>
</div>
