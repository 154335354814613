<header>
  <ul>
    <!-- <li routerLink="banks" routerLinkActive="active">
      <a>Banks</a>
    </li> -->
    <li routerLink="domains" routerLinkActive="active">
      <a>Domains</a>
    </li>
    <!-- <li routerLink="mcc" routerLinkActive="active">
      <a>Percentage</a>
    </li> -->
    <li routerLink="groups" routerLinkActive="active">
      <a>Groups</a>
    </li>
    <!-- <li routerLink="report" routerLinkActive="active">
      <a>Report</a>
    </li> -->
    <li routerLink="employees" routerLinkActive="active">
      <a>Employees</a>
    </li>
    <li routerLink="parters" routerLinkActive="active">
      <a>HostX Partners</a>
    </li>
  </ul>
</header>
