<div class="wrapper">
  <div class="text-hy" *ngIf="currentLang == 0">
    <b>
      1) Ինչու՞ է պետք գրանցվել
    </b>
    <br>
    Գրանցումն անհրաժեշտ է՝ Կոնվերս Բանկ միասնական ենթակառուցվածքի առևտրի և սպասարկման կետ (այսուհետ՝ առևտրային կետը)
    հանդիսանալու և համակարգի օգտատերերից վճարումներ ընդունելու համար։
    <br>
    <br>

    <b>
      2) Ինչպե՞ս գրանցվել հավելվածում
    </b>
    <br>
    Գրանցման համար անհրաժեշտ է "Կոնվերս Բանկ" ՓԲԸ-ի հետ կնքել համապատասխան պայմանագիր։ Հավելյալ տեղեկատվության համար
    կարող եք այցելել բանկի պաշտոնական կայք <a target="_blank"
      href="https://www.conversebank.am">https://conversebank.am/</a> կամ մոտենալ որևէ մասնաճյուղ։
    <br>
    <br>

    <b>
      3) Հնարավո՞ր է միաժամանակ օգտվել հավելվածից տարբեր սարքերով, բայց նույն
      մուտքանունով:
    </b>
    <br>
    Նույն մուտքանունով մի քանի սմարթ հեռախոսից միաժամանակ օգտվել հնարավոր չէ, բացառությամբ սմարթ հեռախոսով և
    <a target="_blank" href="/">www.pay.conversebank.am</a> կայքով զուգահեռ մուտքի:
    <br>
    <br>

    <b>
      4) Հավելվածից օգտվելու համար արդյոք կա՞ միջնորդավճար
    </b>
    <br>

    Գործարքներից միջնորդավճարը սահմանվում է սպասարկող բանկի կողմից և գանձվում է Առևտրային կետից։
    <br>
    <br>

    <b>
      5) Հնարավո՞ր է ընդունել վճարումներ առանց քարտի ֆիզիկական
      առկայության
    </b>
    <br>

    Այո, հնարավոր է։ C-PAY-ը հնարավորություն է ընձեռում գործարքի պահին վճարումներ ընդունել առանց բանկային քարտի
    ֆիզիկապես առկայության։ C-PAY-ով վճարումներն ընդունվում են և՛ տեղում՝ QR կոդի գեներացման միջոցով, և՛ հեռահար՝ վճարման
    հարցում ուղարկելու միջոցով:
    <br>
    <br>

    <b>
      6) Ինչպե՞ս են իրականացվում վճարումները
    </b>
    <br>
    Վճարում ընդունելու համար C-PAY հավելվածի գլխավոր էջում պետք է մուտքագրել գումարը, անհրաժեշտության դեպքում «Նշում»
    դաշտում կատարել գրառում, որից հետո սեղմել «Ստանալ վճարում» ստեղնը։ Հեռախոսի էկրանին գեներացվում է QR կոդ, որը կարող
    է ներկայացվել վճարողին․
    <br>
    6.1. Տեղում վճարելու տարբերակով՝ որի համար գնորդը իր սմարթ հեռախոսի համապատասխան հավելվածով ընթերցում է QR կոդը և
    կատարում վճարումը։
    <br>
    6.2. Վճարման հարցում ուղարկելու տարբերակով, որի համար անհրաժեշտ է սեղմել «Ուղարկել վճարողին» ստեղնը և վճարման
    հարցումը ուղարկել հետևյալ տարբերակներով.
    <br>
    6.2.1. եթե վճարողը հանդիսանում է Converse Mobile հավելվածի օգտատեր, ապա անհրաժեշտ է մուտքագրել նրա հեռախոսահամարը և
    սեղմել «Որոնել Converse Mobile » ստեղնը։ Պետք է ստուգել վճարողի անունը, որը հայտնվում է հեռախոսի էկրանին և սեղմել
    «Հաստատել» ստեղնը.
    <br>
    6.2.2. եթե վճարողը չի հանդիսանում Converse Mobile հավելվածի օգտատեր, ապա անհրաժեշտ է մուտքագրել նրա հեռախոսահամարը և
    սեղմել հղումը «SMS»-ով ուղարկելու ստեղնը՝ վճարման հարցման հղումը հաղորդագրության տեսքով ուղարկելու համար։
    <br>
    6.2.3. եթե վճարողը չի հանդիսանում Converse Mobile հավելվածի օգտատեր, ապա պետք է սեղմել հղումը ուղարկելու ստեղնը և
    վճարման հարցման հղումը ուղարկել հաղորդագրության տեսքով՝ WhatsApp-ի, Viber-ի, Messenger-ի, էլ․ փոստի և այլ նմանատիպ
    գործիքների միջոցով։
    <br>
    <br>

    <b>
      7) Ինչպե՞ս հասկանալ վճարումն հաջողությամբ իրականացվել է, թե ոչ
    </b>
    <br>
    Գործարքի կատարումից հետո հեռախոսի էկրանին հայտնվում է գործարքի կատարման, կամ մերժման վերաբերյալ հաղորդագրություն։
    Բացի այդ, հավելվածի հիմնական մենյուի «Գործարքներ» բաժնի “Վճարված’’ ենթաբաժնում արտացոլվում են բոլոր գործարքները՝ ըստ
    վճարման եղանակի, իսկ “Չվճարված’’ ենթաբաժնում արտաբերված են ուղարկված վճարային հարցումները:
    <br>
    <br>


    <b>
      8) Ինչպե՞ս վարվել, եթե պահանջվում է կատարել գնված ապրանքի վերադարձ
    </b>
    <br>
    Գնված ապրանքի վերադարձը կամ գործարքի կասեցումը իրականացվում է անկանխիկ՝ Առևտրային կետի «ադմինիստրատոր»-ի
    մասնակցությամբ։ Եթե ապրանքի վերադարձը կամ գործարքի կասեցումը իրականացնելու հետ կապված խնդիրներ են առաջանում, ապա
    անհրաժեշտ է դիմել բանկին:
    <br>
    <br>


    <b>
      9)Ե՞րբ է գործարքի գումարը փոխանցվում առևտրային կետի հաշվեհամարին
    </b>
    <br>
    Կատարված գործարքի գումարը բանկը փոխանցում է առևտրային կետի հաշվեհամարին՝ սպասարկման պայմանագրում սահմանված
    ժամկետում։

  </div>

  <div class="text-ru" *ngIf="currentLang == 1">
    <b>
      1) Почему необходима регистрация?
    </b>
    <br>
    Регистрация в объединенной инфраструктуре Конверс Банка необходима для того, чтобы стать точкой обслуживания (далее
    Торговой точкой) для приема платежей от пользователей системы.
    <br>
    <br>

    <b>
      2) Как зарегистрироваться в приложении?
    </b>
    <br>
    Для регистрации необходимо заключить соответствующий договор с ЗАО «Конверс Банк». Для получения дополнительной
    информации вы можете посетить официальный сайт Банка <a target="_blank"
      href="https://www.conversebank.am">https://conversebank.am/</a> или любое отделение.
    <br>
    <br>

    <b>
      3) Можно ли одновременно использовать приложение с разных устройств одним и тем
      же именем пользователя?
    </b>
    <br>
    Использование приложения одним и тем же именем пользователя с нескольких мобильных телефонов одновременно
    невозможно, однако возможно параллельное использование приложения через мобильный телефон и через веб-страницу
    <a target="_blank" href="/">www.pay.conversebank.am</a>.
    <br>
    <br>

    <b>
      4) Есть ли комиссия за использование приложения?
    </b>
    <br>

    Комиссионная плата за транзакции определяется Банком и взимается с Торговой точки.
    <br>
    <br>

    <b>
      5) Можно ли принимать платежи без присутствия карты?
    </b>
    <br>

    Да, это возможно. C-PAY дает возможность принимать платежи без присутствия карты. С C-PAY платежи можно получать на
    месте, генерируя QR-код или на расстоянии отправляя запрос на оплату по ссылке.
    <br>
    <br>

    <b>
      6) Как осуществляются платежи?
    </b>
    <br>
    Чтобы получить платеж, необходимо ввести сумму на главной странице приложения C-PAY, при необходимости добавить
    комментарий в поле «Заметки», после чего нажать кнопку «Получить платеж». На экране мобильного телефона
    сгенерируется QR-код, который может быть представлен покупателю следующими способами:

    <br>
    6.1. Опция оплата на месте: для осуществления платежа, плательщик считывает QR-код и совершает оплату с помощью
    соответствующего мобильного приложения.
    <br>
    6.2. Опция оплаты по ссылке: Торговая точка генерирует ссылку на оплату, нажав на кнопку «Отправить плательщику»,
    для отправки запроса на оплату следующими способами:
    <br>

    6.2.1. Если плательщик является пользователем приложения Converse Mobile, то необходимо ввести его номер мобильного
    телефона и нажать кнопку «Поиск Converse Mobile». Необходимо проверить имя плательщика, которое отображается на
    экране мобильного телефона, а затем нажать кнопку «Отправить».
    <br>

    6.2.2. Если плательщик не является пользователем приложения Converse Mobile, то необходимо ввести его номер
    мобильного телефона и нажать кнопку со знаком «SMS», чтобы отправить запрос на оплату в виде текстового сообщения.
    <br>

    6.2.3. Если плательщик не является пользователем приложения Converse Mobile, необходимо нажать кнопку со знаком
    «Поделиться» и отправить запрос на оплату в виде сообщения через SMS, WhatsApp, Viber, Messenger и т. д.
    <br>
    <br>

    <b>
      7) Как узнать, платеж успешно осуществлен или нет?
    </b>
    <br>
    После выполнения операции на экране мобильного телефона появляется сообщение о подтверждении платежа или об отмене
    операции. Более того, на главной странице меню в разделе «Транзакции» отражены все действия по способу оплаты, а в
    подразделе «Неоплаченные» отраженa отправленные запросы на оплату.
    <br>
    <br>

    <b>
      8) Как поступить в случае возврата купленного товара? </b>
    <br>
    Возврат купленного товара или отмена транзакции осуществляется безналично «администратор»-ом Торговой точки. При
    возникновении проблем, связанных с возвратом товара или отменой транзакции, необходимо обратиться в банк.

    <br>
    <br>


    <b>
      9) Когда сумма транзакции зачисляется на счет Торговой точки?
    </b>
    <br>
    Сумма подтвержденной транзакции зачисляется банком на счет Торговой точки в сроки, установленные в сервисном
    договоре.
  </div>

  <div class="text-en" *ngIf="currentLang == 2">
    <b>
      1) Why registration is required?
    </b>
    <br>
    Registration is required at Converse Bank unified infrastructure in order to become a merchant (hereinafter-
    merchant) for receiving payments from the system users.
    <br><br>

    <b>
      2) How to register on app?
    </b>
    <br>
    For registration, it is necessary to sign an appropriate contract with "Converse Bank" CJSC. For additional
    information, you can visit the bank's official website <a target="_blank"
      href="https://www.conversebank.am">https://conversebank.am/</a>
    or any branch.
    <br><br>

    <b>
      3) Is it possible to use this app from different devices at the same time, using the same
      username?
    </b>
    <br>
    It’s impossible to use this app with the same username from a number of different smartphones' at the same time,
    however it's possible to log in via а smartphone and via website
    <a target="_blank" href="/">www.pay.conversebank.am</a> parallelly.
    <br><br>

    <b>
      4) Is there a fee for using the application?
    </b>
    <br>
    The fee from transactions is defined by The Bank and is charged from the merchant.
    <br><br>

    <b>
      5) Is it possible to receive card-not-present payments ?
    </b>
    <br>
    Yes, it’s possible. C-PAY gives an opportunity to receive card-not-present payments. The payments by C-PAY are
    accepted on spot by generating QR code or on distance by sending the payment link.
    <br><br>

    <b>
      6) How the payments are processing?
    </b>
    <br>
    In order to receive a payment, input the amount on the home page of the C-PAY application, write a note in the
    “Comment” field, if nessecary. Then, click on the “Receive payment” button. On the mobile screen a QR code will be
    generated, which can be presented to the buyer in the following ways:
    <br>
    6.1. Paying on spot option: when a buyer scans the QR code and pays with his appropriate smartphone application.
    <br>

    6.2. A payment link option: the merchant generates a payment link by clicking on the
    “Send to the payer” button in order to send a payment request in the following ways:

    <br>

    6.2.1. If the payer is a Converse Mobile application user it is simply required to input the customer’s mobile
    number and to click on the “Converse Mobile Search” button. Check the payer’s name, which appears on the mobile
    screen and then click on the “Submit” button.
    <br>

    6.2.2. If the payer isn’t a Converse Mobile application user it is simply required to input the payer’s mobile
    number and to press “SMS” button to send the payment request as a text message.
    <br>

    6.2.3. If the payer isn’t a Converse Mobile application user, then it’s required to click on the “Share” button and
    to send the payment request as a message via SMS, WhatsApp, Viber, Messenger etc.
    <br><br>

    <b>
      7) How do we understand whether the payment has successfully been done or not?
    </b>
    <br>
    After the operation if performed a confirmation or cancellation message appears on the mobile screen. Moreover, on
    the main page of the menu at the “Transactions” section all the operations are reflected according to the payment
    way and at the “Unpaid” section is reflected the sent payment requests.
    <br><br>

    <b>
      8) What to do in case of a return of a bought product?
    </b>
    <br>
    The bought product refund or the transaction cancellation should be done in non-cash by merchant’s “administrator”.
    In case of a problem connected with the product refund or with a transaction cancellation it’s required to apply to
    The Bank.
    <br><br>

    <b>
      9) When the transaction amount is transferred to the trading point's account?
    </b>
    <br>
    The confirmed transaction amount is transferred to the merchant's account by The Bank in timeframe defined in the
    service agreement.
  </div>
</div>