import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Transactionfilter } from "../models/transactionfilter";

@Injectable({
  providedIn: "root",
})
export class ShowTransactionsService {
  constructor(private http: HttpClient) {}
  getTransactions(id) {
    return this.http.get(`/api/Payment/GetTransactions`);
  }
  getTransaction(id) {
    return this.http.get(`/api/Payment/GetTransaction?transferId=${id}`, {
      observe: "response",
    });
  }
  endTransactionsForExcel(body) {
    // const headers = new HttpHeaders().set('Content-Type', 'xlxx');
    return this.http.post(`/api/Payment/GetTransactionsForExcel`, body, {
      responseType: "blob",
    });
  }
  endTransactionsForExcelNew(body) {
    return this.http.post(`/api/Payment/GetTransactionsForUserExcel`, body, {
      responseType: "blob",
    });
  }
  getTransactionForAdmin() {
    return this.http.get(`/api/Payment/GetTransactionsAdmin`, {
      observe: "response",
    });
  }

  reverseTransaction(id: number) {
    const params = new HttpParams().set("transactionId", id.toString());
    return this.http.post(`/api/Payment/Reverse`, id, {
      observe: "response",
      params: params,
    });
  }

  getTransactionByPage(page, count) {
    return this.http.get(
      `/api/Payment/GetTransactionsByPage?page=${page}&count=${count}`,
      {
        observe: "response",
      }
    );
  }
  getTransactionFilterByPage(page, count, filters) {
    let body: Transactionfilter = filters;
    return this.http.post(
      `/api/Payment/SearchTransactions?page=${page}&count=${count}`,
      body,
      {
        observe: "response",
      }
    );
  }

  getHosXTransactionFilterByPage(page, count, filters) {
    let body = filters;
    return this.http.post(
      `/api/Payment/SearchOutsideTransactions?page=${page}&count=${count}`,
      body,
      {
        observe: "response",
      }
    );
  }

  blockTransaction(id) {
    return this.http.get(`/api/Payment/Block?id=${id}`, {
      observe: "response",
    });
  }

  checkStatus(id) {
    return this.http.get(`api/Payment/CheckStatus?orderId=${id}`);
  }
}
