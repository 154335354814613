import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ApplePayComponentResponse } from "src/app/models/apple-pay/ApplePayComponentResponse.model";
import { MerchantPaymentInfoModel } from "src/app/models/merchantInfoPayment.model";
import { ResponseScannedQr } from "src/app/models/responseScannedQr";
import { Transactions } from "src/app/models/transactions.model";
import { DataExchangeService } from "src/app/services/dataExchange.service";
import { PaymentService } from "src/app/services/payment.service";
import { QrGeneratorService } from "src/app/services/qrGenerator.service";
import { SignalRService } from "src/app/services/signalR.service";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit, OnDestroy {
  showEmailField: boolean = false;
  transferId!: string;

  transaction!: Transactions;
  merchantInfo!: MerchantPaymentInfoModel;
  resScanBody!: ResponseScannedQr;

  changedAmount!: string;
  amountError: boolean = false;

  newComment: string = "";

  isMainLoading: boolean = false;
  isSmallLoading: boolean = false;

  notary: boolean = false;

  getNotification: Subscription;

  showApplePayButton: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private signalRService: SignalRService,
    private dataEx: DataExchangeService,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
    private qrService: QrGeneratorService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      this.transferId = paramMap.get("id");
      if (!this.transferId) {
      }
      this.getTransactionDetails(this.transferId);

      if (ApplePaySession) {
        this.showApplePayButton = true;
      }
    });
  }

  getTransactionDetails(id: string) {
    this.isMainLoading = true;
    this.paymentService.getTransaction(id).subscribe(
      (res) => {
        this.transaction = (res.body as any).transaction;
        this.merchantInfo = (res.body as any).merchantUser;
        if (this.merchantInfo.merchantInfo.companyNameHy.includes("նոտար")) {
          this.notary = true;
        }
        // this.signalRService.addData(
        //   this.merchantInfo.merchantUserId.toString()
        // );

        localStorage.setItem(
          "lang",
          this.transaction.language == "hy"
            ? "am"
            : this.transaction.language.trim() == ""
            ? "am"
            : this.transaction.language
        );

        this.dataEx.setLanguage(
          this.transaction.language == "hy"
            ? "am"
            : this.transaction.language.trim() == ""
            ? "am"
            : this.transaction.language
        );

        if (
          this.transaction.isBlocked ||
          this.transaction.transactionStatus == 3 ||
          this.transaction.transactionStatus == 4
        ) {
          this.router.navigateByUrl(`transaction-not-found/${id}`);
          return;
        }
        this.newComment = this.transaction.comment;
        this.getNotification = this.dataEx.getNotification().subscribe(
          (res) => {
            if (res["isPaid"] == "true") {
              window.location.href = `en/px_transfer?transfer_id=${res["transactionId"]}`;
            }
          },
          (err) => {
            let errmer = err.error.message;
            this._snackBar.open(errmer, "", {
              duration: 2000,
            });
          }
        );
        this.translateService.use(localStorage.getItem("lang"));
        if (this.transaction.transactionStatus == 2) {
          this.router.navigateByUrl(`transfer_success/${this.transferId}`);
          return;
        }
        this.isMainLoading = false;
      },
      (err) => {
        this.isMainLoading = false;
      }
    );
  }

  checkAmount() {
    console.log(typeof this.changedAmount);
  }

  payByCard() {
    this.isSmallLoading = true;
    if (this.transaction.amount == 0) {
      console.log(this.changedAmount);
      if (
        Number.parseInt(this.changedAmount) == 0 ||
        Number.parseInt(this.changedAmount) == undefined ||
        Number.isNaN(this.changedAmount) ||
        this.changedAmount == null
      ) {
        console.log("some error");
        this.amountError = true;
        this.isSmallLoading = false;
        return;
      }
      if (this.changedAmount.toString().length > 11) {
        this._snackBar.open(
          "Max amount will be less than 100,000,000,000",
          "",
          {
            duration: 7000,
          }
        );
        this.isSmallLoading = false;
        return;
      }
    }

    this.qrService.scanQr(this.transaction.qr).subscribe(
      (res) => {
        this.resScanBody = res.body as ResponseScannedQr;
        let sendData = this.resScanBody.response;
        let hash = this.resScanBody.hash;
        sendData.amount =
          this.transaction.amount == 0
            ? Number.parseInt(this.changedAmount)
            : this.transaction.amount;
        sendData.cardId = 0;
        sendData.comment = this.newComment;
        let lang = !!localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "hy";
        lang = lang == "am" ? "hy" : lang;

        this.paymentService.pay(sendData, lang).subscribe(
          (res) => {
            this.isSmallLoading = false;
            if (res.status == 201) {
              if (hash != null) {
                this.router.navigateByUrl(`transfer_success/${hash}`);
                return;
              }
              this.router.navigateByUrl(`transfer_success/${this.transferId}`);
            } else {
              window.location.href = res.body["formUrl"];
            }
          },
          (err) => {
            this.isSmallLoading = false;
            let errMessage: string = "";
            this.translateService.get("deals").subscribe((res) => {
              errMessage = res.errorMessage1;
              this._snackBar.open(errMessage, "", {
                duration: 7000,
              });
            });
          }
        );
      },
      (err) => {
        this.isSmallLoading = false;
        let errmer = err.error.message;
        this._snackBar.open(errmer, "", {
          duration: 7000,
        });
      }
    );
  }

  replaceConverseImgLink(originalUrl: string) {
    let domainPattern = /^(http:\/\/)([^\/]+)(.*)$/;
    let newUrl = originalUrl.replace(domainPattern, "$1pay.conversebank.am$3");
    return newUrl;
  }

  onApplePayCompleted(data: ApplePayComponentResponse) {
    console.log(data.status, data.redirect);

    if (data.status == "success") {
      data.redirect
        ? this.router.navigateByUrl(`transfer_success/${this.transferId}`)
        : null;
    } else {
      alert("some error");
    }
  }

  ngOnDestroy(): void {
    this.getNotification.unsubscribe();
  }
}
