import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { DataExchangeService } from "src/app/services/dataExchange.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  selectedLang: string = "en";

  langText = "en";
  checkAM: boolean;
  checkRU: boolean;
  checkEN: boolean;

  languageForTransaction$: Subscription;

  constructor(
    private translateService: TranslateService,
    private dataExService: DataExchangeService
  ) {
    let local_lang = localStorage.getItem("lang");
    if (local_lang) {
      this.langText = local_lang;
    }
    this.translateService.use(this.langText);

    this.languageForTransaction$ = this.dataExService
      .getLanguage()
      .subscribe((res) => {
        this.langText = res;
      });
  }

  ngOnInit(): void {}

  changeLanguage(val: string) {
    localStorage.setItem("lang", val);
    this.selectedLang = val;
    this.translateService.use(this.selectedLang);
  }

  ngOnDestroy(): void {
    this.languageForTransaction$.unsubscribe();
  }

  changeLang(lang: string) {
    this.langText = lang;
    this.checkAM = lang == "am" ? true : false;
    this.checkRU = lang == "ru" ? true : false;
    this.checkEN = lang == "en" ? true : false;
    localStorage.setItem("lang", lang);
    this.translateService.use(lang);
  }
}
