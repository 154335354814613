<div class="wrapper">
  <div class="main">
    <div class="header">
      <h1>
        Error
      </h1>
    </div>
    <div class="body">
      {{text}}!
    </div>
    <div class="footer">
      <button (click)='onOkClick()'>Ok</button>
    </div>
  </div>
</div>
